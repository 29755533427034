export enum TimeSpan {
  lastWeek = 'Last week',
  lastMonth = 'Last month',
  lastYear = 'Last year',
}

export const spanFilters = [
  { value: TimeSpan.lastYear, label: TimeSpan.lastYear },
  { value: TimeSpan.lastMonth, label: TimeSpan.lastMonth },
  { value: TimeSpan.lastWeek, label: TimeSpan.lastWeek },
];

export const getDateFrom = (span: TimeSpan): Date => {
  const fromDate = new Date();
  switch (span) {
    case TimeSpan.lastYear:
      fromDate.setFullYear(fromDate.getFullYear() - 1);
      return fromDate;
    case TimeSpan.lastMonth:
      fromDate.setMonth(fromDate.getMonth() - 1);
      return fromDate;
    case TimeSpan.lastWeek:
      fromDate.setDate(fromDate.getDate() - 7);
      return fromDate;
    default:
      return fromDate;
      break;
  }
};
