import { forwardRef, useEffect, useState } from 'react';
import Colors from '@/app/styles/Colors';
import { Flex, Text } from '@/app/ui/atoms';
import { Icon } from '@/app/ui/molecules';

interface TextAreaWithLabelProps {
  label: string;
  placeholder?: string;
  tooltip?: boolean;
  defaultValue?: string;
  onChange?: (text: string) => void;
}

const TextAreaWithLabel = forwardRef<HTMLTextAreaElement, TextAreaWithLabelProps>(
  (props: TextAreaWithLabelProps, ref) => {
    const { label, placeholder, tooltip, defaultValue, onChange } = props;

    const [currentValue, setCurrentValue] = useState<string>(defaultValue || '');

    const handleOnChange = (text: string) => {
      if (!onChange || !(onChange instanceof Function)) return;
      setCurrentValue(text);
      onChange(text);
    };

    useEffect(() => {
      setCurrentValue(defaultValue || '');
    }, [defaultValue]);

    return (
      <Flex.Container flexDirection="column" style={{ width: '100%' }}>
        <Flex.Container gap="0.5rem" alignItems="center">
          <Text.LabelSmall htmlFor="title_input">{label}</Text.LabelSmall>
          {tooltip && <Icon.InfoIcon />}
        </Flex.Container>

        <textarea
          ref={ref}
          value={currentValue}
          style={{
            resize: 'vertical',
            padding: 10,
            border: `1px solid ${Colors.borderInput}`,
            height: '40px',
          }}
          onChange={(e) => handleOnChange(e.target.value)}
          id="title_input"
          placeholder={placeholder}
        />
      </Flex.Container>
    );
  },
);

export default TextAreaWithLabel;
