import { Cradle, Territory, Usage } from '@solo/app-core';
import { translate } from '@/app/i18n';
import { Flex, Text } from '@/app/ui/atoms';
import { convertArrayToString } from '@/app/utils/ArrayToString';
import { useCradle } from '@/app/contexts';
import { useBlocState } from '@/app/hooks';
import Colors from '@/app/styles/Colors';

interface UsageDetailsTableProps {
  usages: Usage[];
}

const UsageDetailList = ({ usages }: UsageDetailsTableProps) => {
  const { territoryBloc } = useCradle<Cradle>();
  const territoryState = useBlocState(territoryBloc);
  const territories: Territory[] =
    territoryState.kind === 'LoadedTerritoriesState' ? territoryState.territories : [];
  return (
    <Flex.Container className="table" flexDirection="column">
      <Flex.Container gap="16px" className="thead">
        <Flex.Item flex={2} className="th" style={{ paddingLeft: '1rem' }}>
          <Text.Paragraph fontWeight={600} style={{ padding: 0 }}>
            {translate('WORKS.TITLE')}
          </Text.Paragraph>
        </Flex.Item>
        <Flex.Item flex={2} className="th">
          <Text.Paragraph fontWeight={600} style={{ padding: 0 }}>
            {translate('WORKS.ARTIST')}
          </Text.Paragraph>
        </Flex.Item>
        <Flex.Item flex={1} className="th">
          <Text.Paragraph fontWeight={600} style={{ padding: 0 }}>
            {translate('DIGITAL_DISTRIBUTION.PLATFORM')}
          </Text.Paragraph>
        </Flex.Item>
        <Flex.Item flex={1} className="th">
          <Text.Paragraph fontWeight={600} style={{ padding: 0 }}>
            {translate('FORMS.COUNTRY')}
          </Text.Paragraph>
        </Flex.Item>
        <Flex.Item flex={1} className="th">
          <Text.Paragraph fontWeight={600} style={{ padding: 0 }}>
            {translate('DIGITAL_DISTRIBUTION.DATE')}
          </Text.Paragraph>
        </Flex.Item>
        <Flex.Item flex={1} className="th">
          <Text.Paragraph fontWeight={600} style={{ padding: 0 }}>
            {translate('USAGES.PLAYS')}
          </Text.Paragraph>
        </Flex.Item>
      </Flex.Container>
      <Flex.Container className="tbody" flexDirection="column" gap="10px">
        {usages.map((usage) => (
          <Flex.Container key={usage.id} className="tr" alignItems="center" gap="16px">
            <Flex.Item flex={2} className="td" style={{ paddingLeft: '1rem' }}>
              <Text.Paragraph style={{ color: Colors.primary }} fontWeight={700}>
                {usage.recording?.title}
              </Text.Paragraph>
            </Flex.Item>
            <Flex.Item flex={2} className="td">
              {convertArrayToString(usage.recording?.artists.map((artist) => artist.name) || [])}
            </Flex.Item>
            <Flex.Item flex={1} className="td">
              {usage.digitalPlatform?.name}
            </Flex.Item>
            <Flex.Item flex={1} className="td">
              {territories.find((t) => t.tis === usage.territoryTIS)?.name}
            </Flex.Item>
            <Flex.Item flex={1} className="td">
              {usage.date.toDateString()}
            </Flex.Item>
            <Flex.Item flex={1} className="td">
              {usage.plays}
            </Flex.Item>
          </Flex.Container>
        ))}
      </Flex.Container>
    </Flex.Container>
  );
};

export default UsageDetailList;
