import { forwardRef, useEffect, useState } from 'react';
import { Flex, Input, Text } from '@/app/ui/atoms';
import { Icon } from '@/app/ui/molecules';
import Colors from '@/app/styles/Colors';

interface NumericInputWithLabelProps {
  label: string;
  placeholder?: string;
  tooltip?: boolean;
  value?: number;
  labelColor?: string;
  mandatoryError?: boolean;
  onChange?: (value: number) => void;
}

const NumericInputWithLabel = forwardRef<HTMLInputElement, NumericInputWithLabelProps>(
  (props: NumericInputWithLabelProps, ref) => {
    const { label, placeholder, tooltip, value, labelColor, mandatoryError, onChange } = props;

    const [currentValue, setCurrentValue] = useState<number | undefined>(value);

    const handleOnChange = (val: number) => {
      if (!onChange || !(onChange instanceof Function)) return;
      setCurrentValue(val);
      onChange(val);
    };

    useEffect(() => {
      setCurrentValue(value);
    }, [value]);

    return (
      <Flex.Container flexDirection="column" style={{ width: '100%' }}>
        <Flex.Container gap="0.5rem" alignItems="center">
          <Text.LabelSmall
            htmlFor="title_input"
            style={{ color: mandatoryError ? Colors.red : labelColor }}
          >
            {label}
          </Text.LabelSmall>
          {tooltip && <Icon.InfoIcon />}
        </Flex.Container>
        <Input.Numeric
          style={{
            border: mandatoryError ? `1px solid ${Colors.red}` : `1px solid ${Colors.borderInput}`,
          }}
          ref={ref}
          id="title_input"
          placeholder={placeholder}
          value={currentValue || ''}
          onChange={(e) => handleOnChange(parseInt(e.target.value, 10))}
        />
      </Flex.Container>
    );
  },
);

export default NumericInputWithLabel;
