// import Colors from '@/app/styles/Colors';
import { Nav, RouterLink, Text } from '@/app/ui/atoms';
import styles from './CustomNavBar.module.scss';

export interface CustomNavBarItem {
  routerLinkTo: string;
  title: string;
  end?: boolean;
}

interface CustomNavBarProps {
  items: CustomNavBarItem[];
}

const CustomNavBar = ({ items }: CustomNavBarProps) => (
  <Nav style={{ justifyContent: 'space-between', height: '50px' }}>
    <ul className={styles.menu}>
      {items.map((item) => (
        <li key={item.title} className={styles.menuItem}>
          <RouterLink $transparent $borderBottonActive to={item.routerLinkTo} end={item.end}>
            <Text.Span>{item.title}</Text.Span>
          </RouterLink>
        </li>
      ))}
    </ul>
  </Nav>
);

export default CustomNavBar;
