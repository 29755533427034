import { translate } from '@/app/i18n';
import { Flex, Text } from '@/app/ui/atoms';

// TODO Loading screen or message
const LoadingComponent = () => (
  <Flex.Container>
    <Text.Label>{translate('WORKS.LOADING')}...</Text.Label>
  </Flex.Container>
);

export default LoadingComponent;
