import styled, { css } from 'styled-components/macro';
import Colors from '@/app/styles/Colors';

interface FontProps {
  readonly fontWeight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
  readonly fontSize?: number;
  readonly fontFamily?: string;
  readonly color?: string;
  readonly textAlign?: 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit';
}

const CommonCSS = css<FontProps>`
  word-break: break-word;
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '16px')};
  font-weight: ${(props) => (props.fontWeight ? `${props.fontWeight}` : 400)};
  ${(props) =>
    props.fontFamily
      ? `font-family: ${props.fontFamily}`
      : `font-family: ''Work Sans'', 'Open Sans', serif, sans-serif;`}
  color: ${(props) => props.color || Colors.black};
  ${(props) => (props.textAlign ? `text-align: ${props.textAlign}` : null)}
`;

const H1 = styled.h1<FontProps>`
  ${CommonCSS}
`;

const H2 = styled.h2<FontProps>`
  ${CommonCSS}
`;

const H3 = styled.h3<FontProps>`
  ${CommonCSS}
`;

const H4 = styled.h4<FontProps>`
  ${CommonCSS}
`;

const H5 = styled.h5<FontProps>`
  ${CommonCSS}
`;

const Paragraph = styled.p<FontProps>`
  ${CommonCSS}

  font-size: 16;
  padding: 1rem 0;
`;

const Title1 = styled(H2).attrs({
  fontWeight: 900,
  fontSize: 48,
})``;

const Title2 = styled(H2).attrs({
  fontWeight: 700,
  fontSize: 24,
})``;

const Title3 = styled(H2).attrs({
  fontWeight: 700,
  fontSize: 20,
})``;

const SubtitleBold = styled(H2).attrs({
  fontWeight: 700,
  fontSize: 18,
  fontStyle: 'bold',
})``;

const SubtitleLight = styled(H2).attrs({
  fontWeight: 400,
  fontSize: 18,
})``;

const PageTitle = styled(H1).attrs({
  fontWeight: 700,
  fontSize: 20,
})``;

const SectionTitle = styled(H2).attrs({
  fontWeight: 700,
  fontSize: 18,
})``;

const SectionSubtitle = styled(H2).attrs({
  fontWeight: 700,
  fontSize: 16,
})``;

const SectionSubtitleBold = styled(H2).attrs({
  fontWeight: 700,
  fontSize: 16,
  fontStyle: 'bold',
})``;

const BigItalic = styled(H2).attrs({
  fontWeight: 700,
  fontSize: 18,
  fontStyle: 'italic',
})``;

const Bigger = styled(H2).attrs({
  fontWeight: 400,
  fontSize: 23,
})``;

const BiggerItalic = styled(H2).attrs({
  fontWeight: 700,
  fontSize: 23,
  fontStyle: 'italic',
})``;

const BiggerBold = styled(H2).attrs({
  fontWeight: 700,
  fontSize: 23,
  fontStyle: 'bold',
})``;

const Caption = styled.caption<FontProps>`
  ${CommonCSS}
`;

const Strong = styled.strong<FontProps>`
  ${CommonCSS}
`;

const Span = styled.span<FontProps>`
  ${CommonCSS}
`;

const Label = styled.label<FontProps>`
  ${CommonCSS}

  color: ${Colors.grey3};
`;

const LabelMedium = styled.label<FontProps>`
  ${CommonCSS}

  font-size: 16px;
  font-weight: 400;
`;

const LabelSmall = styled.label<FontProps>`
  ${CommonCSS}

  font-size: 14px;
  font-weight: 400;
`;

const LabelSmallBold = styled.label<FontProps>`
  ${CommonCSS}

  font-size: 14px;
  font-weight: 700;
  font-style: 'bold';
`;

const List = styled.ul<FontProps>`
  ${CommonCSS}

  padding-left: 2rem;
`;

const ListItem = styled.li<FontProps>`
  ${CommonCSS}
`;

const Anotation = styled.label<FontProps>`
  ${CommonCSS}

  font-size: 12px;
  font-weight: 400;
`;

const ErrorMessage = styled(H2).attrs({
  fontWeight: 400,
  fontSize: 14,
  color: Colors.red,
})``;

const Typography = {
  H1,
  H2,
  H3,
  H4,
  H5,
  Paragraph,
  Caption,
  Title1,
  Title2,
  Title3,
  SubtitleBold,
  SubtitleLight,
  PageTitle,
  SectionTitle,
  SectionSubtitle,
  SectionSubtitleBold,
  BigItalic,
  BiggerItalic,
  Strong,
  Span,
  Label,
  LabelMedium,
  LabelSmall,
  LabelSmallBold,
  BiggerBold,
  Bigger,
  List,
  ListItem,
  Anotation,
  ErrorMessage,
};

export default Typography;
