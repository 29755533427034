import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Cradle } from '@solo/app-core';
import { translate } from '@/app/i18n';
import { AliceBiometrics, Button, Card, Flex, Icon, Text } from '@/app/ui';
import Colors from '@/app/styles/Colors';
import { useCradle } from '@/app/contexts';

interface SignaturitComponentProps {
  nextStep: () => void;
  email: string;
}

const SignaturitComponent = (props: SignaturitComponentProps) => {
  const { nextStep, email } = props;
  const [completedIdentification, setCompletedIdentification] = useState<boolean>(false);
  const [userToken, setUserToken] = useState<string>();
  const { authBloc } = useCradle<Cradle>();

  async function getAliceUserToken() {
    try {
      const token = await authBloc.getAliceUserToken({ email });
      setUserToken(token);
    } catch (error) {
      toast.error('TOAST.SIGNATURIT_ERROR');
    }
  }
  useEffect(() => {
    getAliceUserToken();
  }, []);

  return (
    <Flex.Container style={{ width: '100%' }} justifyContent="flex-start">
      {completedIdentification ? (
        <Flex.Container
          style={{ width: '100%', minHeight: '70vh' }}
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <Flex.Container flexDirection="column" gap="2rem" justifyContent="center">
            <Text.BiggerBold style={{ color: Colors.white }}>
              {translate('SIGN_UP.ALICE_COMPLETED')}
            </Text.BiggerBold>
            <Flex.Container
              style={{ marginLeft: '140px' }}
              gap="1.5rem"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Icon.SpinnerCheckIcon />
              <Text.LabelMedium style={{ color: Colors.white }}>
                {translate('SIGN_UP.CORRECT_FRONT_ID')}
              </Text.LabelMedium>
            </Flex.Container>
            <Flex.Container
              style={{ marginLeft: '140px' }}
              gap="1.5rem"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Icon.SpinnerCheckIcon />
              <Text.LabelMedium style={{ color: Colors.white }}>
                {translate('SIGN_UP.CORRECT_BACK_ID')}
              </Text.LabelMedium>
            </Flex.Container>
            <Flex.Container
              style={{ marginLeft: '140px' }}
              gap="1.5rem"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Icon.SpinnerCheckIcon />
              <Text.LabelMedium style={{ color: Colors.white }}>
                {translate('SIGN_UP.CORRECT_SELFIE')}
              </Text.LabelMedium>
            </Flex.Container>
            <Button onClick={() => nextStep()} style={{ width: '100%' }}>
              <Text.SectionSubtitle style={{ color: Colors.white, padding: '1rem' }}>
                {translate('PAGINATION.NEXT_STEP')}
              </Text.SectionSubtitle>
            </Button>
          </Flex.Container>
        </Flex.Container>
      ) : (
        <Flex.Container flexDirection="column" gap="4rem" style={{ width: '100%' }}>
          <Flex.Container style={{ marginLeft: '20px' }}>
            <Text.Bigger style={{ color: Colors.white }}>
              {translate('SIGN_UP.ALICE_IDENTIFY')}
            </Text.Bigger>
          </Flex.Container>
          <Flex.Container alignItems="center" justifyContent="center" alignContent="center">
            <Card.Container backgroundColor={Colors.white} className="p-5">
              <Card.Body style={{ padding: '3rem' }}>
                {userToken && (
                  <AliceBiometrics
                    email={email}
                    userToken={userToken}
                    requestRefreshToken={() => getAliceUserToken()}
                    onSuccess={() => {
                      setCompletedIdentification(true);
                    }}
                    isTrial
                  />
                )}
              </Card.Body>
            </Card.Container>
          </Flex.Container>
        </Flex.Container>
      )}
    </Flex.Container>
  );
};
export default SignaturitComponent;
