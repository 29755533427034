import { Flex, Text, Container, Section, RouterLinkArrow } from '@/app/ui';
import Colors from '@/app/styles/Colors';

interface HomeCardProps {
  readonly title: string;
  readonly description: string;
  readonly toLink: string;
  readonly textLink: string;
}

const HomeCard = (props: HomeCardProps) => {
  const { title, description, toLink, textLink } = props;

  return (
    <Flex.Container alignItems="center" justifyContent="center" alignContent="center">
      <Flex.Item flex={2}>
        <Text.Paragraph fontWeight={700} fontSize={26}>
          {title}
        </Text.Paragraph>
        <Text.Paragraph fontWeight={500} fontSize={16}>
          {description}
        </Text.Paragraph>
        <RouterLinkArrow to={toLink}>
          <Text.Span fontWeight={700} fontSize={20}>
            {textLink}
          </Text.Span>
        </RouterLinkArrow>
      </Flex.Item>
    </Flex.Container>
  );
};

const HomePage = () => (
  <>
    <Section py={15}>
      <Container>
        <Text.Title1>HomePage</Text.Title1>
      </Container>
    </Section>
    <Section py={15} backgroundColor={Colors.grey4}>
      <Container>
        <Flex.Container>
          <Flex.Item>
            <HomeCard
              title="Demo Section"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus laoreet luctus ante eget porttitor. Nunc tincidunt, arcu in congue vestibulum."
              textLink="Link"
              toLink="/path-to-link"
            />
          </Flex.Item>
        </Flex.Container>
      </Container>
    </Section>
  </>
);

export default HomePage;
