import { Text, Container, Section, AliceBiometrics } from '@/app/ui';

const TestPage = () => (
  <Section py={15}>
    <Container>
      <Text.PageTitle>TEST_PAGE</Text.PageTitle>
      <AliceBiometrics
        email="robertt@nakima.es"
        userToken="eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJpc3N1ZXItb25ib2FyZGluZy11c2VyIiwidHlwIjoiVVNFUiIsImV4cCI6MTY3ODc5MTMyMCwiaWF0IjoxNjc4Nzg3NzIwLCJjbGkiOiJzaWduYXR1cml0LXVuaXNvbnJpZ2h0cy10cmlhbCIsInN1YiI6ImZjYzZhYjA4LTM2NjAtNDQyMS04OTQ5LTA3NWJiYmI3MmY0ZCJ9.lu5ybPGG1KrCzNw4-v0IslRV-e6ZY1GoXRomA7wpcBCh4BFQokr1-IzkqUqSoJ_bhQv5NH0tz_XhASFs1U41fRoRJcKb5HVNrpsHlxWJaqcL2tU2dWO677dk8NnDfB1gdrcqb9t2XR4zC0OoVHRYSTsG9gm55VeriZYzt5a0W1fUYUpx1kEhQSwwESlYI_c8rdZZHeBpqP6VafPP5jIiTrKCRxs21eFSByMw3ZWiRiooGn6JEOvgWMT7xlxiK64bREUv9NvcA6-VFe2A5Vp_Gc7kxWfttZWN1O7_91UDddX3O3bFlj3-a1cPR-yPnkrE1aL9JtmYZuTW6lTKoFh-FA"
        requestRefreshToken={() => {
          // eslint-disable-next-line no-console
          console.log('requestRefreshToken');
        }}
        onSuccess={() => {
          // eslint-disable-next-line no-console
          console.log('onSuccess');
        }}
        isTrial
      />
    </Container>
  </Section>
);

export default TestPage;
