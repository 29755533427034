import { useEffect, useState } from 'react';
import { Cradle, PaginatedEntity, Recording, TotalEarnings } from '@solo/app-core';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { Button, Flex, RouterLink, Text } from '@/app/ui/atoms';
import { useCradle } from '@/app/contexts';
import { translate } from '@/app/i18n';
import Colors from '@/app/styles/Colors';
import TopEarningWorksList from '@/app/ui/organisms/Royalties/EarningWorksList';

const TOPN = 5;

export type RecordingWithEarnings = {
  earnings: TotalEarnings;
  recording: Recording;
};

const TopEarningWorks = () => {
  const [topEarningsData, setTopEarningsData] = useState<RecordingWithEarnings[]>([]);
  const { musicalWorksBloc } = useCradle<Cradle>();

  const { consumptionReportsBloc } = useCradle<Cradle>();

  useEffect(() => {
    async function getTopEarningWorks() {
      let recordings: PaginatedEntity<Recording> | undefined;
      try {
        recordings = await musicalWorksBloc.getRecordings();
      } catch (error) {
        Sentry.captureException(error);
      }

      if (!recordings) return;

      const recordingIds = recordings.data.map((recording) => recording.id);

      let topEarnings: TotalEarnings[] = [];
      try {
        topEarnings = await consumptionReportsBloc.getEarningsByRecordings(TOPN, { recordingIds });
      } catch (error) {
        Sentry.captureException(error);
      }

      const topEarningsRecordings: RecordingWithEarnings[] = [];
      await Promise.all(
        topEarnings.map(async (earning) => {
          const recording = recordings?.data.find((earn) => earn.id === earning.recordingId);
          if (!recording) return;
          topEarningsRecordings.push({
            earnings: earning,
            recording,
          });
        }),
      );
      setTopEarningsData(topEarningsRecordings);
    }
    try {
      getTopEarningWorks();
    } catch (error) {
      toast.error('An error ocurred while loading your earnings');
    }
  }, []);

  return (
    <Flex.Container flexDirection="column" className="mt-5" gap="5rem">
      <TopEarningWorksList earningsData={topEarningsData} />
      <Flex.Container justifyContent="center">
        <RouterLink to="/earnings/royalties/works" style={{ width: '25%' }}>
          <Button
            $size="sm"
            $variant="primary"
            $backgroundColor={Colors.transparent}
            $backgroundColorHover={Colors.transparent}
          >
            <Text.Paragraph style={{ padding: 0 }} fontWeight={600}>
              {translate('EARNINGS.SEE_ALL')}
            </Text.Paragraph>
          </Button>
        </RouterLink>
      </Flex.Container>
    </Flex.Container>
  );
};

export default TopEarningWorks;
