import React from 'react';
import { Flex } from '@/app/ui/atoms';
import Colors from '@/app/styles/Colors';

interface SideBarProps {
  children: React.ReactNode;
}
const SideBar = (props: SideBarProps) => {
  const { children } = props;
  return (
    <Flex.Container
      style={{
        backgroundColor: Colors.backgroundSidebar,
        minHeight: ' 100vh',
        padding: '1.6rem 1.6rem 1.6rem 3.2rem',
        minWidth: '225px',
        // flexDirection: 'column',
      }}
    >
      {children}
    </Flex.Container>
  );
};

export default SideBar;
