import { translate } from '@/app/i18n';
import Colors from '@/app/styles/Colors';
import { Flex, RouterLink, Text } from '@/app/ui';

const NotFoundPage = () => (
  <Flex.Container
    flexDirection="column"
    justifyContent="center"
    alignContent="center"
    alignItems="center"
    style={{
      textAlign: 'center',
      height: '80vh',
    }}
  >
    <h1 style={{ fontSize: '15rem', margin: 0, color: Colors.primary }}>404</h1>
    <h1 style={{ fontSize: '23px', marginTop: '-15px', color: Colors.black }}>
      Oops! {translate('NOT_FOUND.PAGE_NOT_FOUND')}
    </h1>
    <Text.LabelMedium style={{ marginTop: '15px' }}>
      {translate('NOT_FOUND.SORRY')}
    </Text.LabelMedium>
    <Text.LabelMedium>
      {translate('NOT_FOUND.GO_BACK')}
      <RouterLink $transparent to="/dashboard" style={{ color: Colors.primary, fontWeight: 700 }}>
        {translate('NAVBAR.DASHBOARD')}
      </RouterLink>
    </Text.LabelMedium>
  </Flex.Container>
);

export default NotFoundPage;
