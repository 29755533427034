import { useEffect, useState } from 'react';
import { CountriesWithPlays, Cradle, PaginatedEntity, Recording } from '@solo/app-core';
import { toast } from 'react-toastify';
import { Flex, WorldMapWithMarkers } from '@/app/ui';
import { useCradle } from '@/app/contexts';
import { QuantityPerCountryWithCoordinates } from '@/app/ui/organisms/WorldMapWithMarkers/WorldMapWithMarkers';
import { useBlocState } from '@/app/hooks';

const PlaysByCountry = () => {
  const { consumptionReportsBloc, musicalWorksBloc, authBloc, territoryBloc } = useCradle<Cradle>();
  const authState = useBlocState(authBloc);
  const [topCountries, setTopCountries] = useState<QuantityPerCountryWithCoordinates[]>([]);

  useEffect(() => {
    async function getTopSongsByCountry() {
      if (authState.kind !== 'AuthenticatedAuthState') return;

      let recordings: PaginatedEntity<Recording> | undefined;

      try {
        recordings = await musicalWorksBloc.getRecordings({
          filters: { userId: authState.user.id },
        });
      } catch (error) {
        toast.error('Something went wrong when loading recordings.');
        return;
      }
      const recordingIds = recordings.data.map((rec) => rec.id);

      let usagesFiltered: CountriesWithPlays[] = [];
      try {
        usagesFiltered = await consumptionReportsBloc.getPlaysByCountries({
          props: { topCountries: 10, filters: { recordingIds } },
        });
      } catch (error) {
        toast.error('Something went wrong when loading plays.');
        return;
      }

      const countryPlaysWithCoordinates: QuantityPerCountryWithCoordinates[] = [];
      await Promise.all(
        usagesFiltered.map(async (countryData) => {
          const coordinates = await territoryBloc.getTerritoryCoordinates(countryData.territoryTIS);

          countryPlaysWithCoordinates.push({
            countryData: { territoryTis: countryData.territoryTIS, quantity: countryData.plays },
            coordinates,
          });
        }),
      );

      setTopCountries(countryPlaysWithCoordinates);
    }
    getTopSongsByCountry();
  }, []);

  return (
    <Flex.Container
      flexDirection="column"
      style={{ width: '100%', marginTop: '-30px', marginLeft: '-30px' }}
    >
      <WorldMapWithMarkers markers={topCountries} />
    </Flex.Container>
  );
};

export default PlaysByCountry;
