import { createRef, useEffect, useState } from 'react';
import {
  Cradle,
  CurrencyId,
  FinancialData,
  FinancialUserType,
  TerritoryType,
  UpdateUserProps,
} from '@solo/app-core';
import Select from 'react-select/dist/declarations/src/Select';
import { toast } from 'react-toastify';
import {
  Flex,
  Text,
  // Container,
  Section,
  Button,
  InputTextAutocomplete,
  Item,
  InputWithLabel,
  //  RouterLinkArrow
} from '@/app/ui';

import { translate } from '@/app/i18n';
import { useCradle } from '@/app/contexts';
import { useBlocState } from '@/app/hooks';

//   bankAccount?: string;
//   swift?: string;
//   country?: TerritoryInterface;
//   currency?: CurrencyInterface;
//   countryId?: number;
//   currencyId?: string;
// }

// interface FinancialDataInterface {
//   financialUserType?: FinancialUserType;
//   bankInfo?: BankInfoInterface;
// }

// enum FinancialUserType {
//   COMPANY = 'company',
//   INDIVIDUAL = 'individual',
//   SELF_EMPLOYED = 'self_employed',
// }

// const FINANCIAL_USER_TYPE = Object.values(FinancialUserType);

// type FinancialUserTypeType = typeof FINANCIAL_USER_TYPE[number];

const FinancialDataComponent = () => {
  const [financialData, setFinancialData] = useState<FinancialData | undefined>(undefined);
  const [countryOptions, setCountryOptions] = useState<Item[] | undefined>(undefined);
  const [currencyOptions, setCurrencyOptions] = useState<Item[] | undefined>(undefined);
  const [userType, setUserType] = useState<FinancialUserType | undefined>();

  const { authBloc } = useCradle<Cradle>();
  const authState = useBlocState(authBloc);

  const userTypeRef = createRef<Select<Item>>();
  const countryRef = createRef<Select<Item>>();
  const currencyRef = createRef<Select<Item>>();
  const accountRef = createRef<HTMLInputElement>();
  const swiftRef = createRef<HTMLInputElement>();

  const userTypeOptions: Array<Item> = Object.entries(FinancialUserType).map((uType) => ({
    label: translate(`FINANCIAL_DATA_INFO.${uType[0]}`),
    value: translate(`FINANCIAL_DATA_INFO.${uType[1]}`),
  }));

  const { userBloc, territoryBloc, currencyBloc } = useCradle<Cradle>();

  function validateFinancialForm(): UpdateUserProps {
    if (
      userTypeRef.current === null ||
      userTypeRef.current?.state.selectValue[0].value === '' ||
      countryRef.current === null ||
      countryRef.current?.state.selectValue[0].value === '' ||
      currencyRef.current === null ||
      currencyRef.current?.state.selectValue[0].value === '' ||
      accountRef.current === null ||
      accountRef.current?.value === '' ||
      swiftRef.current === null ||
      swiftRef.current?.value === ''
    )
      throw new Error('Undefined values not found');

    const type = userTypeRef.current.state.selectValue[0].value as FinancialUserType;
    const country = countryOptions?.find(
      (c) => c.value === countryRef.current?.state.selectValue[0].value,
    );
    const currency = currencyOptions?.find(
      (curr) => curr.value === currencyRef.current?.state.selectValue[0].value,
    );
    const account = accountRef.current.value;
    const swift = swiftRef.current.value;

    const updateFinancialData: UpdateUserProps = {
      financialData: {
        financialUserType: type,
        bankInfo: {
          countryId: parseInt(country?.value || '0', 10),
          currencyId: currency?.value as CurrencyId,
          bankAccount: account,
          swift,
        },
      },
    };

    return updateFinancialData;
  }

  // TODO userId?
  const userId = authState.kind === 'AuthenticatedAuthState' ? authState.user.id : '';

  const saveBankInformation = () => {
    try {
      const updateFinancialData = validateFinancialForm();
      userBloc.updateUser(updateFinancialData);
      toast.success(translate('TOAST.CHANGES_SAVED'));
    } catch (error) {
      toast.error(translate('TOAST.CHANGES_ERROR'));
    }
  };

  const getFinancialData = async () => {
    try {
      const financialDataFetched = await userBloc.getFinancialData(userId);

      if (financialDataFetched !== undefined) {
        setFinancialData(financialDataFetched);
      }
    } catch (error) {
      // TODO handle errors
    }
  };

  const getCountries = async () => {
    try {
      const countriesFetched = await territoryBloc.getTerritories(TerritoryType.COUNTRY);

      if (countriesFetched === undefined) return;

      const countryOpt: Array<Item> = countriesFetched.map((country) => ({
        label: country.name,
        value: country.tis.toString() ? country.tis.toString() : '0',
      }));
      setCountryOptions(countryOpt);
    } catch (error) {
      // TODO handle errors
    }
  };

  const getCurrencies = async () => {
    try {
      const currenciesFetched = await currencyBloc.getCurrencies();

      if (currenciesFetched === undefined) return;

      const currencyOpt: Array<Item> = currenciesFetched.map((currency) => ({
        label: currency.name,
        value: currency.id,
      }));

      setCurrencyOptions(currencyOpt);
    } catch (error) {
      // TODO handle errors
    }
  };

  useEffect(() => {
    getCountries();
    getCurrencies();
    getFinancialData();
  }, []);

  return (
    <>
      <Section py={15} style={{ flexDirection: 'column', paddingTop: 0 }}>
        <Text.SectionTitle style={{ marginBottom: 32 }}>
          {translate('PROFILE_PAGES.FINANCIAL_DATA')}
        </Text.SectionTitle>
        <Flex.Container gap="18px">
          <Flex.Item flex={1}>
            <InputTextAutocomplete
              ref={userTypeRef}
              label={translate('FINANCIAL_DATA_INFO.USER_TYPE')}
              placeholder={translate('FINANCIAL_DATA_INFO.SELECT')}
              items={userTypeOptions}
              defaultOption={
                financialData
                  ? {
                      value: financialData.financialUserType,
                      label: financialData.financialUserType,
                    }
                  : undefined
              }
              onChange={(type) => setUserType(type as FinancialUserType)}
            />
          </Flex.Item>
          <Flex.Item flex={3} />
        </Flex.Container>
      </Section>
      <Section py={5} style={{ flexDirection: 'column' }}>
        <Text.SectionTitle style={{ marginBottom: '32px' }}>
          {translate('FINANCIAL_DATA.BANK_INFO')}
        </Text.SectionTitle>
        <Flex.Container>
          <Flex.Item flex={1}>
            <Text.Paragraph style={{ marginBottom: '32px' }}>
              {translate('FINANCIAL_DATA.BANK_ACCOUNT_TEXT')}
            </Text.Paragraph>
          </Flex.Item>
          <Flex.Item flex={1} />
        </Flex.Container>
        <Flex.Container>
          <Flex.Container gap="3rem" flex={1} flexDirection="column">
            <Flex.Container gap="18px">
              <Flex.Item flex={1}>
                <InputTextAutocomplete
                  ref={countryRef}
                  disabled={userType === undefined}
                  label={translate('FINANCIAL_DATA_INFO.COUNTRY')}
                  placeholder={translate('FINANCIAL_DATA_INFO.COUNTRY')}
                  defaultOption={
                    financialData?.bankInfo.country
                      ? {
                          value: financialData.bankInfo.country?.name,
                          label: financialData.bankInfo.country?.name,
                        }
                      : undefined
                  }
                  items={countryOptions || []}
                />
              </Flex.Item>
              <Flex.Item flex={1}>
                <InputTextAutocomplete
                  ref={currencyRef}
                  disabled={userType === undefined}
                  label={translate('FINANCIAL_DATA_INFO.CURRENCY')}
                  placeholder={translate('FINANCIAL_DATA_INFO.CURRENCY')}
                  defaultOption={
                    financialData?.bankInfo.currency
                      ? {
                          value: financialData.bankInfo.currency?.name,
                          label: financialData.bankInfo.currency?.name,
                        }
                      : undefined
                  }
                  items={currencyOptions || []}
                />
              </Flex.Item>
            </Flex.Container>
            <Flex.Container gap="18px">
              <Flex.Item flex={1}>
                <InputWithLabel
                  ref={accountRef}
                  disabled={userType === undefined}
                  label={translate('FINANCIAL_DATA_INFO.BANK_ACCOUNT')}
                  placeholder={translate('FINANCIAL_DATA_INFO.BANK_ACCOUNT')}
                  defaultValue={
                    financialData === undefined ? '' : financialData?.bankInfo?.bankAccount
                  }
                />
              </Flex.Item>
              <Flex.Item flex={1}>
                <InputWithLabel
                  ref={swiftRef}
                  disabled={userType === undefined}
                  label={translate('FINANCIAL_DATA_INFO.SWIFT')}
                  placeholder={translate('FINANCIAL_DATA_INFO.SWIFT')}
                  defaultValue={financialData === undefined ? '' : financialData?.bankInfo?.swift}
                />
              </Flex.Item>
            </Flex.Container>
            {/* <p className={`${error ? 'error' : 'success'}`}>{helperText}</p> */}
            <Flex.Container gap="18px">
              <Flex.Item flex={1} />
              <Flex.Item flex={1}>
                <Button
                  $size="md"
                  $full
                  $variant="primary"
                  onClick={() => {
                    saveBankInformation();
                  }}
                >
                  {translate('BUTTON.SAVE_CHANGES')}
                </Button>
              </Flex.Item>
            </Flex.Container>
          </Flex.Container>
          <Flex.Item flex={1} />
        </Flex.Container>
      </Section>
    </>
  );
};

export default FinancialDataComponent;
