import { Flex, Text, Container, RouterLink } from '@/app/ui';
import { translate } from '@/app/i18n';
import Colors from '@/app/styles/Colors';

const TypeCardContainer = ({ children }: { children: JSX.Element }) => (
  <Flex.Container
    flexDirection="column"
    padding="40px"
    style={{
      border: `3px solid ${Colors.borderInput}`,
      borderTop: `3px solid ${Colors.purple}`,
    }}
  >
    {children}
  </Flex.Container>
);

const MusicalReleaseTypeForm = () => (
  <Container>
    <Flex.Container gap="0px 24px" style={{ marginTop: '6%' }}>
      <TypeCardContainer>
        <>
          <Text.BiggerItalic>{translate('WORK_TYPE.SINGLE_MIN')}</Text.BiggerItalic>
          <Flex.Container padding="20px 0px 16px 0px">
            <Text.Paragraph>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sed pellentesque diam
              mauris.
            </Text.Paragraph>
          </Flex.Container>

          <RouterLink className="mb-2" $size="md" $variant="primary" to="/works/add-song/single">
            <Text.Span style={{ padding: 0 }} fontWeight={600}>
              {translate('BUTTON.ADD_SINGLE')}
            </Text.Span>
          </RouterLink>
        </>
      </TypeCardContainer>
      <TypeCardContainer>
        <>
          <Text.BiggerItalic>EP</Text.BiggerItalic>
          <Flex.Container padding="20px 0px 16px 0px">
            <Text.Paragraph>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sed pellentesque diam
              mauris.
            </Text.Paragraph>
          </Flex.Container>

          <RouterLink className="mb-2" $size="md" $variant="primary" to="/works/add-song/ep">
            <Text.Span style={{ padding: 0 }} fontWeight={600}>
              {translate('BUTTON.ADD_EP')}
            </Text.Span>
          </RouterLink>
        </>
      </TypeCardContainer>
      <TypeCardContainer>
        <>
          <Text.BiggerItalic>{translate('WORK_TYPE.ALBUM_MIN')}</Text.BiggerItalic>
          <Flex.Container padding="20px 0px 16px 0px">
            <Text.Paragraph>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sed pellentesque diam
              mauris.
            </Text.Paragraph>
          </Flex.Container>

          <RouterLink className="mb-2" $size="md" $variant="primary" to="/works/add-song/album">
            <Text.Span style={{ padding: 0 }} fontWeight={600}>
              {translate('BUTTON.ADD_ALBUM')}
            </Text.Span>
          </RouterLink>
        </>
      </TypeCardContainer>
    </Flex.Container>
  </Container>
);

export default MusicalReleaseTypeForm;
