import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

import {
  // FullLayout,
  FinancialDataComponent,
  DashboardLayout,
  SubscriptionComponent,
  ProfileInfoComponent,
  AuthLayout,
  MusicalReleaseDataComponent,
  TracksComponent,
  DigitalDistributionComponent,
  TrackDataComponent,
  EarningsWorks,
} from '@/app/ui';

import HomePage from './HomePage/HomePage';
import NotFoundPage from './NotFoundPage/NotFoundPage';
import LoginPage from './LoginPage/LoginPage';
import ProfilePage from './ProfilePage/ProfilePage';
import ResetPasswordPage from './ResetPasswordPage/ResetPasswordPage';
import SignUpPage from './SignUpPage/SignUpPage';
import NewPasswordPage from './NewPasswordPage/NewPasswordPage';
import WorksPage from '@/app/pages/WorksPage/WorksPage';
import WorkPage from '@/app/pages/WorkPage/WorkPage';
import AddMusicalRelease from '@/app/pages/AddMusicalReleasePage/AddMusicalReleasePage';
import TestPage from '@/app/pages/TestPage/TestPage';
import MusicUsagePage from '@/app/pages/MusicUsagePage/MusicUsagePage';
import AllUsageDetails from '@/app/ui/organisms/Usages/AllUsageDetails';
import EarningsPage from '@/app/pages/Earnings/RoyaltiesPage/RoyaltiesPage';
import DashboardPage from '@/app/pages/DashboardPage/DashboardPage';
import StatementsPage from '@/app/pages/Earnings/StatementsPage/StatementsPage';
import RequireAuth from '@/app/pages/ProtectedRoutes';
import NotAuthRoute from '@/app/pages/NoAuthRoutes';

const routes: RouteObject[] = [
  {
    path: '/auth',
    children: [
      { index: true, element: <Navigate to="login" replace /> },
      {
        path: 'reset-password',
        element: (
          <AuthLayout>
            <RequireAuth>
              <ResetPasswordPage />
            </RequireAuth>
          </AuthLayout>
        ),
      },
      {
        path: 'new-password',
        element: (
          <AuthLayout>
            <RequireAuth>
              <NewPasswordPage />
            </RequireAuth>
          </AuthLayout>
        ),
      },
      {
        path: 'sign-up',
        element: (
          <NotAuthRoute>
            <SignUpPage />
          </NotAuthRoute>
        ),
      },
      {
        path: 'login',
        element: (
          <NotAuthRoute>
            <LoginPage />
          </NotAuthRoute>
        ),
      },
    ],
  },
  {
    path: '/',
    element: (
      <RequireAuth>
        <DashboardLayout />
      </RequireAuth>
    ),
    children: [
      { index: true, element: <HomePage /> },
      {
        path: '/profile',
        children: [
          {
            index: false,
            path: '',
            element: <ProfilePage />,
            children: [
              {
                index: true,
                path: '',
                element: <ProfileInfoComponent />,
              },
              {
                index: true,
                path: 'financial-data',
                element: <FinancialDataComponent />,
              },
              {
                index: true,
                path: 'subscription',
                element: <SubscriptionComponent />,
              },
            ],
          },
        ],
      },
      {
        path: '/works',
        children: [
          {
            index: false,
            path: '',
            element: <WorksPage />,
          },
          {
            index: false,
            path: '/works/add-song',
            element: <AddMusicalRelease />,
            children: [
              {
                path: ':songType',
                element: <AddMusicalRelease />,
              },
            ],
          },
          {
            path: ':id',
            element: <WorkPage />,
            children: [
              {
                index: true,
                path: '',
                element: <MusicalReleaseDataComponent />,
              },
              {
                index: true,
                path: 'tracks',
                element: <TracksComponent />,
              },
              {
                index: true,
                path: 'digital-distribution',
                element: <DigitalDistributionComponent />,
              },
              {
                index: true,
                path: 'tracks/:trackId',
                element: <TrackDataComponent />,
              },
            ],
          },
        ],
      },
      {
        path: '/dashboard',
        children: [{ index: true, element: <DashboardPage /> }],
      },
      {
        path: '/music-usage',
        children: [
          { index: true, element: <MusicUsagePage /> },
          { index: true, path: 'usage-detail', element: <AllUsageDetails /> },
        ],
      },
      {
        path: '/earnings/royalties',
        children: [
          { index: true, element: <EarningsPage /> },
          { index: true, path: 'works', element: <EarningsWorks /> },
        ],
      },
      {
        path: '/earnings/statements',
        children: [{ index: true, element: <StatementsPage /> }],
      },
      {
        path: 'test',
        children: [{ index: true, element: <TestPage /> }],
      },
      {
        path: '*',
        children: [{ path: '*', element: <NotFoundPage /> }],
      },
    ],
  },
];

const Navigator = () => {
  const Router = useRoutes(routes);

  return Router;
};

export default Navigator;
