import {
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLegend,
  VictoryLine,
  VictoryScatter,
  VictoryTheme,
} from 'victory';
import Colors from '@/app/styles/Colors';
import { BarData } from '@/app/ui/organisms/CustomBarChart/CustomBarChart';

const dataColors = [
  Colors.primary,
  Colors.pink,
  Colors.green,
  Colors.blue,
  Colors.red,
  Colors.yellow,
];

export type LineChartData = {
  [key: string]: BarData[];
};

interface CustomLineChartProps {
  data: LineChartData;
  xaxis?: string;
  yaxis?: string;
}

const CustomLineChart = (props: CustomLineChartProps) => {
  const { data, xaxis, yaxis } = props;

  return (
    <VictoryChart
      theme={VictoryTheme.material}
      domainPadding={100}
      padding={{ top: 80, bottom: 32, left: 70, right: 70 }}
      height={320}
      width={1500}
    >
      <VictoryLegend
        x={0}
        y={0}
        centerTitle
        orientation="horizontal"
        gutter={20}
        style={{ labels: { fontSize: 20 } }}
        data={Object.entries(data).map((year, index) => ({
          name: year[0],
          symbol: { fill: dataColors[index % dataColors.length] },
        }))}
      />
      <VictoryAxis
        dependentAxis
        style={{
          tickLabels: { fontSize: '14px', fill: Colors.black },
          grid: { stroke: Colors.borderInput, strokeDasharray: '0' },
          axis: { stroke: 'transparent' },
        }}
      />
      <VictoryAxis
        style={{
          tickLabels: { fontSize: '14px', fill: Colors.black },
          grid: { stroke: 'transparent' },
          axis: { stroke: Colors.borderInput },
        }}
        label={xaxis}
      />
      <VictoryLabel x={20} y={65} text={yaxis} style={{ fontSize: '18px' }} />
      {Object.entries(data).map((year, index) => (
        <VictoryGroup key={year[0]}>
          <VictoryLine
            data={year[1]}
            style={{ data: { stroke: dataColors[index % dataColors.length] } }}
          />
          <VictoryScatter
            data={year[1]}
            size={6}
            style={{ data: { fill: dataColors[index % dataColors.length] } }}
          />
        </VictoryGroup>
      ))}
    </VictoryChart>
  );
};

export default CustomLineChart;
