import { useState } from 'react';
import { Flex, Text } from '@/app/ui/atoms';
import Colors from '@/app/styles/Colors';
import { Icon } from '@/app/ui/molecules';
import Dropdown from '@/app/ui/molecules/Dropdown';
import { translate } from '@/app/i18n';

interface ElementsPerPageProps {
  options: number[];
  position: 'left' | 'right';
  totalPages: number;
  defaultElementsPerPage: number;
  onChange: (nElements: number) => void;
}

const ElementsPerPage = ({
  options,
  position,
  totalPages,
  defaultElementsPerPage,
  onChange,
}: ElementsPerPageProps) => {
  const [elementsPerPage, setElementsPerPage] = useState<number>(defaultElementsPerPage);

  const handleOnChange = (pages: number) => {
    setElementsPerPage(pages);
    onChange(pages);
  };
  return (
    <Flex.Container
      gap="1rem"
      alignItems="center"
      justifyContent={position === 'left' ? 'flex-start' : 'flex-end'}
    >
      <Text.Span>{translate('FILTERS.SEE')}</Text.Span>
      <Dropdown.Container>
        <Dropdown.Button style={{ width: '100%' }}>
          <Flex.Container
            style={{
              alignItems: 'center',
              padding: '1rem',
              justifyContent: 'left',
              border: `1px solid ${Colors.borderInput}`,
              width: '100%',
            }}
          >
            <Text.Span style={{ padding: '0 1rem 0 0' }} fontSize={14}>
              {elementsPerPage}
            </Text.Span>
            <Icon.ArrowDown style={{ marginLeft: 'auto' }} />
          </Flex.Container>
        </Dropdown.Button>
        <Dropdown.Content
          style={{ width: '100%', minWidth: '40px', border: `1px solid ${Colors.borderInput}` }}
        >
          <Flex.Container
            flexDirection="column"
            justifyContent="flex-start"
            alignContent="flex-start"
            alignItems="flex-start"
            style={{
              backgroundColor: 'white',
              borderRadius: '4px',
            }}
          >
            {options.map((pages) => (
              <Dropdown.Button
                key={pages}
                style={{ width: '100%' }}
                onClick={() => {
                  handleOnChange(pages);
                }}
              >
                <Flex.Container
                  style={{
                    alignItems: 'center',
                    padding: '1rem',
                    justifyContent: 'left',
                    width: '100%',
                    backgroundColor: 'white',
                  }}
                >
                  <Text.Span fontSize={14}>{pages}</Text.Span>
                </Flex.Container>
              </Dropdown.Button>
            ))}
          </Flex.Container>
        </Dropdown.Content>
      </Dropdown.Container>
      <Text.Span>
        {translate('FILTERS.OF')} {totalPages} {translate('FILTERS.PAGES')}
      </Text.Span>
    </Flex.Container>
  );
};

export default ElementsPerPage;
