// import Colors from '@/app/styles/Colors';
import { Nav, RouterLink, Text } from '@/app/ui/atoms';
import { translate } from '@/app/i18n';
import styles from './NavBar.module.scss';

const NavBarProfile = () => (
  <Nav style={{ justifyContent: 'space-between', height: '50px' }}>
    <ul className={styles.menu}>
      <li className={styles.menuItem}>
        <RouterLink $transparent $borderBottonActive to="/profile" end>
          <Text.Span>{translate('PROFILE_PAGES.PROFILE')}</Text.Span>
        </RouterLink>
      </li>
      <li className={styles.menuItem}>
        <RouterLink $transparent $borderBottonActive to="subscription">
          <Text.Span>{translate('PROFILE_PAGES.SUBSCRIPTION')}</Text.Span>
        </RouterLink>
      </li>
      <li className={styles.menuItem}>
        <RouterLink $transparent $borderBottonActive to="financial-data">
          <Text.Span>{translate('PROFILE_PAGES.FINANCIAL_DATA')}</Text.Span>
        </RouterLink>
      </li>
    </ul>
  </Nav>
);

export default NavBarProfile;
