import { forwardRef } from 'react';
import { Flex, Text } from '@/app/ui/atoms';
import { Icon } from '@/app/ui/molecules';
import Colors from '@/app/styles/Colors';

interface InputDateWithLabelProps {
  label?: string;
  tooltip?: boolean;
  defaultValue?: Date;
  mandatoryError?: boolean;
  labelColor?: string;
  type?: 'month' | 'date';
  onChange?: (date: Date) => void;
}

const InputWithLabel = forwardRef<HTMLInputElement, InputDateWithLabelProps>(
  (props: InputDateWithLabelProps, ref) => {
    const { label, tooltip, defaultValue, mandatoryError, labelColor, type, onChange } = props;

    const handleOnChange = (date: string) => {
      if (!onChange || !(onChange instanceof Function)) return;
      const newDate = new Date(date);
      onChange(newDate);
    };

    return (
      <Flex.Container flexDirection="column" style={{ width: '100%' }}>
        <Flex.Container gap="0.5rem" alignItems="center">
          <Text.LabelSmall style={{ color: mandatoryError ? Colors.red : labelColor }}>
            {label}
          </Text.LabelSmall>
          {tooltip && <Icon.InfoIcon />}
        </Flex.Container>
        <input
          style={{
            border: mandatoryError ? `1px solid ${Colors.red}` : `1px solid ${Colors.borderInput}`,
            padding: '0.85rem',
          }}
          defaultValue={
            defaultValue !== undefined
              ? `${defaultValue.getFullYear()}-${
                  defaultValue.getMonth() + 1 < 10
                    ? `0${defaultValue.getMonth() + 1}`
                    : defaultValue.getMonth() + 1
                }-${
                  defaultValue.getDate() + 1 < 10
                    ? `0${defaultValue.getDate()}`
                    : defaultValue.getDate()
                }`
              : undefined
          }
          type={type || 'date'}
          ref={ref}
          onChange={(e) => handleOnChange(e.target.value)}
        />
      </Flex.Container>
    );
  },
);

export default InputWithLabel;
