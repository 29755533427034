import { useDrag } from 'react-dnd';

interface DragItemProps {
  readonly children: React.ReactNode;
  readonly text: string;
  readonly type: string;
}

const DragItem = ({ children, text, type }: DragItemProps) => {
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type,
      item: { text },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
    }),
    [],
  );

  return (
    <div ref={dragRef} style={{ opacity }}>
      {children}
    </div>
  );
};

export default DragItem;
