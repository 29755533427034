/* eslint-disable @typescript-eslint/no-unused-vars */
import { Cradle } from '@solo/app-core';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useCradle } from '@/app/contexts';
import { useBlocState } from '@/app/hooks';
import { translate } from '@/app/i18n';
import { Flex } from '@/app/ui/atoms';
import CustomCircleChart, {
  CircleData,
} from '@/app/ui/organisms/CustomCircleChart/CustomCircleChart';
import Colors from '@/app/styles/Colors';

const Top3EarningPlatforms = () => {
  const { consumptionReportsBloc, digitalDistributionBloc, authBloc } = useCradle<Cradle>();
  const digitalDistributionState = useBlocState(digitalDistributionBloc);
  const authState = useBlocState(authBloc);
  const digitalPlatforms =
    digitalDistributionState.kind === 'LoadedDigitalDistributionState'
      ? digitalDistributionState.platforms
      : [];

  const [top3Platforms, setTop3Platforms] = useState<CircleData[]>([]);

  useEffect(() => {
    async function getTop3Platforms() {
      if (authState.kind !== 'AuthenticatedAuthState') return;
      try {
        const top3PlatformsEarnings =
          await consumptionReportsBloc.getAllUserEarningsByDigitalPlatform({
            topDigitalPlatforms: 3,
            userId: authState.user.id,
          });

        const totalTopEarnings = top3PlatformsEarnings.reduce(
          (prev, platform) => prev + platform.total,
          0,
        );

        const top3PlatformsEarningsData: CircleData[] = top3PlatformsEarnings.map((platform) => ({
          name: platform.digitalPlatform?.name || 'Unknown',
          value: Math.floor((platform.total * 100) / totalTopEarnings),
        }));

        setTop3Platforms(top3PlatformsEarningsData);
      } catch (error) {
        toast.error(translate('TOAST.LOAD_EARNINGS_ERROR'));
      }
    }
    getTop3Platforms();
  }, []);

  return (
    <Flex.Container style={{ position: 'relative', width: '100%', aspectRatio: '2/1.2' }}>
      {top3Platforms.length > 0 && (
        <>
          <Flex.Container style={{ position: 'absolute', width: '55%', top: -20 }}>
            <CustomCircleChart barColor={Colors.primary} data={top3Platforms[0]} />
          </Flex.Container>
          <Flex.Container style={{ position: 'absolute', width: '45%', top: -15, right: 0 }}>
            <CustomCircleChart barColor={Colors.pink} data={top3Platforms[1]} />
          </Flex.Container>
          <Flex.Container style={{ position: 'absolute', width: '35%', bottom: -60, right: '25%' }}>
            <CustomCircleChart barColor={Colors.greenSolid} data={top3Platforms[2]} />
          </Flex.Container>
        </>
      )}
    </Flex.Container>
  );
};

export default Top3EarningPlatforms;
