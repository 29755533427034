import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Flex, Text } from '@/app/ui/atoms';
import { Icon } from '@/app/ui/molecules';
import Colors from '@/app/styles/Colors';

interface InputWithLabelProps {
  label: string;
  tooltip?: boolean;
  defaultValue?: Dayjs;
  mandatoryError?: boolean;
  onChange: (time: string) => void;
}

const InputWithLabel = (props: InputWithLabelProps) => {
  const { label, tooltip, defaultValue, mandatoryError, onChange } = props;
  const [time, setTime] = useState<Dayjs | undefined>(
    defaultValue ? dayjs(defaultValue) : undefined,
  );

  function handleOnChange(newTime: Dayjs | null | undefined) {
    if (!newTime) return;
    setTime(newTime);
    onChange(
      `${newTime.get('hours') > 9 ? newTime.get('hours') : `0${newTime.get('hours')}`}:${
        newTime.get('minute') > 9 ? newTime.get('minute') : `0${newTime.get('minute')}`
      }`,
    );
  }

  return (
    <Flex.Container flexDirection="column" style={{ width: '100%' }}>
      <Flex.Container gap="0.5rem" alignItems="center">
        <Text.LabelSmall htmlFor="title_input" style={{ color: mandatoryError ? Colors.red : '' }}>
          {label}
        </Text.LabelSmall>
        {tooltip && <Icon.InfoIcon />}
      </Flex.Container>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopTimePicker
          onChange={(newTime) => handleOnChange(dayjs(newTime))}
          value={time || null}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              sx={{
                '.MuiOutlinedInput-input': {
                  fontSize: '12px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                },
                '.MuiOutlinedInput-root': {
                  borderRadius: 0,
                  border: mandatoryError
                    ? `1px solid ${Colors.red}`
                    : `0.5px solid ${Colors.borderInput}`,
                },
              }}
            />
          )}
        />
      </LocalizationProvider>
    </Flex.Container>
  );
};

export default InputWithLabel;
