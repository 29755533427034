import {
  AnualRevenue,
  Flex,
  Text,
  TopEarningWorks,
  TopRevenueCountry,
  TopRevenueDSP,
} from '@/app/ui';

import { translate } from '@/app/i18n';
import Colors from '@/app/styles/Colors';
import RevenueMonthly from '@/app/ui/organisms/Royalties/RevenueMonthly';

const RoyaltiesPage = () => (
  <Flex.Container flexDirection="column" gap="3rem" padding="3rem">
    <Flex.Container>
      <Text.PageTitle color={Colors.purple}>{translate('NAVBAR.EARNINGS')} </Text.PageTitle>
      <Text.Title3>&nbsp;/ {translate('NAVBAR.ROYALTIES')}</Text.Title3>
    </Flex.Container>
    <Flex.Container gap="5rem">
      <Flex.Container flex={1} flexDirection="column" gap="2rem">
        <Text.SubtitleBold>{translate('EARNINGS.TOP_REVENUE_DSP')}</Text.SubtitleBold>
        <TopRevenueDSP />
      </Flex.Container>
      <Flex.Container flex={1} flexDirection="column" gap="2rem">
        <Text.SubtitleBold>{translate('EARNINGS.TOP_REVENUE_COUNTRY')}</Text.SubtitleBold>
        <TopRevenueCountry />
      </Flex.Container>
    </Flex.Container>
    <Flex.Container flex={1} flexDirection="column" gap="2rem">
      <Text.SubtitleBold>{translate('EARNINGS.MONTHLY_REVENUE_PLATFORM')}</Text.SubtitleBold>
      <RevenueMonthly />
    </Flex.Container>
    <Flex.Container flex={1} flexDirection="column" gap="2rem">
      <Text.SubtitleBold>{translate('EARNINGS.ANUAL_REVENUE')}</Text.SubtitleBold>
      <AnualRevenue />
    </Flex.Container>
    <Flex.Container flex={1} flexDirection="column" gap="2rem">
      <Text.SubtitleBold>{translate('EARNINGS.TOP_EARNING_WORKS')}</Text.SubtitleBold>
      <TopEarningWorks />
    </Flex.Container>
  </Flex.Container>
);

export default RoyaltiesPage;
