import { RecordingAuthor } from '@solo/app-core';
import { translate } from '@/app/i18n';
import { Button, Flex, Text } from '@/app/ui/atoms';
import { Icon } from '@/app/ui/molecules';

interface AuthorsComposersTableComponentPorps {
  recordingAuthors: Array<RecordingAuthor>;
  deleteRecordingAuthor: (authorComposer: RecordingAuthor) => void;
}

const AuthorsComposersTableComponent = ({
  recordingAuthors,
  deleteRecordingAuthor,
}: AuthorsComposersTableComponentPorps) => (
  <Flex.Container className="table" flexDirection="column">
    <Flex.Container>
      <Flex.Container flex={7}>
        <Flex.Item flex={3} className="th">
          <Text.LabelSmall>{translate('WORKS_PAGES.AUTHOR_COMPOSERS')}</Text.LabelSmall>
        </Flex.Item>
        <Flex.Item flex={2} className="th">
          <Text.LabelSmall>{translate('TRACKS.ROLE')}</Text.LabelSmall>
        </Flex.Item>
      </Flex.Container>
      <Flex.Container flex={5}>
        <Flex.Item flex={1} className="th">
          <Text.LabelSmall>{translate('TRACKS.PERF')}</Text.LabelSmall>
        </Flex.Item>
        <Flex.Item flex={1} className="th">
          <Text.LabelSmall>{translate('TRACKS.MECH')}</Text.LabelSmall>
        </Flex.Item>
        <Flex.Item flex={1} className="th" />
      </Flex.Container>
    </Flex.Container>
    <Flex.Container flexDirection="column" gap="10px">
      {recordingAuthors.map((recordingAuthor) => (
        <Flex.Container key={recordingAuthor.authorComposerId} className="tr" alignItems="center">
          <Flex.Container flex={7}>
            <Flex.Container className="td" flex={3} alignItems="center">
              {recordingAuthor.authorComposerName}
            </Flex.Container>
            <Flex.Item flex={2} className="td">
              {recordingAuthor.role}
            </Flex.Item>
          </Flex.Container>
          <Flex.Container flex={5}>
            <Flex.Item flex={1} className="td">
              {recordingAuthor.performanceSplit}%
            </Flex.Item>
            <Flex.Item flex={1} className="td">
              {recordingAuthor.mechanicalSplit}%
            </Flex.Item>

            <Flex.Item flex={1} className="td">
              <Button
                onClick={() => deleteRecordingAuthor(recordingAuthor)}
                style={{ marginLeft: '40px' }}
                $transparent
              >
                <Icon.BinIcon width={15} />
              </Button>
            </Flex.Item>
          </Flex.Container>
        </Flex.Container>
      ))}
    </Flex.Container>
    <Flex.Container className="tr" alignItems="center" style={{ marginTop: '20px' }}>
      <Flex.Container flex={7}>
        <Flex.Container className="td" flex={3} alignItems="center" />
        <Flex.Item flex={2} className="td">
          <Text.SectionSubtitleBold>Total</Text.SectionSubtitleBold>
        </Flex.Item>
      </Flex.Container>
      <Flex.Container flex={5}>
        <Flex.Item flex={1} className="td">
          <Text.SectionSubtitleBold>
            {recordingAuthors
              .map((recordingAuthor) => recordingAuthor.performanceSplit)
              .reduce((sum, element) => sum + element, 0)}
            %
          </Text.SectionSubtitleBold>
        </Flex.Item>
        <Flex.Item flex={1} className="td">
          <Text.SectionSubtitleBold>
            {recordingAuthors
              .map((recordingAuthor) => recordingAuthor.mechanicalSplit)
              .reduce((sum, element) => sum + element, 0)}
            %
          </Text.SectionSubtitleBold>
        </Flex.Item>

        <Flex.Item flex={1} className="td" />
      </Flex.Container>
    </Flex.Container>
  </Flex.Container>
);

export default AuthorsComposersTableComponent;
