import { useEffect, useState } from 'react';
import { Cradle, MusicalRight, Territory, TerritoryType } from '@solo/app-core';
import { ButtonsNavBar, Flex, Icon, Text } from '@/app/ui';
import { translate } from '@/app/i18n';
import Colors from '@/app/styles/Colors';
import { useCradle } from '@/app/contexts';
import { useBlocState } from '@/app/hooks';

type EaringsByCountry = {
  [key: string]: number;
};

type TopEarningsByCountry = {
  earnings: number;
  country: string;
};

const TopRevenueCountry = () => {
  const [activeTab, setActiveTab] = useState<string>(translate('EARNINGS.ALL'));
  const [earningType, setEarningType] = useState<MusicalRight | undefined>();
  const { consumptionReportsBloc, territoryBloc, authBloc } = useCradle<Cradle>();
  const authState = useBlocState(authBloc);

  const [earningsByCountry, setEarningsByCountry] = useState<TopEarningsByCountry[]>([]);
  const [territories, setTerritories] = useState<Territory[]>([]);

  useEffect(() => {
    async function loadTerritories() {
      const t = await territoryBloc.getTerritories(TerritoryType.COUNTRY);
      setTerritories(t);
    }
    loadTerritories();
  }, []);

  useEffect(() => {
    async function getEarningsByCountry() {
      if (authState.kind !== 'AuthenticatedAuthState') return;
      const earnings: EaringsByCountry = {};
      const earn = await consumptionReportsBloc.getEarnings({
        rightType: earningType || undefined,
        userId: authState.user.id,
      });
      earn.forEach((e) => {
        const territory = territories.find((ter) => ter.tis === e.territoryTIS);
        const territoryName = territory?.name || 'Others';
        if (earnings[territoryName] === undefined) {
          earnings[territoryName] = e.amount;
          return;
        }
        earnings[territoryName] += e.amount;
      });

      const topEarnings: TopEarningsByCountry[] = [];
      Object.keys(earnings).forEach((country) => {
        topEarnings.push({
          earnings: earnings[country],
          country,
        });
      });

      topEarnings.sort((a, b) => b.earnings - a.earnings);

      const topEarningsSorted = topEarnings.slice(0, 5);

      setEarningsByCountry(topEarningsSorted);
    }
    getEarningsByCountry();
  }, [activeTab, territories]);

  function changeTab(tab: string) {
    switch (tab) {
      case translate('EARNINGS.ALL'):
        setEarningType(undefined);
        break;
      case translate('DIGITAL_DISTRIBUTION.DIGITAL_DISTRIBUTION'):
        setEarningType(MusicalRight.DISTRIBUTION);
        break;
      default:
        setEarningType(MusicalRight.PUBLISHING);
        break;
    }
    setActiveTab(tab);
  }

  return (
    <Flex.Container gap="1rem" flexDirection="column">
      <ButtonsNavBar
        defaultTab={translate('EARNINGS.ALL')}
        tabs={[
          translate('EARNINGS.ALL'),
          translate('DIGITAL_DISTRIBUTION.DIGITAL_DISTRIBUTION'),
          translate('EARNINGS.PUBLISHING'),
        ]}
        onSelectTab={(tab) => changeTab(tab)}
      />
      <Flex.Container flexDirection="column">
        {earningsByCountry.map((earning, index) => (
          <Flex.Container
            key={earning.country}
            gap="1rem"
            style={{ borderTop: index !== 0 ? `1px solid ${Colors.borderInput}` : '' }}
            padding="1rem 0"
          >
            <Flex.Container gap="1rem">
              <Icon.SpotifyIcon />
              <Text.LabelMedium style={{ paddingTop: '3px' }}>{earning.country}</Text.LabelMedium>
            </Flex.Container>
            <Flex.Container
              style={{ width: '50%', marginLeft: 'auto' }}
              alignItems="flex-end"
              flexDirection="column"
            >
              <Text.Span style={{ textTransform: 'capitalize' }}>{earning.earnings}€</Text.Span>
            </Flex.Container>
          </Flex.Container>
        ))}
      </Flex.Container>
    </Flex.Container>
  );
};

export default TopRevenueCountry;
