export enum Month {
  January = 'Jan',
  February = 'Feb',
  March = 'Mar',
  April = 'Apr',
  May = 'May',
  June = 'Jun',
  July = 'Jul',
  August = 'Aug',
  September = 'Sept',
  October = 'Oct',
  November = 'Nov',
  December = 'Dec',
}

export type PlaysByMonth = {
  [key in Month]: number;
};

export const monthNumberToName = (month: number): Month => {
  switch (month) {
    case 0:
      return Month.January;
    case 1:
      return Month.February;
    case 2:
      return Month.March;
    case 3:
      return Month.April;
    case 4:
      return Month.May;
    case 5:
      return Month.June;
    case 6:
      return Month.July;
    case 7:
      return Month.August;
    case 8:
      return Month.September;
    case 9:
      return Month.October;
    case 10:
      return Month.November;
    default:
      return Month.December;
  }
};
