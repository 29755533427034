import { Outlet, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Cradle } from '@solo/app-core';
import {
  Flex,
  Text,
  Container,
  Section,
  CustomNavBar,
  CustomNavBarItem,
  RouterLink,
} from '@/app/ui';

import Colors from '@/app/styles/Colors';
import { translate } from '@/app/i18n';
import { useCradle } from '@/app/contexts';

const WorkPage = (): JSX.Element => {
  const { id } = useParams();
  const { musicalWorksBloc } = useCradle<Cradle>();
  const [musicalReleaseType, setMusicalReleaseType] = useState<string>('');

  useEffect(() => {
    async function getMusicalReleaseType() {
      if (!id) return;
      const musicalRelease = await musicalWorksBloc.getMusicalRelease(id);
      const { type } = musicalRelease;
      let translatedType: string = '';
      switch (type) {
        case 'EP':
          translatedType = translate('WORKS.EP_DATA');
          break;
        case 'SINGLE':
          translatedType = translate('WORKS.SINGLE_DATA');
          break;
        case 'ALBUM':
          translatedType = translate('WORKS.ALBUM_DATA');
          break;
        default:
      }
      setMusicalReleaseType(translatedType);
    }
    getMusicalReleaseType();
  }, []);

  const itemsNavBar: CustomNavBarItem[] = [
    {
      routerLinkTo: '',
      title: musicalReleaseType,
      end: true,
    },
    {
      routerLinkTo: 'tracks',
      title: translate('WORKS_PAGES.TRACKS'),
    },
    {
      routerLinkTo: 'digital-distribution',
      title: translate('WORKS_PAGES.DIGITAL_DISTRIBUTION'),
    },
  ];

  return (
    <>
      <Section py={15}>
        <Container>
          <Flex.Container>
            <RouterLink $transparent $variant="primary" to="/works">
              <Text.Title2 style={{ marginRight: 5 }}>
                {translate('WORKS_PAGES.MY_SONGS')}
              </Text.Title2>
            </RouterLink>
            <Text.Title2>/ {id}</Text.Title2>
          </Flex.Container>
        </Container>
      </Section>
      <Section py={15} style={{ paddingTop: 0 }} backgroundColor={Colors.white}>
        <Container>
          <CustomNavBar items={itemsNavBar} />
        </Container>
      </Section>
      <Section>
        <Container>
          <Flex.Container>
            <Flex.Item>
              <Outlet />
            </Flex.Item>
          </Flex.Container>
        </Container>
      </Section>
    </>
  );
};

export default WorkPage;
