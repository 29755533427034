import { VictoryPie } from 'victory';
import Colors from '@/app/styles/Colors';
import { Flex, Text } from '@/app/ui/atoms';

export interface CircleData {
  name: string;
  value: number;
}

interface CustomCircleChartProps {
  data: CircleData;
  barColor: string;
}

const CustomCircleChart = (props: CustomCircleChartProps) => {
  const { data, barColor } = props;

  return (
    <Flex.Container style={{ position: 'relative' }}>
      <VictoryPie
        innerRadius={125}
        colorScale={[barColor, Colors.borderInput]}
        data={[
          { x: ' ', y: data.value },
          { x: ' ', y: 100 - data.value },
        ]}
      />
      <Flex.Container
        style={{
          position: 'absolute',
          width: '100%',
          aspectRatio: '1',
        }}
        flexDirection="column"
        justifyContent="center"
        gap="1rem"
      >
        <Flex.Container justifyContent="center" className="mt-2">
          {data.name.length < 12 ? (
            <Text.Bigger>{data.name}</Text.Bigger>
          ) : (
            <Text.LabelMedium>{data.name}</Text.LabelMedium>
          )}
        </Flex.Container>
        <Flex.Container justifyContent="center">
          <Text.BiggerBold>{data.value}%</Text.BiggerBold>
        </Flex.Container>
      </Flex.Container>
    </Flex.Container>
  );
};

export default CustomCircleChart;
