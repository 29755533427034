import { useState } from 'react';
import { NewPasswordI } from '@solo/app-core';
import { translate } from '@/app/i18n';
import { Button, Flex, Text, Icon, ChangePasswordForm } from '@/app/ui';
import Colors from '@/app/styles/Colors';

const NewPasswordPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newPassword, setNewPassword] = useState<NewPasswordI>({
    password: '',
    repeatPassword: '',
  });
  const [changePassword, setChangePassword] = useState<boolean>(false);

  return (
    <Flex.Container
      flexDirection="column"
      alignItems="center"
      style={{ backgroundColor: Colors.white, marginTop: '15%' }}
    >
      {!changePassword && (
        <Flex.Container flexDirection="column" alignItems="center">
          <Flex.Item flex={1} className="mb-3">
            <Text.Title2 textAlign="center" style={{ fontStyle: 'italic' }}>
              {translate('RESET_PASSWORD.NEW_PASSWORD')}
            </Text.Title2>
            <Text.Paragraph>{translate('RESET_PASSWORD.ENTER_NEW_PASSWORD')}</Text.Paragraph>
          </Flex.Item>
          <Flex.Container style={{ width: '300px' }} flexDirection="column">
            <ChangePasswordForm onChange={setNewPassword} />
            <Flex.Item flex={1}>
              <Button $size="md" $full $variant="primary" onClick={() => setChangePassword(true)}>
                <Text.Span>{translate('RESET_PASSWORD.SEND_RESET_EMAIL')}</Text.Span>
              </Button>
            </Flex.Item>
          </Flex.Container>
        </Flex.Container>
      )}
      {changePassword && (
        <Flex.Container flexDirection="column" alignItems="center">
          <Icon.SpinnerCheckIcon width={120} />
          <Text.Title2>{translate('RESET_PASSWORD.YOUR_PASSWORD_HAS_BEEN_CHANGED')}</Text.Title2>
          <Text.Paragraph>{translate('RESET_PASSWORD.YOU_CAN_LOGIN')}</Text.Paragraph>
        </Flex.Container>
      )}
    </Flex.Container>
  );
};
export default NewPasswordPage;
