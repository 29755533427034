/* eslint-disable @typescript-eslint/no-unused-vars */
import { createRef, useState } from 'react';
import { MusicalRight } from '@solo/app-core';
import {
  Button,
  Flex,
  InputDateWithLabel,
  InputTextAutocomplete,
  StatementsList,
  Text,
} from '@/app/ui';

import { translate } from '@/app/i18n';
import Colors from '@/app/styles/Colors';

const status = ['Payment pending', 'Paid', 'Error'];

const StatementsPage = () => {
  const fromDateRef = createRef<HTMLInputElement>();
  const toDateRef = createRef<HTMLInputElement>();

  const [currentStatusFilter, setCurrentStatusFilter] = useState<string>('All');

  return (
    <Flex.Container flexDirection="column" gap="3rem" padding="3rem">
      <Flex.Container>
        <Text.PageTitle color={Colors.purple}>{translate('NAVBAR.EARNINGS')} </Text.PageTitle>
        <Text.Title3>&nbsp;/ {translate('NAVBAR.STATEMENTS')}</Text.Title3>
      </Flex.Container>
      <Flex.Container gap="5rem" flexDirection="column">
        <Flex.Container style={{ width: '70%', height: '40px' }} gap="1rem" alignContent="center">
          <Flex.Item flex={3}>
            <InputTextAutocomplete
              placeholder="Status"
              items={[{ value: 'All', label: 'All' }].concat(
                status.map((stat) => ({ value: stat, label: stat })),
              )}
              onChange={(stat) => setCurrentStatusFilter(stat)}
            />
          </Flex.Item>
          <Flex.Item flex={3}>
            <InputTextAutocomplete
              placeholder="Type"
              items={[
                { value: 'All', label: 'All' },
                { value: MusicalRight.DISTRIBUTION, label: MusicalRight.DISTRIBUTION },
                { value: MusicalRight.PUBLISHING, label: MusicalRight.PUBLISHING },
              ]}
              onChange={(stat) => setCurrentStatusFilter(stat)}
            />
          </Flex.Item>
          <Flex.Item flex={1}>
            <InputDateWithLabel ref={fromDateRef} />
          </Flex.Item>
          <Flex.Item flex={1}>
            <InputDateWithLabel ref={toDateRef} />
          </Flex.Item>
          <Flex.Item flex={2} style={{ paddingTop: '2px' }}>
            <Button $size="md" $variant="primary" style={{ width: '100%', height: '100%' }}>
              <Text.Paragraph style={{ padding: 0 }} fontWeight={600}>
                {translate('WORKS.SEARCH')}
              </Text.Paragraph>
            </Button>
          </Flex.Item>
        </Flex.Container>
        <StatementsList />
      </Flex.Container>
    </Flex.Container>
  );
};

export default StatementsPage;
