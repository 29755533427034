import { Outlet } from 'react-router-dom';
import Colors from '@/app/styles/Colors';
import { Flex, Main, Nav } from '@/app/ui/atoms';
import { NavLogo } from '@/app/ui/molecules';

const AuthLayout = ({ children }: { children?: JSX.Element }) => (
  <Flex.Container
    flexDirection="column"
    style={{
      backgroundColor: Colors.white,
      color: Colors.black,
      overflowX: 'hidden',
      minHeight: '100vh',
    }}
  >
    <Nav style={{ alignItems: 'flex-start', flexDirection: 'column', marginLeft: 50 }}>
      <NavLogo style={{ height: '64px', marginRight: '3rem' }} to="/" />
    </Nav>

    <Main>{children || <Outlet />}</Main>
  </Flex.Container>
);

export default AuthLayout;
