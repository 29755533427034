import { MusicalReleasesFilters, MusicalReleaseTypes } from '@solo/app-core';
import { useState } from 'react';
import { translate } from '@/app/i18n';
import { Section, Flex, Text, RouterLink, Input } from '@/app/ui/atoms';
import { InputTextAutocomplete, Item } from '@/app/ui/molecules';

interface WorksFilterComponentProps {
  filter: (filters: MusicalReleasesFilters) => void;
}
const WorksFilterComponent = ({ filter }: WorksFilterComponentProps) => {
  const [filters, setFilters] = useState<MusicalReleasesFilters>({
    title: '',
    type: undefined,
  });

  const workTypeOptions: Array<Item> = MusicalReleaseTypes.map((uType) => ({
    label: translate(`WORK_TYPE.${uType}`),
    value: translate(`WORK_TYPE.${uType}`),
  }));

  // const workStatusOptions: Array<OptionsPropsI> = Object.entries(MusicalReleaseType).map(
  //   (uType) => ({
  //     label: translate(`WORK_STATUS.${uType[0]}`),
  //     value: translate(`WORK_STATUS.${uType[1]}`),
  //   }),
  // );

  const handleOnChangeFormGroup = (path: string, value: any) => {
    const newFilter = {
      ...filters,
      [path]: value,
    };
    setFilters(newFilter);
    filter(newFilter);
  };

  return (
    <Section>
      <Flex.Container flex={1} className="mb-5">
        <Flex.Container flex={8} gap="2rem">
          <Flex.Item flex={5}>
            <Input.Text
              placeholder={translate('WORKS.SEARCH_BY_TITLE')}
              onChange={(e) => handleOnChangeFormGroup('title', e.target.value)}
            />
          </Flex.Item>
          <Flex.Item flex={3}>
            <InputTextAutocomplete
              placeholder={translate('WORKS.TYPE')}
              items={workTypeOptions}
              onChange={(type) => handleOnChangeFormGroup('type', type)}
            />
          </Flex.Item>
          {/* <Flex.Item flex={3}>
            <FormGroup
              className="mr-2"
              type="select"
              id="status"
              path="status"
              name="status"
              placeholder={translate('WORKS.STATUS')}
              value={filters.status}
              onChange={handleOnChangeFormGroup}
              options={workStatusOptions}
            />
          </Flex.Item> */}
        </Flex.Container>
        <Flex.Container flex={4} justifyContent="flex-end">
          <RouterLink className="mb-2" $size="md" $variant="primary" to="/works/add-song">
            <Text.Span style={{ padding: 0 }} fontWeight={600}>
              {translate('BUTTON.ADD_SONG')}
            </Text.Span>
          </RouterLink>
        </Flex.Container>
      </Flex.Container>
    </Section>
  );
};

export default WorksFilterComponent;
