import { createElement } from 'react';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faAngleLeft,
  faAngleDown,
  faArrowUpRightFromSquare,
  faUserCircle,
  faArrowRightFromBracket,
  faBan,
  faAngleUp,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

import Colors from '@/app/styles/Colors';
import { Img } from '@/app/ui/atoms';

import logo from '@/app/assets/images/logo.png';
import logoBlackAndWhite from '@/app/assets/images/logo-black-white.png';
import loadingImage from '@/app/assets/images/loading-image.gif';
import arrowPadIcon from '@/app/assets/images/icons/arrow-icon.svg';
import helpIcon from '@/app/assets/images/icons/help-icon.svg';
import listIcon from '@/app/assets/images/icons/list-icon.svg';
import reportIcon from '@/app/assets/images/icons/report-icon.svg';
import userIcon from '@/app/assets/images/icons/user-icon.svg';
import checkIcon from '@/app/assets/images/icons/check-icon.svg';
import spinnerCheckIcon from '@/app/assets/images/icons/spinner-check-icon.svg';
import infoIcon from '@/app/assets/images/icons/info-icon.svg';
import alertCircleIcon from '@/app/assets/images/icons/alert-circle-icon.svg';
import editIcon from '@/app/assets/images/icons/edit-icon.svg';
import binIcon from '@/app/assets/images/icons/bin-icon.svg';
import dragAndDropIcon from '@/app/assets/images/icons/drag-and-drop-icon.svg';
import spotifyIcon from '@/app/assets/images/icons/spotify-icon.svg';
import googleIcon from '@/app/assets/images/icons/google.svg';
import downloadIcon from '@/app/assets/images/icons/download.svg';
import visaIcon from '@/app/assets/images/visa-logo.png';
import mastercardIcon from '@/app/assets/images/mastercard.svg';

type ImageProps = React.ComponentProps<typeof Img>;
type FontAwesomeIconPropsNoIcon = Omit<FontAwesomeIconProps, 'icon'>;
type ImagePropsNoSrc = Omit<ImageProps, 'src'>;

/* ##### FONT AWESEOME ICONS ################################################ */

const FAIcon = (props: FontAwesomeIconProps) => {
  const { color, className, onClick } = props;

  return createElement(FontAwesomeIcon, {
    ...props,
    color: color || Colors.primary,
    className: `${className !== undefined && className}${onClick !== undefined && ' clickable'}`,
  });
};

const ArrowRight = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    icon: faAngleRight,
  });

const PrimaryArrowRight = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    icon: faAngleRight,
    color: Colors.primary,
  });

const ArrowLeft = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    icon: faAngleLeft,
  });

const ArrowDown = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    icon: faAngleDown,
  });

const ExternalLink = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    icon: faArrowUpRightFromSquare,
  });

const UserCircle = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    icon: faUserCircle,
  });

const LogOut = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    icon: faArrowRightFromBracket,
  });

const Forbidden = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    icon: faBan,
  });

const ArrowUp = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    icon: faAngleUp,
  });

const ClearCross = (props: FontAwesomeIconPropsNoIcon) =>
  createElement(FAIcon, {
    ...props,
    icon: faTimes,
  });
/* ##### CUSTOM ICONS ####################################################### */

const ImageIcon = (props: ImageProps) => {
  const { width, height, src, className, onClick } = props;

  return createElement(Img, {
    src,
    height: width === undefined && height === undefined ? 32 : height || 'auto',
    width: width || 'auto',
    ...props,
    className: `${className !== undefined ? className : ''}${
      onClick !== undefined ? ' clickable' : ''
    }`,
  });
};

const ArrowPadUp = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { src: arrowPadIcon, ...props });

const ArrowPadDown = (props: ImagePropsNoSrc) => {
  const { style } = props;
  return createElement(ArrowPadUp, { ...props, style: { ...style, transform: 'rotate(-180deg)' } });
};

const ArrowPadLeft = (props: ImagePropsNoSrc) => {
  const { style } = props;
  return createElement(ArrowPadUp, { ...props, style: { ...style, transform: 'rotate(-90deg)' } });
};

const ArrowPadRight = (props: ImagePropsNoSrc) => {
  const { style } = props;
  return createElement(ArrowPadUp, { ...props, style: { ...style, transform: 'rotate(90deg)' } });
};

const Logo = (props: ImagePropsNoSrc) => createElement(ImageIcon, { ...props, src: logo });
const LogoBlackAndWhite = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { ...props, src: logoBlackAndWhite });

const Spinner = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { src: loadingImage, height: 32, ...props });

const User = (props: ImagePropsNoSrc) => createElement(ImageIcon, { src: userIcon, ...props });

const Help = (props: ImagePropsNoSrc) => createElement(ImageIcon, { src: helpIcon, ...props });

const List = (props: ImagePropsNoSrc) => createElement(ImageIcon, { src: listIcon, ...props });

const Report = (props: ImagePropsNoSrc) => createElement(ImageIcon, { src: reportIcon, ...props });

const CheckIcon = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { height: 12, src: checkIcon, ...props });

const SpinnerCheckIcon = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { src: spinnerCheckIcon, ...props });

const InfoIcon = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { height: 12, marginLeft: '5px', src: infoIcon, ...props });

const AlertCircleIcon = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { height: 20, src: alertCircleIcon, ...props });

const EditIcon = (props: ImagePropsNoSrc) => createElement(ImageIcon, { src: editIcon, ...props });

const BinIcon = (props: ImagePropsNoSrc) => createElement(ImageIcon, { src: binIcon, ...props });

const DragAndDropIcon = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { src: dragAndDropIcon, ...props });

const SpotifyIcon = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { height: 24, src: spotifyIcon, ...props });

const GoogleIcon = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { height: '18px', src: googleIcon, ...props });

const DownloadIcon = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { height: '18px', src: downloadIcon, ...props });

const VisaIcon = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { height: '18px', src: visaIcon, ...props });

const MastercardIcon = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { height: '18px', src: mastercardIcon, ...props });

const Icons = {
  ArrowRight,
  ArrowLeft,
  ArrowDown,
  ExternalLink,
  UserCircle,
  LogOut,
  Spinner,
  Logo,
  LogoBlackAndWhite,
  ArrowPadUp,
  ArrowPadDown,
  ArrowPadLeft,
  ArrowPadRight,
  User,
  Help,
  List,
  Report,
  CheckIcon,
  SpinnerCheckIcon,
  InfoIcon,
  AlertCircleIcon,
  EditIcon,
  BinIcon,
  DragAndDropIcon,
  SpotifyIcon,
  GoogleIcon,
  Forbidden,
  ArrowUp,
  ClearCross,
  PrimaryArrowRight,
  DownloadIcon,
  VisaIcon,
  MastercardIcon,
};

export default Icons;
