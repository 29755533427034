import React, { useCallback, useEffect, useState } from 'react';
import {
  Onboarding,
  OnboardingConfig,
  DocumentType,
  DocumentCapturerType,
  CameraType,
  DocumentStageConfig,
  OnboardingClientError,
  TrialAuthenticator,
} from 'aliceonboarding';
import { Block } from '@/app/ui/atoms';
import Colors from '@/app/styles/Colors';

// aliceonboarding.d.ts has no exported variables on OnboardingClientError so in order to read its attributes
// we create a new interface extending from the original and adding that parameters to it
interface AliceOnboardingClientError extends OnboardingClientError {
  code: number;
  message: {
    exp: string;
  };
}

export interface AliceBiometricsProps {
  onSuccess: () => void;
  requestRefreshToken: () => void;
  userToken: string;
  isTrial?: boolean;
  email: string;
}

const aliceCustomTheme = {
  alice_color_primary: Colors.purple,
  alice_color_accent: Colors.purpleDarker,
  alice_color_secondary: Colors.purpleLighter,
  alice_color_background: 'white',
  alice_color_check: '#98c832',
  alice_font_family: '"Work Sans", "Open Sans", "serif", "sans-serif"',
  alice_title_font_family: '"Work Sans", "Open Sans", "serif", "sans-serif"',
  alice_title_font_size: '1.2rem',
  alice_title_font_size_desktop: '2.2em',
  alice_subtitle_font_size: '1rem',
  alice_subtitle_font_size_desktop: '1.3rem',
  alice_modal_title_font_size: '1.2rem',
  alice_modal_title_font_size_desktop: '1.5rem',
  alice_input_font_size: '1rem',
  alice_input_font_size_desktop: '1.3rem',
  alice_button_font_size: '1rem',
  alice_button_font_size_desktop: '1.3rem',
  alice_button_font_color: 'white',
  alice_button_border_radius: '0.4rem',
  alice_button_height: '2.8rem',
  alice_font_bold_weight: '500',
  alice_letter_spacing: '0.25px',
  alice_title_letter_spacing: '0.25px',
  alice_font_extra_bold_weight: '700',
};

const AliceBiometrics = (props: AliceBiometricsProps) => {
  const { userToken, onSuccess, requestRefreshToken, isTrial, email } = props;

  // State
  const [OCRUserToken, setOCRUserToken] = useState<string>(userToken);
  const [onboarding, setOnboarding] = useState<Onboarding | null>(null);

  // Functions
  const onError = useCallback(
    (error: AliceOnboardingClientError) => {
      if (error.code === 401 && error.message.exp) {
        requestRefreshToken();
      }
    },
    [requestRefreshToken],
  );

  const onCancel = () => {
    // eslint-disable-next-line no-console
    console.log('on cancel');
  };

  const aliceOnboarding = useCallback(async () => {
    // const issuingCountry = 'ESP';
    const automaticCapture = true;
    let aliceToken = OCRUserToken;

    const documentStage = new DocumentStageConfig(
      DocumentCapturerType.CAMERA,
      automaticCapture,
      CameraType.BACK,
    );

    // #### TRIAL FLOW #########################################################

    if (isTrial) {
      const authenticator = new TrialAuthenticator(aliceToken, {
        email,
      });
      aliceToken = await authenticator.execute();
    }

    // #########################################################################

    const config = new OnboardingConfig()
      .withUserToken(aliceToken)
      .withAddDocumentStage(DocumentType.IDCARD, 'SELECT', documentStage)
      // .withAddSelfieStage()
      // .withAddDocumentStage(DocumentType.DRIVERLICENSE, issuingCountry)
      // .withAddDocumentStage(DocumentType.PASSPORT, issuingCountry)
      .withCustomLocalization('es')
      .withTheme('corporate', 'minimal', 'shape', aliceCustomTheme);

    setOnboarding(
      new Onboarding('alice-onboarding-mount', config).run(onSuccess, onError, onCancel),
    );
  }, [OCRUserToken, onError, onSuccess]);

  useEffect(() => {
    setOCRUserToken(userToken);
  }, [userToken]);

  useEffect(() => {
    if (OCRUserToken === undefined || OCRUserToken === '') return;

    if (onboarding) onboarding.unmount();

    aliceOnboarding();
  }, [aliceOnboarding, OCRUserToken]);

  if (OCRUserToken === '') return <Block>Loading...</Block>;

  return <div id="alice-onboarding-mount" />;
};

export default AliceBiometrics;
