import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { Button } from '@/app/ui/atoms';

interface PaymentComponentProps {
  clientSecret: string;
  savePaymentInfo: () => void;
}

const PaymentComponent = (props: PaymentComponentProps) => {
  const { clientSecret, savePaymentInfo } = props;
  const stripe = useStripe();
  const elements = useElements();

  async function confirmPayment() {
    if (!stripe || !elements) return;
    elements.submit();
    const confirmResult = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      clientSecret,
    });

    if (confirmResult.error) {
      toast.error('Payment error');
    } else {
      savePaymentInfo();
    }
  }

  return (
    <div>
      {clientSecret && (
        <>
          <PaymentElement />
          <Button
            $size="md"
            className="mt-5"
            $full
            $variant="primary"
            onClick={() => confirmPayment()}
          >
            Submit payment
          </Button>
        </>
      )}
    </div>
  );
};

export default PaymentComponent;
