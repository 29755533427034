import React, { ChangeEvent } from 'react';
import styled from 'styled-components/macro';
import Colors from '@/app/styles/Colors';

export interface FormInputInterface {
  path: string;
  onChange?: CallableFunction;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  type: string;
  id: string;
  placeholder?: string;
  required?: boolean;
  name: string;
  readOnly?: boolean;
  min?: number;
  max?: number;
  step?: number;
}

const FormInput = (props: FormInputInterface): JSX.Element => {
  const {
    value,
    type,
    id,
    required,
    placeholder,
    name,
    readOnly,
    className = '',
    min,
    max,
    step,
  } = props;

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { path, onChange } = props;

    if (onChange) {
      onChange(path, e.target.value, id);
    }
  };

  const formatPlaceholder = (): string => {
    if (typeof placeholder === 'string') {
      return placeholder.charAt(0).toUpperCase() + placeholder.slice(1).toLowerCase();
    }
    return String(placeholder);
  };

  return (
    <input
      id={id}
      type={type}
      className={`solo-input form-control ${className} ${readOnly ? 'disabled' : ''}`}
      value={value}
      onChange={handleChange}
      autoComplete="off"
      placeholder={formatPlaceholder()}
      required={required}
      name={name}
      readOnly={readOnly}
      min={min}
      max={max}
      step={step}
    />
  );
};

export default styled(FormInput)`
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 17px;
  padding-right: 12px;
  border: 1px solid ${Colors.borderInput};
  box-shadow: 0px 3px 5px rgba(95, 95, 95, 0.02);
  background-color: ${Colors.white};
  /* box-shadow: 0px 3px 5px ${Colors.boxShadowColor}; */
  /* opacity: 0.02; */
  &.disabled {
    background-color: ${Colors.disabledInput} !important;
  }

  &:focus {
    border-color: #d8d8d8 !important;
    outline: 0;
    box-shadow: none !important;
  }

  &::-webkit-input-placeholder {
    text-transform: none;
  }
`;
