import styled from 'styled-components';
import { Flex } from '@/app/ui/atoms';
import { Icon, Tooltip } from '@/app/ui/molecules';

interface TooltipInfoProps {
  text: string;
  className?: string;
}

const TooltipInfo = ({ text, className }: TooltipInfoProps): JSX.Element => (
  <Flex.Container className={className}>
    <Icon.InfoIcon style={{ marginLeft: 10 }} width={14} />
    <Tooltip>{text}</Tooltip>
  </Flex.Container>
);

export default styled(TooltipInfo)`
  cursor: pointer;
  &:hover > div {
    display: block;
  }
`;
