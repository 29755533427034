// import Colors from '@/app/styles/Colors';
import { useState } from 'react';
import { useConfig } from '@/app/hooks';
import { translate } from '@/app/i18n';
import {
  Button,
  Flex,
  //  Button, Flex,
  Nav,
  RouterLink,
  Text,
} from '@/app/ui/atoms';
import {
  Icon,
  // Dropdown,
  // Icon,
  NavLogo,
} from '@/app/ui/molecules';

import styles from './NavBar.module.scss';

const NavBar = () => {
  const config = useConfig();
  const [isEarningsOpen, setIsEarningsOpen] = useState<boolean>(false);

  return (
    <Nav style={{ justifyContent: 'flex-start', flexDirection: 'column' }}>
      <NavLogo style={{ height: '64px', marginRight: '3rem' }} to="/" />
      <ul className={styles.menu}>
        <li className={styles.menuItem}>
          <RouterLink $transparent $underlineHover to="/dashboard">
            <Text.Span>{translate('NAVBAR.DASHBOARD')}</Text.Span>
          </RouterLink>
        </li>
        <li className={styles.menuItem}>
          <RouterLink $transparent $underlineHover to="/works">
            <Text.Span>{translate('NAVBAR.MY_SONGS')}</Text.Span>
          </RouterLink>
        </li>
        <li className={styles.menuItem}>
          <RouterLink $transparent $underlineHover to="/music-usage">
            <Text.Span>{translate('NAVBAR.MUSIC_USAGE')}</Text.Span>
          </RouterLink>
        </li>
        <li className={styles.menuItem} style={{ height: '10px' }}>
          <Button
            $transparent
            $underlineHover
            style={{ width: '100%' }}
            onClick={() => setIsEarningsOpen(!isEarningsOpen)}
          >
            <Flex.Container alignItems="center" style={{ width: '100%' }}>
              <Text.Span>{translate('NAVBAR.EARNINGS')}</Text.Span>
              <Flex.Container style={{ marginLeft: 'auto' }}>
                {isEarningsOpen ? <Icon.ArrowUp /> : <Icon.ArrowDown />}
              </Flex.Container>
            </Flex.Container>
          </Button>
        </li>
        {isEarningsOpen && (
          <Flex.Container flexDirection="column" style={{ marginLeft: '10px' }}>
            <li className={styles.menuItem} style={{ height: '5px' }}>
              <RouterLink $transparent $underlineHover to="/earnings/royalties">
                <Text.Span>{translate('NAVBAR.ROYALTIES')}</Text.Span>
              </RouterLink>
            </li>
            <li className={styles.menuItem} style={{ height: '5px' }}>
              <RouterLink $transparent $underlineHover to="/earnings/statements">
                <Text.Span>{translate('NAVBAR.STATEMENTS')}</Text.Span>
              </RouterLink>
            </li>
          </Flex.Container>
        )}
        <li className={styles.menuItem} style={{ marginTop: '12px' }}>
          <RouterLink $transparent $underlineHover to="/help">
            <Text.Span>{translate('NAVBAR.HELP')}</Text.Span>
          </RouterLink>
        </li>
        {config.development && (
          <li className={styles.menuItem}>
            <RouterLink $transparent $underlineHover to="/test">
              <Text.Span>TEST</Text.Span>
            </RouterLink>
          </li>
        )}
        {/* <Dropdown.Container style={{ marginLeft: '2rem' }}>
        <Dropdown.Button>
          <Icon.User width={16} />
        </Dropdown.Button>
        <Dropdown.Content>
          <Flex.Container flexDirection="column">
            <RouterLink
              to="/profile"
              $backgroundColorHover={Colors.grey}
              $transparent
              $size="md"
              $full
              style={{ justifyContent: 'flex-start' }}
            >
              Profile
            </RouterLink>
            <Button
              $backgroundColorHover={Colors.grey}
              $transparent
              $size="md"
              $full
              style={{ justifyContent: 'space-between' }}
              // onClick={() => {
              //   authBloc.logOut();
              // }}
            >
              Logout <Icon.LogOut width={21} />
            </Button>
          </Flex.Container>
        </Dropdown.Content>
      </Dropdown.Container> */}
        {/* <li className={styles.menuItem}>
              <Link
                $transparent
                $underlineHover
                href={config.marketUrl}
                target="_blank"
                rel="noreferrer"
              >
                <Text.Span>Marketplace</Text.Span>
                <Icon.ExternalLink
                  color={Colors.white}
                  style={{ marginLeft: '0.5rem' }}
                  size="sm"
                />
              </Link>
            </li> */}
      </ul>
    </Nav>
  );
};

export default NavBar;
