import { useEffect, useState } from 'react';
import {
  Cradle,
  CreateDistributionProps,
  Distribution,
  DistributionId,
  MusicalReleaseId,
  Territory,
  TerritoryRestriction,
  TerritoryRestrictionModificator,
  TerritoryType,
  UpdateDistributionProps,
} from '@solo/app-core';
import { translate } from '@/app/i18n';
import { Button, Flex, Text } from '@/app/ui/atoms';
import {
  CheckboxWithLabel,
  Icon,
  InputTextAutocomplete,
  InputWithLabel,
  RadioButtonWithLabel,
} from '@/app/ui/molecules';
import { useCradle } from '@/app/contexts';
import { useBlocState } from '@/app/hooks';
import { TerritoriesTableComponent } from '@/app/ui';

export type UpdateDist = {
  distributionId: DistributionId;
  props: UpdateDistributionProps;
};

export type MusicalReleaseDistributionData = {
  newDistributions: CreateDistributionProps[];
  updatedDistributions: UpdateDist[];
  deletedDistributions: DistributionId[];
};

interface MusicalReleaseDistributionFormProps {
  distributionData: Distribution[];
  musicalReleaseId: MusicalReleaseId;
  closeModal?: () => void;
  saveDistributions?: (distributionData: MusicalReleaseDistributionData) => void;
  onChange?: (distributionData: MusicalReleaseDistributionData) => void;
}

const MusicalReleaseDistributionForm = (props: MusicalReleaseDistributionFormProps) => {
  const { distributionData, musicalReleaseId, closeModal, saveDistributions, onChange } = props;

  const [distribution, setDistribution] = useState<MusicalReleaseDistributionData>();

  const [checkedStores, setCheckedStores] = useState<string[]>([]);

  const [hasAppleId, setHasAppleId] = useState<boolean | undefined>();
  const [hasSpotifyId, setHasSpotifyId] = useState<boolean | undefined>();
  const [appleId, setAppleId] = useState<string>();
  const [spotifyId, setSpotifyId] = useState<string>();

  const [territories, setTerritories] = useState<Territory[]>([]);
  const [territoryRestrictions, setTerritoryRestrictions] = useState<TerritoryRestriction[]>(
    distributionData[0] ? distributionData[0].territoryRestrictions : [],
  );
  const [allTerritoriesSelected, setAllTerritoriesSelected] = useState<boolean>(
    territoryRestrictions[0]?.territory.tis === 0 || territoryRestrictions.length === 0,
  );
  const [updateDistributions, setUpdateDistributions] = useState<boolean>(false);

  const { digitalDistributionBloc } = useCradle<Cradle>();
  const digitalDistributionState = useBlocState(digitalDistributionBloc);
  const { musicalWorksBloc } = useCradle<Cradle>();
  const musicalWorksState = useBlocState(musicalWorksBloc);
  const { territoryBloc } = useCradle<Cradle>();

  const digitalPlatforms =
    digitalDistributionState.kind === 'LoadedDigitalDistributionState'
      ? digitalDistributionState.platforms
      : [];

  const [currentTerritoryTIS, setCurrentTerritoryTIS] = useState<string>();
  const [currentTerritoryMod, setCurrentTerritoryMod] = useState<TerritoryRestrictionModificator>();

  useEffect(() => {
    async function getTerritories() {
      setTerritories(await territoryBloc.getTerritories(TerritoryType.COUNTRY));
    }
    async function getDistributionsData() {
      if (digitalDistributionState.kind !== 'LoadedDigitalDistributionState') return;
      const platformsIds = distributionData.map((dist) => dist.digitalPlatformId);
      const platforms = digitalDistributionState.platforms.filter((platform) =>
        platformsIds.includes(platform.id),
      );
      const ids = platforms.map((platform) => platform.id);
      setCheckedStores(ids);

      const applePlatform = platforms.find((p) => p.name === 'Apple Music');
      const appleDistribution = await digitalDistributionBloc.getDistributions({
        musicalReleaseId,
        digitalPlatformId: applePlatform?.id,
      });
      if (
        appleDistribution[0]?.platformUserId !== undefined &&
        appleDistribution[0]?.platformUserId !== ''
      ) {
        setHasAppleId(true);
        setAppleId(appleDistribution[0]?.platformUserId);
      }
      const spotifyPlatform = platforms.find((p) => p.name === 'Spotify');
      const spotifyDistribution = await digitalDistributionBloc.getDistributions({
        musicalReleaseId,
        digitalPlatformId: spotifyPlatform?.id,
      });
      if (
        spotifyDistribution[0]?.platformUserId !== undefined &&
        spotifyDistribution[0]?.platformUserId !== ''
      ) {
        setHasSpotifyId(true);
        setSpotifyId(spotifyDistribution[0]?.platformUserId);
      }
    }
    getTerritories();
    getDistributionsData();
  }, [musicalWorksState]);

  function toggleAll() {
    if (checkedStores && checkedStores.length === digitalPlatforms.length) {
      setCheckedStores([]);
    } else {
      setCheckedStores(digitalPlatforms.map((platform) => platform.id));
    }
  }
  function checkStore(store: string | undefined) {
    if (store) {
      if (!checkedStores.includes(store)) {
        setCheckedStores([...checkedStores, store]);
        return;
      }
      setCheckedStores(checkedStores.filter((checkedStore) => checkedStore !== store));
    }
  }

  useEffect(() => {
    async function updateDist() {
      const newDistributions: CreateDistributionProps[] = [];
      const updatedDistributions: UpdateDist[] = [];
      const platformsIds = distributionData.map((dist) => dist.digitalPlatformId);
      checkedStores.forEach((id) => {
        if (!platformsIds.includes(id)) {
          let platformUserId = '';
          const platformName = digitalPlatforms.find((plat) => plat.id === id)?.name;
          if (platformName === 'Apple Music')
            platformUserId = hasAppleId && appleId ? appleId : 'automaticAppleId';
          if (platformName === 'Spotify') {
            platformUserId = hasSpotifyId && spotifyId ? spotifyId : 'automaticSpotifyId';
          }
          newDistributions.push({
            digitalPlatformId: id,
            territoryRestrictions: allTerritoriesSelected
              ? [
                  {
                    mod: TerritoryRestrictionModificator.INCLUDE,
                    territory: {
                      tis: 0,
                      name: 'WorldWide',
                      iso: 'WorldWide',
                      type: TerritoryType.COUNTRY, // TODO per ara nomes hi ha countries al CORE
                    },
                  },
                ]
              : territoryRestrictions,
            platformUserId,
          });
          return;
        }
        const existingDistribution = distributionData.find((dist) => dist.digitalPlatformId === id);

        if (updateDistributions && existingDistribution !== undefined)
          updatedDistributions.push({
            distributionId: existingDistribution.id,
            props: {
              territoryRestrictions: allTerritoriesSelected
                ? [
                    {
                      mod: TerritoryRestrictionModificator.INCLUDE,
                      territory: {
                        tis: 0,
                        name: 'WorldWide',
                        iso: 'WorldWide',
                        type: TerritoryType.COUNTRY,
                      },
                    },
                  ]
                : territoryRestrictions,
            },
          });
      });
      const deletedDist: Distribution[] = await distributionData.filter(
        (dist) => !checkedStores.includes(dist.digitalPlatformId),
      );
      const deletedDistributions = deletedDist.map((d) => d.id);

      setDistribution({ newDistributions, updatedDistributions, deletedDistributions });
    }
    updateDist();
  }, [checkedStores, territoryRestrictions, allTerritoriesSelected, spotifyId, appleId]);

  useEffect(() => {
    if (distribution && onChange) onChange(distribution);
  }, [distribution]);

  function saveDist() {
    if (!saveDistributions || !distribution || !closeModal) return;
    saveDistributions(distribution);
    closeModal();
  }
  function addTerritoryRestriction() {
    if (!currentTerritoryTIS || !currentTerritoryMod) return;
    const territory: Territory | undefined = territories.find(
      (t) => t.tis.toString() === currentTerritoryTIS,
    );

    if (!territory) return;

    const newTerritoryRestriction: TerritoryRestriction = {
      mod: currentTerritoryMod,
      territory,
    };

    if (
      territoryRestrictions.find(
        (tr) => tr.territory.tis === newTerritoryRestriction.territory.tis,
      ) === undefined
    )
      setTerritoryRestrictions([...territoryRestrictions, newTerritoryRestriction]);
    setUpdateDistributions(true);
    setCurrentTerritoryMod(undefined);
    setCurrentTerritoryTIS(undefined);
  }

  function deleteTerritoryRestriction(territoryRestriction: TerritoryRestriction) {
    const restrictions = territoryRestrictions.filter(
      (t) => t.territory.tis !== territoryRestriction.territory.tis,
    );
    setUpdateDistributions(true);
    setTerritoryRestrictions(restrictions);
  }

  return (
    // const { releaseData } = props;
    <Flex.Container flexDirection="column" gap="6rem" style={{ width: '100%' }}>
      <Flex.Container gap="5rem" flexDirection="column">
        <Flex.Container gap="4rem" flexDirection="column">
          <Text.SubtitleBold>{translate('WORKS_PAGES.DIGITAL_DISTRIBUTION')}</Text.SubtitleBold>
          <Text.LabelMedium>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. At nec cras mauris, blandit in
            id non tellus. Tincidunt quis facilisi fermentum at eu, nullam sodales pellentesque
            mauris. Volutpat sed nibh semper felis tellus.
          </Text.LabelMedium>
        </Flex.Container>
        <Flex.Container flexDirection="column" gap="20px">
          <CheckboxWithLabel
            checked={
              checkedStores !== undefined && checkedStores.length === digitalPlatforms.length
            }
            onChange={() => toggleAll()}
            label={translate('WORKS_PAGES.SELECT_ALL_STORES')}
          />
          <Flex.Container flexDirection="row" flexWrap="wrap" gap="20px 0px">
            {digitalPlatforms.map((store) => (
              <Flex.Container key={store.id} style={{ width: '33%' }}>
                <CheckboxWithLabel
                  value={store.id}
                  onChange={(value?: string) => checkStore(value)}
                  checked={checkedStores.includes(store.id)}
                  label={store.name}
                />
              </Flex.Container>
            ))}
          </Flex.Container>
        </Flex.Container>
      </Flex.Container>

      <Flex.Container gap="25px" flexDirection="column">
        <Text.SubtitleBold>{translate('WORKS_PAGES.TERRITORY_RESTRICTIONS')}</Text.SubtitleBold>

        <Flex.Container gap="0.5rem" alignItems="center">
          <Icon.AlertCircleIcon />
          <Text.LabelMedium>{translate('WORKS_PAGES.CHANGE_RESTRICTION_WARNING')}</Text.LabelMedium>
        </Flex.Container>

        <Flex.Container gap="13rem">
          <CheckboxWithLabel
            checked={allTerritoriesSelected}
            onChange={() => setAllTerritoriesSelected(true)}
            label={translate('WORKS_PAGES.ALL_TERRITORIES')}
          />
          <CheckboxWithLabel
            checked={!allTerritoriesSelected && allTerritoriesSelected !== undefined}
            onChange={() => setAllTerritoriesSelected(false)}
            label={translate('WORKS_PAGES.SPECIFIC_TERRITORIES')}
          />
        </Flex.Container>
        {!allTerritoriesSelected && allTerritoriesSelected !== undefined && (
          <Flex.Container gap="16px" style={{ width: '100%' }} flexDirection="column">
            <Flex.Container gap="16px">
              <Flex.Container flex={2}>
                <InputTextAutocomplete
                  label={translate('WORKS.INCLUDE_EXCLUDE')}
                  items={[
                    { value: translate('WORKS.INCLUDE'), label: translate('WORKS.INCLUDE') },
                    { value: translate('WORKS.EXCLUDE'), label: translate('WORKS.EXCLUDE') },
                  ]}
                  onChange={(mod) => setCurrentTerritoryMod(mod as TerritoryRestrictionModificator)}
                />
              </Flex.Container>
              <Flex.Container flex={4}>
                <InputTextAutocomplete
                  label={translate('WORKS.TERRITORY')}
                  placeholder={`${translate('WORKS.TERRITORY')}...`}
                  items={territories.map((territory) => ({
                    value: territory.tis.toString(),
                    label: territory.name,
                  }))}
                  onChange={(tis) => setCurrentTerritoryTIS(tis)}
                />
              </Flex.Container>
              <Flex.Container flex={1}>
                <Button
                  $size="md"
                  $variant="primary"
                  style={{ marginTop: '24px', height: '40px', width: '120px' }}
                  onClick={() => addTerritoryRestriction()}
                >
                  <Text.SectionSubtitle style={{ padding: 0 }} fontWeight={600}>
                    {translate('BUTTON.ADD')}
                  </Text.SectionSubtitle>
                </Button>
              </Flex.Container>
            </Flex.Container>
            {territoryRestrictions.length > 0 && (
              <TerritoriesTableComponent
                territories={territoryRestrictions}
                deleteTerritory={(territory) => deleteTerritoryRestriction(territory)}
              />
            )}
          </Flex.Container>
        )}
      </Flex.Container>
      <Flex.Container>
        <Flex.Container flex={1} flexDirection="column" gap="2.5rem">
          <Text.SubtitleBold>{translate('WORKS_PAGES.APPLE_ID')}</Text.SubtitleBold>
          <Flex.Container flexDirection="column" gap="1rem" style={{ width: '80%' }}>
            <RadioButtonWithLabel
              checked={hasAppleId || false}
              label={translate('TRACKS.YES')}
              onChange={() => setHasAppleId(true)}
            />
            {hasAppleId && (
              <InputWithLabel
                label="Apple Music ID"
                placeholder="Apple Music ID"
                value={appleId}
                onChange={(id) => setAppleId(id)}
              />
            )}
          </Flex.Container>
          <RadioButtonWithLabel
            checked={!hasAppleId && hasAppleId !== undefined}
            label={translate('WORKS_PAGES.NO_ID')}
            onChange={() => setHasAppleId(false)}
          />
        </Flex.Container>

        <Flex.Container flex={1} flexDirection="column" gap="2.5rem">
          <Text.SubtitleBold>{translate('WORKS_PAGES.SPOTIFY_ID')}</Text.SubtitleBold>
          <Flex.Container flexDirection="column" gap="1rem" style={{ width: '80%' }}>
            <RadioButtonWithLabel
              checked={hasSpotifyId || false}
              label={translate('TRACKS.YES')}
              onChange={() => setHasSpotifyId(true)}
            />
            {hasSpotifyId && (
              <InputWithLabel
                label="Spotify ID"
                placeholder="Spotify ID"
                value={spotifyId}
                onChange={(id) => setSpotifyId(id)}
              />
            )}
          </Flex.Container>

          <RadioButtonWithLabel
            checked={!hasSpotifyId && hasSpotifyId !== undefined}
            label={translate('WORKS_PAGES.NO_ID')}
            onChange={() => setHasSpotifyId(false)}
          />
        </Flex.Container>
      </Flex.Container>
      {closeModal && distributionData && (
        <Flex.Container style={{ marginLeft: 'auto' }} gap="20px">
          <Button
            $transparent
            $variant="primary"
            onClick={() => {
              closeModal();
            }}
          >
            <Text.SectionSubtitle>{translate('PROFILE_INFO.CANCEL')}</Text.SectionSubtitle>
          </Button>

          <Button
            $size="md"
            $variant="primary"
            style={{ width: '290px' }}
            onClick={() => saveDist()}
          >
            <Text.SectionSubtitle style={{ padding: 0 }} fontWeight={600}>
              {translate('PROFILE_INFO.SAVE')}
            </Text.SectionSubtitle>
          </Button>
        </Flex.Container>
      )}
    </Flex.Container>
  );
};

export default MusicalReleaseDistributionForm;
