import { useEffect, useState } from 'react';
import { Cradle, Usage } from '@solo/app-core';
import { Button, Flex, RouterLink, Text } from '@/app/ui/atoms';
import { useCradle } from '@/app/contexts';
import UsageDetailsList from '@/app/ui/organisms/Usages/UsageDetailsList';
import { translate } from '@/app/i18n';
import Colors from '@/app/styles/Colors';
import DefaultPagination from '@/app/constants/Pagination';

const TopSongsPlatform = () => {
  const [usagesData, setUsagesData] = useState<Usage[]>([]);

  const { consumptionReportsBloc } = useCradle<Cradle>();

  useEffect(() => {
    async function getTopSongsData() {
      const usagesFiltered = await consumptionReportsBloc.getUsages(undefined, {
        limit: DefaultPagination.limit,
      });
      setUsagesData(usagesFiltered.data);
    }
    getTopSongsData();
  }, []);

  return (
    <Flex.Container flexDirection="column" className="mt-5" gap="5rem">
      <UsageDetailsList usages={usagesData} />
      <Flex.Container justifyContent="center">
        <RouterLink to="/music-usage/usage-detail" style={{ width: '25%' }}>
          <Button
            $size="sm"
            $variant="primary"
            $backgroundColor={Colors.transparent}
            $backgroundColorHover={Colors.transparent}
          >
            <Text.Paragraph style={{ padding: 0 }} fontWeight={600}>
              {translate('EARNINGS.SEE_ALL')}
            </Text.Paragraph>
          </Button>
        </RouterLink>
      </Flex.Container>
    </Flex.Container>
  );
};

export default TopSongsPlatform;
