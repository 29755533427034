import Colors from '@/app/styles/Colors';
import { Flex, Text } from '@/app/ui/atoms';

interface FormProgressProps {
  readonly steps?: string[];
  readonly currentStep: number;
  readonly canGoBack: boolean;
  readonly setCurrentStep: (step: number) => void;
}

const FormProgress = (props: FormProgressProps) => {
  const { steps, currentStep, canGoBack, setCurrentStep } = props;
  return (
    <Flex.Container gap="20px" style={{ width: '100%', paddingBottom: '16px' }}>
      {steps?.map((step, index) => {
        if (index + 1 < currentStep) {
          return (
            <Flex.Container
              key={step}
              flex={1}
              style={{ borderBottom: `12px solid ${Colors.purpleLighter}`, cursor: 'pointer' }}
              onClick={() => canGoBack && setCurrentStep(index + 1)}
            >
              <Text.SectionSubtitle style={{ color: Colors.purpleLighter }}>
                <Flex.Container gap="10px">
                  {index + 1}. {step}
                </Flex.Container>
              </Text.SectionSubtitle>
            </Flex.Container>
          );
        }
        if (index + 1 === currentStep) {
          return (
            <Flex.Container
              key={step}
              flex={1}
              style={{
                borderBottom: `12px solid ${Colors.purple}`,
                paddingBottom: '10px',
                flex: 1,
              }}
            >
              <Text.SectionSubtitle style={{ color: Colors.purple }}>
                {index + 1}. {step}
              </Text.SectionSubtitle>
            </Flex.Container>
          );
        }
        return (
          <Flex.Container
            flex={1}
            key={step}
            style={{ borderBottom: `12px solid ${Colors.grey3}` }}
          >
            <Text.SectionSubtitle style={{ color: Colors.grey3 }}>
              {index + 1}. {step}
            </Text.SectionSubtitle>
          </Flex.Container>
        );
      })}
    </Flex.Container>
  );
};

export default FormProgress;
