import { Flex, Input, Text } from '@/app/ui/atoms';
import { Icon } from '@/app/ui/molecules';

interface CheckboxProps {
  label: string;
  tooltip?: boolean;
  checked: boolean;
  value?: string;
  bold?: boolean;
  onChange?: (value?: string) => void;
}

const RadioButtonWithLabel = (props: CheckboxProps) => {
  const { label, tooltip, checked, value, bold, onChange } = props;

  const handleOnChange = (v: string | undefined) => {
    if (onChange === undefined || typeof onChange !== 'function') return;
    onChange(v);
  };

  return (
    <Flex.Container alignItems="center" gap="0.5rem" onClick={() => handleOnChange(value)}>
      <Input.Radio
        checked={checked}
        onChange={() => handleOnChange(value)}
        id="checkbox"
        style={{ marginRight: '15px' }}
      />
      {bold ? (
        <Text.LabelMedium htmlFor="checkbox">{label}</Text.LabelMedium>
      ) : (
        <Text.SectionSubtitle>{label}</Text.SectionSubtitle>
      )}
      {tooltip && <Icon.InfoIcon />}
    </Flex.Container>
  );
};

export default RadioButtonWithLabel;
