import { translate } from '@/app/i18n';
import Colors from '@/app/styles/Colors';
import { Flex, Text, RouterLink } from '@/app/ui/atoms';

const SignUpFooter = () => (
  <Flex.Container gap="3rem">
    <RouterLink to="/" $transparent>
      <Text.LabelSmall style={{ color: Colors.white }}>
        {translate('LOGIN.ABOUT_US')}
      </Text.LabelSmall>
    </RouterLink>
    <RouterLink to="/" $transparent>
      <Text.LabelSmall style={{ color: Colors.white }}>
        {translate('LOGIN.TERMS_CONDITIONS')}
      </Text.LabelSmall>
    </RouterLink>
    <RouterLink to="/" $transparent>
      <Text.LabelSmall style={{ color: Colors.white }}>
        {translate('LOGIN.PRIVACY_POLICY')}
      </Text.LabelSmall>
    </RouterLink>
    <RouterLink to="/" $transparent>
      <Text.LabelSmall style={{ color: Colors.white }}>Solorights S.L. 2022</Text.LabelSmall>
    </RouterLink>
  </Flex.Container>
);

export default SignUpFooter;
