import styled from 'styled-components';
import Colors from '@/app/styles/Colors';
import { Block } from '@/app/ui';

const Tooltip = styled(Block)`
  position: absolute;
  display: none;
  z-index: 9999;
  width: fit-content;
  padding: 5px;
  background: ${Colors.grey3};
`;
// ${Colors.black.concat('80')}

export default Tooltip;
