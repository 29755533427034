import { translate } from '@/app/i18n';
import Colors from '@/app/styles/Colors';
import { Flex, SubscriptionCard, Text } from '@/app/ui';

// TODO CORE
interface SubscriptionInfo {
  title: string;
  price: number;
  description: string;
  rights: { title: string; description: string }[];
}

interface SubscriptionTypeSelectComponentProps {
  setSelectedSubscription: (subscription: SubscriptionInfo) => void;
}
const SubscriptionTypeSelectComponent = ({
  setSelectedSubscription,
}: SubscriptionTypeSelectComponentProps) => {
  const allRights = [
    {
      title: 'Publishing rights',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vulputate vitae commodo tortor.',
    },
    {
      title: 'Digital distribution',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vulputate vitae commodo tortor.',
    },
  ];
  const allRightsInfo = {
    title: translate('SIGN_UP.ALL_RIGHTS'),
    price: 100,
    description: '(Publishing & Digital)',
    rights: allRights,
  };

  const publishingRights = [
    {
      title: 'Publishing rights',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vulputate vitae commodo tortor.',
    },
  ];
  const publishingRightsInfo = {
    title: translate('SIGN_UP.PUBLISHING_RIGHTS'),
    price: 10,
    description: '(Publishing)',
    rights: publishingRights,
  };

  return (
    <Flex.Container flexDirection="column" gap="2rem" style={{ width: '100%' }}>
      <Text.Title2 style={{ color: Colors.white, paddingLeft: '10rem' }} className="mt-2 mb-2">
        {translate('SIGN_UP.CHOOSE_THE_BEST_SUBSCRIPTION')}
      </Text.Title2>

      <Flex.Container justifyContent="center">
        <Flex.Container gap="40px" style={{ width: '66%' }}>
          <Flex.Item flex={1}>
            <SubscriptionCard
              info={allRightsInfo}
              select={() => setSelectedSubscription(allRightsInfo)}
            />
          </Flex.Item>
          <Flex.Item flex={1}>
            <SubscriptionCard
              info={publishingRightsInfo}
              select={() => setSelectedSubscription(publishingRightsInfo)}
            />
          </Flex.Item>
        </Flex.Container>
      </Flex.Container>
    </Flex.Container>
  );
};
export default SubscriptionTypeSelectComponent;
