import { translate } from '@/app/i18n';
import Colors from '@/app/styles/Colors';
import { Button, Flex, Text } from '@/app/ui/atoms';
import { Icon } from '@/app/ui/molecules';

interface DashboardHelpCardProps {
  title: string;
  text: string;
}
const DashboardHelpCard = (props: DashboardHelpCardProps) => {
  const { title, text } = props;
  return (
    <Flex.Container
      flex={1}
      flexDirection="column"
      gap="1rem"
      padding="1.5rem 1rem 5rem 1rem"
      style={{ border: `1px solid ${Colors.borderInput}`, position: 'relative' }}
    >
      <Text.SectionTitle>{title}</Text.SectionTitle>
      <Flex.Container style={{ width: '90%' }}>
        <Text.LabelMedium>{text}</Text.LabelMedium>
      </Flex.Container>
      <Button $transparent $size="sm" style={{ position: 'absolute', bottom: 20, right: 20 }}>
        <Flex.Container gap="1rem" alignItems="center">
          <Text.SectionSubtitleBold style={{ color: Colors.primary }}>
            {translate('BUTTON.LEARN_MORE')}
          </Text.SectionSubtitleBold>
          <Icon.ArrowRight />
        </Flex.Container>
      </Button>
    </Flex.Container>
  );
};

export default DashboardHelpCard;
