import { useEffect, useState } from 'react';
import { Cradle, PaymentMethod, UserSubscription } from '@solo/app-core';
import { toast } from 'react-toastify';
import { Button, Flex, Img, Link, Section, Text } from '@/app/ui';
import { translate } from '@/app/i18n';
import Colors from '@/app/styles/Colors';
import { useCradle } from '@/app/contexts';
import { dayjs } from '@/app/utils';
import visalogo from '@/app/assets/images/visa-logo.png';

interface CardNumberProps {
  last4: number;
}

const CardNumber = (props: CardNumberProps) => {
  const { last4 } = props;
  return (
    <Text.Paragraph
      style={{ marginLeft: 16, marginRight: 32 }}
    >{`**** **** **** ${last4}`}</Text.Paragraph>
  );
};

interface SubscriptionTypeProps {
  // TODO get types from CORE
  subscriptionType: string;
}
const SubscriptionType = (props: SubscriptionTypeProps) => {
  const { subscriptionType } = props;
  return subscriptionType === `All` ? (
    <>
      <Text.BigItalic style={{ fontStyle: 'italic' }}>
        {translate('SUBSCRIPTION_INFO.ALL_RIGHTS')}
      </Text.BigItalic>
      <Text.Span
        style={{
          fontSize: '14px',
          color: Colors.grey,
          paddingLeft: '4px',
        }}
      >
        {translate('SUBSCRIPTION_INFO.ALL_RIGHTS_DETAIL')}
      </Text.Span>
    </>
  ) : (
    <>
      <Text.BigItalic style={{ fontStyle: 'italic' }}>
        {translate('SUBSCRIPTION_INFO.DISTRIBUTION')}
      </Text.BigItalic>
      <Text.Span
        style={{
          fontSize: '14px',
          color: Colors.grey,
          paddingLeft: '4px',
        }}
      >
        {translate('SUBSCRIPTION_INFO.DISTRIBUTION_RIGHTS_DETAIL')}
      </Text.Span>
    </>
  );
};

const SubscriptionComponent = () => {
  const [cards, setCards] = useState<PaymentMethod[]>([]);
  // TODO
  const subsTypeLink = 'https:solo.com/help/subscrition-types';
  const [currentSubscription, setCurrentSubscription] = useState<UserSubscription>();

  const { paymentBloc } = useCradle<Cradle>();

  useEffect(() => {
    async function getSubscription() {
      const subscription = await paymentBloc.getSubscriptionInfo();
      setCurrentSubscription(subscription);
    }
    async function getCardInfo() {
      try {
        const myCards = await paymentBloc.getStripePaymentMethods();
        setCards(myCards);
      } catch (error) {
        toast.error(translate('TOAST.CARD_ERROR'));
      }
    }
    getSubscription();
    getCardInfo();
  }, []);

  return (
    <>
      <Section py={15} style={{ flexDirection: 'column', paddingTop: 0 }}>
        <Text.SectionTitle style={{ marginBottom: '24px' }}>
          {translate('PROFILE_PAGES.SUBSCRIPTION')}
        </Text.SectionTitle>
        <Flex.Container alignItems="center">
          <SubscriptionType subscriptionType={currentSubscription?.type || 'All'} />
          <Flex.Item>
            <Button
              $transparent
              $size="md"
              $full
              style={{ justifyContent: 'space-between', color: Colors.primary, fontWeight: 700 }}
              // onClick={() => {
              // TODO EDIT
              //   handleEdit();
              // }}
            >
              {translate('SUBSCRIPTION_INFO.MANAGE_SUBSCRIPTION')}
            </Button>
          </Flex.Item>
        </Flex.Container>
        <Flex.Item style={{ marginTop: 10, marginBottom: 24 }}>
          <Link $transparent $underlineHover href={subsTypeLink} target="_blank" rel="noreferrer">
            <Text.Span style={{ color: Colors.grey, textDecoration: 'underline' }}>
              {translate('SUBSCRIPTION_INFO.SUBSCRIPTION_KNOW_MORE')}
            </Text.Span>
          </Link>
        </Flex.Item>
        <Flex.Item style={{ marginTop: 10 }}>
          <Text.Span>{translate('SUBSCRIPTION_INFO.SUBSCRIPTION_RENEWED_ON')}</Text.Span>
          <Text.Span style={{ fontWeight: 700 }}>
            {dayjs(currentSubscription?.currentSubPeriodEnd).format('DD, MMM YYYY')}
          </Text.Span>
        </Flex.Item>
      </Section>
      <Section py={15} style={{ flexDirection: 'column', paddingTop: 0 }}>
        <Text.SectionTitle style={{ marginBottom: '14px' }}>
          {translate('SUBSCRIPTION_INFO.PAYMENT_METHOD')}
        </Text.SectionTitle>
        <Text.Paragraph style={{ marginBottom: '24px' }}>
          {translate('SUBSCRIPTION_INFO.PAYMENT_METHOD_TEXT')}
        </Text.Paragraph>
        <Flex.Container alignItems="center">
          {cards[0] && cards[0].card && (
            <>
              <Img src={visalogo} height={24} width={38} />
              <CardNumber last4={cards[0].card.last4} />
            </>
          )}
          {/* <Flex.Item>
            <Button
              $transparent
              $size="md"
              $full
              style={{ justifyContent: 'space-between', color: Colors.primary, fontWeight: 700 }}
              // onClick={() => {
              // TODO EDIT
              //   handleEdit();
              // }}
            >
              {translate('SUBSCRIPTION_INFO.EDIT')}
            </Button>
          </Flex.Item> */}
        </Flex.Container>
      </Section>
    </>
  );
};

export default SubscriptionComponent;
