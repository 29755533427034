import React, { ChangeEvent } from 'react';
import styled from 'styled-components/macro';
import Colors from '@/app/styles/Colors';

export interface OptionsPropsI {
  label: string;
  value: number | string;
}

export interface SelectInterface {
  id: string;
  // type: string;
  // name: string;
  path: string;
  placeholder: string;
  className?: string;
  // checked?: boolean;
  onChange?: CallableFunction;
  value?: number;
  options?: Array<OptionsPropsI>;
  disabled?: boolean;
}
const FormSelect = (props: SelectInterface): JSX.Element => {
  const { options, value, placeholder, disabled, className = '' } = props;

  const handleChange = (e: ChangeEvent<HTMLSelectElement>): void => {
    const { path, onChange, id } = props;

    if (onChange) {
      onChange(path, e.target.value, id);
    }
  };

  return (
    <div className="FormSelect">
      <select
        value={value}
        onChange={handleChange}
        className={`solo-input FormInput form-control pt-2 ${className} select ${
          disabled ? 'disabled' : ''
        }`}
        disabled={disabled}
      >
        <option value="" key={undefined} className="option">
          {placeholder}
        </option>

        {options?.map((item) => (
          <option value={item.value} key={item.value} className="option">
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default styled(FormSelect)`
  padding-top: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  padding-left: 17px;
  border: 1px solid ${Colors.borderInput};
  box-shadow: 0px 3px 5px rgba(95, 95, 95, 0.02);
  width: 100%;
  /* box-shadow: 0px 3px 5px ${Colors.boxShadowColor}; */
  /* opacity: 0.02; */
  &.disabled {
    background-color: ${Colors.disabledInput} !important;
  }
`;
