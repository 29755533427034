import Colors from '@/app/styles/Colors';
import { Flex, Text } from '@/app/ui/atoms';
import { Icon } from '@/app/ui/molecules';

interface TextWithLabelProps {
  label: string;
  text: string;
  tooltip?: boolean;
}

const TextWithLabel = (props: TextWithLabelProps) => {
  const { label, text, tooltip } = props;

  return (
    <Flex.Container flexDirection="column" gap="0.5rem">
      <Flex.Container gap="0.5rem" alignItems="center">
        <Text.LabelSmall htmlFor="title_input" color={Colors.grey} style={{ marginBottom: '2px' }}>
          {label}
        </Text.LabelSmall>
        {tooltip && <Icon.InfoIcon />}
      </Flex.Container>
      <Text.SubtitleLight>{text}</Text.SubtitleLight>
    </Flex.Container>
  );
};

export default TextWithLabel;
