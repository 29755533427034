import { TerritoryRestriction } from '@solo/app-core';
import { translate } from '@/app/i18n';
import { Button, Flex, Text } from '@/app/ui/atoms';
import { Icon } from '@/app/ui/molecules';

interface TerritoriesTableComponentPorps {
  territories: TerritoryRestriction[];
  deleteTerritory: (territory: TerritoryRestriction) => void;
}

const TerritoriesTableComponent = ({
  territories,
  deleteTerritory,
}: TerritoriesTableComponentPorps) => (
  <Flex.Container className="table" flexDirection="column">
    <Flex.Container gap="16px">
      <Flex.Item flex={2} className="th">
        <Text.LabelSmall>{translate('WORKS.MODIFICATOR')}</Text.LabelSmall>
      </Flex.Item>
      <Flex.Item flex={4} className="th">
        <Text.LabelSmall>{translate('WORKS.TERRITORY')}</Text.LabelSmall>
      </Flex.Item>
      <Flex.Item flex={1} className="th" />
    </Flex.Container>
    <Flex.Container flexDirection="column" gap="10px">
      {territories.map((territory) => (
        <Flex.Container key={territory.territory.iso} className="tr" alignItems="center" gap="16px">
          <Flex.Container className="td" flex={2} alignItems="center">
            {territory.mod}
          </Flex.Container>
          <Flex.Item flex={4} className="td">
            {territory.territory.name}
          </Flex.Item>
          <Flex.Item flex={1} className="td">
            <Button
              onClick={() => deleteTerritory(territory)}
              style={{ marginLeft: '55px' }}
              $transparent
            >
              <Icon.BinIcon width={15} />
            </Button>
          </Flex.Item>
        </Flex.Container>
      ))}
    </Flex.Container>
  </Flex.Container>
);

export default TerritoriesTableComponent;
