import { useState } from 'react';
import { translate } from '@/app/i18n';
import { Button, Flex, Text, FormGroup, Icon } from '@/app/ui';
import Colors from '@/app/styles/Colors';

const ResetPasswordPage = () => {
  const [email, setEmail] = useState<string>('');
  const [resetPassword, setResetPassword] = useState<boolean>(false);

  return (
    <Flex.Container
      flexDirection="column"
      alignItems="center"
      style={{ backgroundColor: Colors.white, marginTop: '15%' }}
    >
      {!resetPassword && (
        <Flex.Container flexDirection="column" alignItems="center">
          <Flex.Item flex={1} className="mb-3">
            <Text.Title2 textAlign="center" style={{ fontStyle: 'italic' }}>
              {translate('RESET_PASSWORD.DID_YOU_FORGOT_PASSWORD')}
            </Text.Title2>
            <Text.Paragraph>
              {translate('RESET_PASSWORD.ENTER_YOUR_EMAIL_INSTRUCTIONS')}
            </Text.Paragraph>
          </Flex.Item>
          <Flex.Container style={{ width: '300px' }} flexDirection="column">
            <Flex.Item flex={1}>
              <FormGroup
                className="mb-5"
                type="text"
                id="email"
                path="email"
                name="email"
                label={translate('RESET_PASSWORD.EMAIL')}
                placeholder={translate('RESET_PASSWORD.EMAIL')}
                value={email}
                onChange={(path: string, value: string) => setEmail(value)}
                required
              />
            </Flex.Item>

            <Flex.Item flex={1}>
              <Button $size="md" $full $variant="primary" onClick={() => setResetPassword(true)}>
                <Text.Span>{translate('RESET_PASSWORD.SEND_RESET_EMAIL')}</Text.Span>
              </Button>
            </Flex.Item>
          </Flex.Container>
        </Flex.Container>
      )}
      {resetPassword && (
        <Flex.Container flexDirection="column" alignItems="center">
          <Icon.SpinnerCheckIcon width={120} />
          <Text.Title2>{translate('RESET_PASSWORD.EMAIL_SENT')}</Text.Title2>
          <Text.Paragraph>
            {translate('RESET_PASSWORD.EMAIL_SENT_WITH_INSTRUCTIONS_1')}
          </Text.Paragraph>
          <Text.Paragraph className="pt-0">
            {translate('RESET_PASSWORD.EMAIL_SENT_WITH_INSTRUCTIONS_2')}
          </Text.Paragraph>
        </Flex.Container>
      )}
    </Flex.Container>
  );
};
export default ResetPasswordPage;
