import { translate } from '@/app/i18n';
import { Flex, Text } from '@/app/ui/atoms';
import DashboardHelpCard from '@/app/ui/organisms/DashboardHelp/DashboardHelpCard';

const DashboardHelp = () => (
  <Flex.Container flexDirection="column" gap="3rem">
    <Text.SectionTitle>{translate('NAVBAR.HELP')}</Text.SectionTitle>
    <Flex.Container style={{ width: '35%' }}>
      <Text.LabelMedium>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas tempus neque, amet ac
        vestibulum vitae justo integer interdum. Go to our Help page.
      </Text.LabelMedium>
    </Flex.Container>
    <Flex.Container gap="2rem">
      <DashboardHelpCard
        title="Lorem ipsum dolor sit amet, consectetur."
        text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tellus non, ligula duis
            vestibulum adipiscing scelerisque ante mauris."
      />
      <DashboardHelpCard
        title="Lorem ipsum dolor sit amet, consectetur."
        text="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
      />
      <DashboardHelpCard
        title="Lorem ipsum dolor"
        text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tellus non, ligula duis
        vestibulum adipiscing scelerisque ante mauris. Consectetur."
      />
    </Flex.Container>
  </Flex.Container>
);

export default DashboardHelp;
