import { NewPasswordI } from '@solo/app-core';
import { Modal, Flex, Text, Button, ChangePasswordForm } from '@/app/ui';
import { translate } from '@/app/i18n';

interface ChangePasswordModalProps {
  isOpen: boolean;
  onChangePassword: (passwordForm: NewPasswordI) => void;
  cancel: () => void;
  save: () => void;
}

const ChangePasswordModal = ({
  isOpen,
  onChangePassword,
  cancel,
  save,
}: ChangePasswordModalProps) => (
  <Modal isOpen={isOpen} position="center" size="sm">
    <Flex.Container flexDirection="column" style={{ padding: '2rem 3rem' }}>
      <Flex.Container flexDirection="column" className="mb-4">
        <Text.PageTitle>{translate('PROFILE_INFO.CHANGE_PASSWORD')}</Text.PageTitle>
      </Flex.Container>

      <ChangePasswordForm onChange={onChangePassword} />

      <Flex.Container justifyContent="flex-end">
        <Button $size="lg" $transparent $variant="primary" onClick={cancel}>
          <Text.Span fontWeight={700} style={{ width: '80px' }}>
            {translate('PROFILE_INFO.CANCEL')}
          </Text.Span>
        </Button>
        <Button $size="lg" $variant="primary" onClick={save}>
          <Text.Span fontWeight={700} style={{ width: '80px' }}>
            {translate('PROFILE_INFO.SAVE')}
          </Text.Span>
        </Button>
      </Flex.Container>
    </Flex.Container>
  </Modal>
);

export default ChangePasswordModal;
