const translations = {
  FORMS: {
    EMAIL: 'Email',
    PASSWORD: 'Password',
    NAME: 'Name',
    SURNAME: 'Surname',
    REPEAT_PASSWORD: 'Repeat password',
    NEW_PASSWORD: 'New password',
    REPEAT_NEW_PASSWORD: 'Repeat new password',
    BIRTHDAY: 'Date of Birth',
    ID_NUMBER: 'ID Number',
    PHONE_NUMBER: 'Phone number',
    ADDRESS: 'Address',
    CITY: 'City',
    POSTAL_CODE: 'Postal Code',
    PROVINCE_STATE: 'Province / State',
    COUNTRY: 'Country',
    ACCEPT_CONDITIONS: 'I accept the conditions lorem ipsum',
  },
  LOGIN: {
    WELCOME_BACK: 'Welcome Back',
    FORGOT_PASSWORD: 'Forgot password?',
    LOG_IN: 'Log In',
    DO_NOT_HAVE_ACCOUNT_YET: 'Don`t have an account yet?',
    SIGN_UP: 'Sign Up',
    ABOUT_US: 'About us',
    TERMS_CONDITIONS: 'Terms and conditions',
    PRIVACY_POLICY: 'Privacy Policy',
    SIGN_IN_GOOGLE: 'Sign in with Google',
  },
  SIGN_UP: {
    CHOOSE_THE_BEST_SUBSCRIPTION: 'Choose the best subscription',
    ALL_RIGHTS: 'All Rights',
    PUBLISHING_RIGHTS: 'Publishing Rights',
    STEP_1: 'Step 1',
    STEP_2: 'Step 2',
    DIGITAL_DISTRIBUTION: 'Digital distribution',
    SELECT: 'Select',
    ACCOUNT_INFORMATION_AND_PAYMENT: 'Account information and payment',
    ACCOUNT_INFORMATION: 'Account information',
    ORDER_SUMMARY: 'Order summary',
    PAYMENT: 'Payment',
    SUBMIT_PAYMENT: 'Submit payment',
    PAYMENT_INFORMATION: 'Payment information',
    SUBTOTAL: 'Subtotal',
    TOTAL: 'Total',
    SIGN_UP_GOOGLE: 'Sign up with Google',
    SIGNED_UP_GOOGLE: "You're signed up with Google",
    SIGN_UP_EMAIL: 'Sign up with email',
    ALREADY_ACCOUNT: 'You already have an account?',
    IDCARD: 'ID Card',
    DOCUMENTS: 'Documents',
    SELECTED_ITEM: 'Selected item',
    CARD_NUMBER: 'Card number',
    CARD_HOLDER: 'Card holder name',
    EXP_DATE: 'Expiration date',
    SEC_CODE: 'Security code',
    SUB_COMPLETED: 'You completed the Subsription successfully!',
    SUB_ITEM: 'Subscription item',
    SIGN_UP_COMPLETED: 'You completed the signup successfully!',
    CONTRACT_SENT: 'We have sent the contract to the email address provided.',
    CHECK_EMAIL_AND_LOGIN:
      'Please check your email and log in with the registered user.',
    ALICE_IDENTIFY: 'Identity validation with your ID card and face',
    ALICE_COMPLETED: 'You completed the identification successfully!',
    CORRECT_FRONT_ID: 'Correct Front side ID',
    CORRECT_BACK_ID: 'Correct Back side ID',
    CORRECT_SELFIE: 'Correct Selfie',
    PLEASE_COMPLETE_SIGNUP: 'Please, complete your sign up.',
    COMPLETE_SIGNUP: 'Complete sign up',
  },
  BUTTON: {
    SAVE_CHANGES: 'Save changes',
    ADD: 'Add',
    ADD_NEW_TRACK: 'Add new track',
    ADD_SONG: 'Add Song',
    ADD_SINGLE: 'Add new Single',
    ADD_EP: 'Add new EP',
    LEARN_MORE: 'Learn more',
    ADD_AUTHOR_COMPOSER: 'Add Author / Composer',
    ADD_ALBUM: 'Add new Album',
    NEW_SINGLE: 'New Single',
    NEW_EP: 'New EP',
    NEW_ALBUM: 'New Album',
    SAVE_ADD: 'Save and Add',
    SAVE_ADD_TRACK: 'Save and Add new track',
  },
  NAVBAR: {
    DASHBOARD: 'Dashboard',
    MY_SONGS: 'My Songs',
    MUSIC_USAGE: 'Music Usage',
    EARNINGS: 'Earnings',
    HELP: 'Help',
    ROYALTIES: 'Royalties',
    STATEMENTS: 'Statements',
  },
  RESET_PASSWORD: {
    DID_YOU_FORGOT_PASSWORD: 'Did you forgot your password?',
    ENTER_YOUR_EMAIL_INSTRUCTIONS:
      'Please enter your email below to receive the instructions to reset your password.',
    EMAIL: 'Email',
    WRITE_EMAIL: 'Write email',
    SEND_RESET_EMAIL: 'Send password reset email',
    EMAIL_SENT: 'Email Sent',
    EMAIL_SENT_WITH_INSTRUCTIONS_1:
      'We have sent an email to the email address provided.',
    EMAIL_SENT_WITH_INSTRUCTIONS_2:
      'Please check your email and follow the instructions.',
    GO_TO_LOGIN: 'Go to Log in',
    NEW_PASSWORD: 'New password',
    ENTER_NEW_PASSWORD: 'Please enter your new password to change it.',
    YOUR_PASSWORD_HAS_BEEN_CHANGED: 'Your password has been changed!',
    YOU_CAN_LOGIN: 'You can login with your new password.',
  },
  ACCOUNT: {
    PROFILE_INFO: 'Profile Information',
    SUBSCRIPTION: 'Subscription',
    FINANCIAL_DATA: 'Financial data',
    LOG_OUT: 'Logout',
  },
  PAGE_TITLE: {
    TOP_EARNING_WORKS: 'Top earning works',
    MUSIC_USAGE_DETAIL: 'Usage details',
    PROFILE: 'Profile',
  },
  PROFILE_INFO: {
    NAME: 'Name',
    EMAIL: 'Email',
    SAVE_CHANGES: 'Save changes',
    PASSWORD: 'Password',
    CHANGE_PASSWORD: 'Change password',
    CANCEL: 'Cancel',
    SAVE: 'Save',
  },
  PROFILE_PAGES: {
    PROFILE: 'Profile',
    PROFILE_INFO: 'Profile Information',
    SUBSCRIPTION: 'Subscription',
    FINANCIAL_DATA: 'Financial data',
  },
  FINANCIAL_DATA: {
    BANK_INFO: 'Bank information',
    BANK_ACCOUNT_TEXT:
      'The back account you provide in this section is very important, as it’s where you will receive the royalties for your work',
  },
  SUBSCRIPTION_INFO: {
    YOUR_NEXT: 'Your next ',
    BILL_DATE: ' bill is due ',
    PAYMENT_METHOD: 'Payment method',
    PAYMENT_METHOD_TEXT:
      'This is the payment method you used when you subscribed',
    EDIT: 'Edit',
    ALL_RIGHTS: 'All Rights',
    DISTRIBUTION: 'Distribution Rights',
    ALL_RIGHTS_DETAIL: '(Publishing & Digital Distribution)',
    DISTRIBUTION_DETAIL: '(Digital Distribution)',
    MANAGE_SUBSCRIPTION: 'Manage subscription',
    SUBSCRIPTION_KNOW_MORE: 'Know more about the subscription type',
    SUBSCRIPTION_RENEWED_ON: 'Your subscription will be renewed on ',
  },
  FINANCIAL_DATA_INFO: {
    USER_TYPE: 'Type of user',
    BANK_INFO: 'Bank information',
    COUNTRY: 'Country',
    CURRENCY: 'Currency',
    BANK_ACCOUNT: 'Banck account',
    SWIFT: 'Swift',
    SELECT: 'Select Here',
    COMPANY: 'Company',
    INDIVIDUAL: 'Individual',
    SELF_EMPLOYED: 'Self employed',
    company: 'company',
    individual: 'individual',
    self_employed: 'self_employed',
  },
  WORK_STATUS: {
    ACCEPTED: 'ACCEPTED',
    CONFLICT: 'CONFLICT',
  },
  WORK_TYPE: {
    SINGLE_MIN: 'Single',
    ALBUM_MIN: 'Album',
    SINGLE: 'SINGLE',
    ALBUM: 'ALBUM',
    EP: 'EP',
  },
  WORKS_PAGES: {
    MY_SONGS: 'My Songs',
    ALBUM_DATA: 'Album data',
    TRACKS: 'Tracks',
    DIGITAL_DISTRIBUTION: 'Digital distribution',
    ALBUM_DETAILS: 'Album details',
    EDIT_DETAILS: 'Edit details',
    EDIT_TRACKS: 'Edit tracks',
    EDIT_DISTRIBUTION: 'Edit digital distribution',
    ADD_ALBUM_TRACKS: 'Add Album tracks',
    ADD_SINGLE_TRACKS: 'Add Single tracks',
    ADD_EP_TRACKS: 'Add EP tracks',
    ADD_EXISTING_TRACK: 'Add existing track',
    TERRITORY_RESTRICTIONS: 'Territory restrictions',
    CHANGE_RESTRICTION: 'Change restriction',
    CHANGE_RESTRICTION_WARNING:
      'The changes will only affect digital distribution rights.',
    ALL_TERRITORIES: 'All territories / countries',
    SPECIFIC_TERRITORIES: 'Specific territories / countries',
    BACK: 'Back',
    BACK_TO_TRACKS: 'Back to tracks',
    TRACK_DETAILS: 'Track Details',
    SEE_MORE: 'See more',
    ARTIST_PERFORMER: 'Artist / Performer',
    AUTHOR_COMPOSERS: 'Author / Composers',
    AUTHOR_COMPOSER: 'Author / Composer',
    ADD_NEW_AUTHOR_COMPOSER: 'Add new Author / Composer',
    AUTHOR_COMPOSER_DATA: 'Author/Composer data',
    CREATE_AUTHOR_COMPOSER: 'Create a new Author / Composer',
    AUTHOR_COMPOSER_RULES: 'Author / Composer rules',
    SEARCH_AUTHOR_COMPOSER: 'Search existing Author / Composer',
    SEARCH_NAME_COMPOSERID: 'Search by name or ComposerID',
    FULLNAME: 'Full Name',
    TOTAL: 'Total',
    SELECT_ALL_STORES: 'Select all the digital stores',
    APPLE_ID: 'Do you have an Apple Music ID?',
    NO_ID: 'No, I need you to assign me one',
    SPOTIFY_ID: 'Do you have an Spotify ID?',
    NEW_TRACK: 'New track',
    TYPES_OF_ROLE: 'Types of role',
    SONG_EDITED: 'This song is edited by a music publishing',
    NEED_ISRC: 'I need you to assign an ISRC',
    HAVE_ISRC: 'I have an ISRC',
    HAVE_LICENSE:
      'Have you obtained a license for this version that specifically authorizes digital distribution?',
    NO_LICENSE:
      'All cover versions require a valid license to be distributed through. SOLO is not responsible if you don’t have a license lorem ipsum dolor secit.',
  },
  WORKS: {
    APPLY_FILTERS: 'Apply filters',
    SEARCH_BY: 'Search by title, Unison ID',
    FILTER: 'Filter ',
    SEARCH: 'Search',
    TYPE: 'Type',
    STATUS: 'Status',
    ISWC: 'ISWC',
    UNISON_ID: 'Unison Id',
    COVER_IMAGE: 'Cover Image',
    ALBUM_TITLE: 'Album title',
    SINGLE_TITLE: 'Single title',
    EP_TITLE: 'EP title',
    ARTIST_NAME: 'Artist name',
    MAIN_ARTIST: 'Main Artist',
    VARIOUS_ARTIST: 'Various artists',
    LANGUAGE: 'Language',
    RECORD_LABEL: 'Record label',
    UPC_EAN: 'UPC / EAN',
    RELEASE_DATE: 'Release date',
    RELEASE_TIME: 'Release time',
    PRIMARY_GENRE: 'Primary genre',
    SECONDARY_GENRE: 'Secondary genre',
    CHOOSE_GENRE: 'Choose a genre',
    PRODUCT_COPYRIGHT: 'Product copyright',
    PRODUCT_COPYRIGHT_YEAR: 'Product copyright year',
    SOUND_RECORDING_COPYRIGHT: 'Sound recording copyright',
    SOUND_RECORDING_COPYRIGHT_YEAR: 'Sound recording copyright year',
    TITLE: 'Title',
    ARTIST: 'Artist',
    RELEASE: 'Release',
    SINGLE: 'Single',
    ALBUM: 'Album',
    SEARCH_BY_TITLE: 'Search by title',
    TASKS: 'Tasks',
    SUMMARY: 'Summary',
    ALBUM_DETAILS: 'Album details',
    EP_DETAILS: 'EP details',
    SINGLE_DETAILS: 'Single details',
    ALBUM_DATA: 'Album data',
    EP_DATA: 'EP data',
    SINGLE_DATA: 'Single data',

    ARTWORK: 'Artwork',
    URL_ARTWORK: 'URL Artwork',
    UPC_INFO:
      "If you already have a UPC for this version, please add. If not, no problem, we'll create one for you.",
    INCLUDE: 'Include',
    EXCLUDE: 'Exclude',
    INCLUDE_EXCLUDE: 'Include / Exclude',
    TERRITORY: 'Territory',
    MODIFICATOR: 'Modificator',
    WORLD: 'World',
    LOADING: 'Loading',
    RELEASE_SUCCESS: 'Musical release successfully created!',
    UPLOAD: 'Upload',
    DROP_FILE: 'or drop file here.',
  },
  TRACKS: {
    TRACK_TITLE: 'Track title',
    ORDER: 'Order',
    ALTERNATIVE_TITLE: 'Alternative title',
    COMPOSITION_TYPE: 'Composition type',
    LANGUAGE_LYRICS: 'Language of the lyrics',
    EXPLICIT_CONTENT: 'Explicit content',
    ISRC: 'ISRC',
    AUDIO: 'Audio',
    AUDIO_URL: 'Audio URL',
    LYRICS: 'Lyrics',
    ARTIST: 'Artist',
    ROLE: 'Role',
    PERF: 'Perf',
    MECH: 'Mech',
    YES: 'Yes',
    NO: 'No',
    ORIGINAL: 'Original',
    ORIGINAL_ARTIST: 'Original song artist',
    ORIGINAL_SONG_DETAILS: 'Original song details',
    ORIGINAL_TITLE: 'Original song title',
    MISSING_ARTIST: 'You need to assign at least one artist.',
    MISSING_AUTHOR: 'You need to assign at least one autor or composer.',
  },
  DIGITAL_DISTRIBUTION: {
    PLATFORM: 'Platform',
    DATE: 'Date',
    STATUS: 'Status',
    DIGITAL_DISTRIBUTION: 'Digital distribution',
  },
  EARNINGS: {
    SEE_ALL: 'See all',
    TOP_WORKS: 'Top earning works',
    PUBLISHING: 'Publishing',
    DIGITAL_DISTRIBUTION: 'Digital distribution',
    TOTAL: 'Total',
    TOP_REVENUE_DSP: 'Top revenue by DSP',
    TOP_REVENUE_COUNTRY: 'Top revenue by country',
    MONTHLY_REVENUE_PLATFORM: 'Monthly revenue by platform',
    ANUAL_REVENUE: 'Anual revenue',
    TOP_EARNING_WORKS: 'Top earning works',
    ROYALTIES_BY_WORK: 'Royalties by work',
    ALL: 'All',
    PERIOD: 'Period',
    AMOUNT: 'Amount',
  },
  USAGES: {
    DATE: 'Date',
    PLAYS: 'Plays',
    PLAYS_LOWER_CASE: 'plays',
    PLAYS_PLATFORM: 'Plays by platform',
    SEE_ALL: 'See all',
    USAGE_DETAILS: 'Usage detail',
    TOP_SONGS_PLATFORM: 'Top Songs by digital platform',
    USAGE_TREND: 'Music usage trend',
  },
  FILTERS: {
    FROM: 'From',
    TO: 'To',
    CLEAR_FILTERS: 'Clear filters',
    CLEAR_FILTER: 'Clear filter',
    CLOSE: 'Close',
    APPLY: 'Set date',
    APPLY_FILTERS: 'Apply filters',
    PERIOD: 'Select period',
    SEE: 'see',
    OF: 'of',
    PAGES: 'pages',
  },
  PAGINATOR: {
    JUMP_TO: 'Jump to page',
    SELECT_PAGE: 'select page',
  },
  ERROR: {
    USER_PASSWORD_NOT_MATCH: 'User or password do not match',
    UNEXPECTED_ERROR: 'Unexpected Error',
    NO_MAIL_REGISTERED: 'This emial is not registered',
  },
  ACTIONS: {
    ACTIONS: 'Actions',
  },
  PAGINATION: {
    PAGE: 'Page',
    PREVIOUS: 'Previous',
    NEXT: 'Next',
    NEXT_STEP: 'Next step',
    JUMP_TO_PAGE: 'Jump to page',
    GO: 'Go',
  },
  TOAST: {
    CHANGES_SAVED: 'Changes saved!',
    CHANGES_ERROR: 'Something went wrong while saving changes!',
    LOAD_EARNINGS_ERROR: 'Something went wrong while loading your earnings!',
    MANDATORY_ERROR: 'All mandatory fields must be filled!',
    ALL_MANDATORY_ERROR: 'All fields are mandatory!',
    PASSWORD_ERROR: 'Passwords do not match!',
    SIGNATURIT_ERROR: 'Something went wrong when connecting to signaturit.',
    LOGIN_ERROR: 'Authentication failed.',
    CREATE_AUTHOR_ERROR: 'An error ocurred when creating the author.',
    LOAD_RECORDING_ERROR: 'Something went wrong while loading the recording!',
    PERF_100_ERROR: "Total performance percentage can't be over 100%!",
    MECH_100_ERROR: "Total mechanichal percentage can't be over 100%!",
    CARD_ERROR: "Couldn't retrieve your card information!",
  },
  DASHBOARD: {
    MY_SONGS_INSIGHT: 'My Songs insights',
    TOTAL_EPS: 'Total EPs',
    TOTAL_SINGLES: 'Total singles',
    TOTAL_ALBUMS: 'Total albums',
    TOTAL_NET_REVENUE: 'Total net revenue',
    PUBLISHING_REVENUE: 'Publishing revenue',
    DISTRIBUTION_REVENUE: 'Distribution revenue',
    VIEW_ALL_SONGS: 'View all songs',
    PLAYS_COUNTRY: 'Countries with more plays',
    TOP_3_EARNING_PLATFORMS: 'Top 3 earning platforms',
  },
  DOWNLOAD: {
    DOWNLOAD: 'Download',
  },
  NOT_FOUND: {
    PAGE_NOT_FOUND: 'Page not found',
    SORRY: 'We are sorry, the page you requested could not be found',
    GO_BACK: 'Please go back to the ',
  },
  SIGNATURIT: {
    SIGN_DOC: 'Sign the document with electronic signature',
    START: 'Start',
    SIGN: 'Sign',
  },
};

export default translations;
