// import { useState } from 'react';
import { useEffect, useState } from 'react';
import {
  CreateMusicalReleaseProps,
  MusicalRelease,
  MusicalReleaseType,
  UpdateMusicalReleaseProps,
} from '@solo/app-core';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import {
  Flex,
  Text,
  Button,
  CheckboxWithLabel,
  Icon,
  ArtistsNameTableComponent,
  InputTextAutocomplete,
  InputDateWithLabel,
  TimePickerWithLabel,
  FileUploadComponent,
} from '@/app/ui';
import { translate } from '@/app/i18n';
import InputWithLabel from '@/app/ui/molecules/InputWithLabel';

const languages = ['Spanish', 'English', 'French'];
const genres = ['Pop', 'Rock', 'Rap', 'R&B'];

export interface MusicalReleaseDetailsFromProps {
  releaseType?: MusicalReleaseType;
  musicalRelease?: MusicalRelease;
  detailsFieldsMissing?: boolean;
  closeModal?: () => void;
  onChange?: (musicalRelease: CreateMusicalReleaseProps | UpdateMusicalReleaseProps) => void;
  saveDetails?: (musicalRelease: CreateMusicalReleaseProps | UpdateMusicalReleaseProps) => void;
  setNewArtwork: (artwork: File) => void;
}

const MusicalReleaseDetailsForm = (props: MusicalReleaseDetailsFromProps) => {
  const {
    releaseType,
    musicalRelease,
    detailsFieldsMissing,
    closeModal,
    onChange,
    setNewArtwork,
    saveDetails,
  } = props;

  const [variousArtists, setVariousArtists] = useState<boolean>(
    musicalRelease?.otherArtists || false,
  );

  const [musicalReleaseDetails, setMusicalReleaseDetails] = useState<UpdateMusicalReleaseProps>({
    ...musicalRelease,
    type: releaseType,
  });

  const [newArtist, setNewArtist] = useState<string | undefined>();
  const [fieldsMissing, setFieldsMissing] = useState<boolean>(detailsFieldsMissing || false);

  const [artwork, setArtowork] = useState<File | undefined>();

  let type;
  let title;

  const years = ['2022', '2021', '2020'];

  switch (musicalRelease?.type || releaseType) {
    case 'ALBUM':
      type = translate('WORKS_PAGES.ALBUM_DETAILS');
      title = translate('WORKS.ALBUM_TITLE');
      break;
    case 'SINGLE':
      type = translate('WORKS.SINGLE_DETAILS');
      title = translate('WORKS.SINGLE_TITLE');
      break;
    default:
      type = translate('WORKS.EP_DETAILS');
      title = translate('WORKS.EP_TITLE');
  }

  function deleteArtist(artist: string) {
    const artists = musicalReleaseDetails.mainArtists || [];
    artists.filter((currentArtist) => currentArtist.name !== artist);
    setMusicalReleaseDetails({ ...musicalReleaseDetails, mainArtists: artists });
  }

  function addNewArtist(artist: string | undefined) {
    if (!artist) return;
    const artists = musicalReleaseDetails.mainArtists || [];
    artists.push({ name: artist });
    setMusicalReleaseDetails({ ...musicalReleaseDetails, mainArtists: artists });
    setNewArtist('');
  }

  function checkMandatoryFields() {
    return (
      musicalReleaseDetails.title !== '' &&
      musicalReleaseDetails.releaseDate &&
      musicalReleaseDetails.releaseTime !== '' &&
      musicalReleaseDetails.language !== '' &&
      musicalReleaseDetails.primaryGenre !== ''
    );
  }

  function validateDetailsForm(): CreateMusicalReleaseProps | UpdateMusicalReleaseProps {
    if (checkMandatoryFields()) {
      const detailsData = {
        ...musicalReleaseDetails,
        releaseTime: musicalReleaseDetails.releaseTime,
        otherArtists: variousArtists,
        productCopyrightYear: musicalReleaseDetails.productCopyrightYear || parseInt(years[0], 10),
        soundCopyrightYear: musicalReleaseDetails.soundCopyrightYear || parseInt(years[0], 10),
      };
      return detailsData;
    }
    throw new Error('All mandatory fields must be filled!');
  }

  async function saveChanges() {
    if (!saveDetails) return;
    try {
      const detailsInfo = await validateDetailsForm();
      saveDetails(detailsInfo);
      if (closeModal) closeModal();
    } catch (error) {
      if (error instanceof Error) toast.error(error.message);
      setFieldsMissing(true);
    }
  }

  useEffect(() => {
    if (onChange) onChange(musicalReleaseDetails);
  }, [musicalReleaseDetails]);

  useEffect(() => {
    if (artwork) setNewArtwork(artwork);
  }, [artwork]);

  useEffect(() => {
    if (detailsFieldsMissing) setFieldsMissing(detailsFieldsMissing);
  }, [detailsFieldsMissing]);

  return (
    <Flex.Container flexDirection="column" gap="4rem" style={{ width: '100%' }}>
      <Flex.Container gap="25px" flexDirection="column">
        <Text.SubtitleBold>{type}</Text.SubtitleBold>
        <InputWithLabel
          placeholder={title}
          label={`${title}*`}
          value={musicalReleaseDetails.title}
          onChange={(t) => setMusicalReleaseDetails({ ...musicalReleaseDetails, title: t })}
          mandatoryError={fieldsMissing && !musicalReleaseDetails.title}
        />
        {!variousArtists && (
          <Flex.Container flexDirection="column" gap="25px">
            <Flex.Container gap="16px" style={{ width: '100%' }}>
              <Flex.Container flex={1}>
                <InputWithLabel
                  placeholder={translate('WORKS.MAIN_ARTIST')}
                  label={translate('WORKS.MAIN_ARTIST')}
                  onChange={(text) => setNewArtist(text)}
                  value={newArtist}
                />
              </Flex.Container>
              <Flex.Container flex={1}>
                <Button
                  $size="md"
                  $variant="primary"
                  style={{ marginTop: '24px', height: '40px', width: '170px' }}
                  onClick={() => addNewArtist(newArtist)}
                >
                  <Text.SectionSubtitle style={{ padding: 0 }} fontWeight={600}>
                    {translate('BUTTON.ADD')}
                  </Text.SectionSubtitle>
                </Button>
              </Flex.Container>
            </Flex.Container>
            <Flex.Container>
              {musicalReleaseDetails.mainArtists &&
                musicalReleaseDetails.mainArtists.length > 0 && (
                  <ArtistsNameTableComponent
                    artists={musicalReleaseDetails.mainArtists.map((artist) => artist.name)}
                    deleteArtist={(artist: string) => deleteArtist(artist)}
                  />
                )}
            </Flex.Container>
          </Flex.Container>
        )}

        <Flex.Container alignItems="center" gap="5px">
          <CheckboxWithLabel
            checked={musicalReleaseDetails.otherArtists}
            onChange={() => setVariousArtists(!variousArtists)}
            label={`${translate('WORKS.VARIOUS_ARTIST')}?`}
            tooltip
          />
        </Flex.Container>
        <Flex.Container gap="16px" style={{ width: '100%' }}>
          <Flex.Container flex={1}>
            <InputDateWithLabel
              defaultValue={musicalReleaseDetails.releaseDate}
              label={translate('WORKS.RELEASE_DATE')}
              onChange={(releaseDate) =>
                setMusicalReleaseDetails({ ...musicalReleaseDetails, releaseDate })
              }
              mandatoryError={fieldsMissing && !musicalReleaseDetails.releaseDate}
            />
          </Flex.Container>
          <Flex.Container flex={1}>
            <TimePickerWithLabel
              defaultValue={
                musicalReleaseDetails.releaseTime
                  ? dayjs(
                      `01/01/2023 ${musicalReleaseDetails.releaseTime}:00`,
                      'DD-MM-YYYY HH:mm:ss',
                    )
                  : undefined
              }
              label={translate('WORKS.RELEASE_TIME')}
              onChange={(time) =>
                setMusicalReleaseDetails({ ...musicalReleaseDetails, releaseTime: time })
              }
              mandatoryError={fieldsMissing && !musicalReleaseDetails.releaseDate}
            />
          </Flex.Container>
        </Flex.Container>
        <Flex.Container gap="16px" style={{ width: '100%' }}>
          <Flex.Container flex={1}>
            <InputTextAutocomplete
              defaultOption={
                musicalReleaseDetails?.language
                  ? {
                      value: musicalReleaseDetails.language,
                      label: musicalReleaseDetails.language,
                    }
                  : undefined
              }
              label={translate('WORKS.LANGUAGE')}
              tooltip
              items={Array.from(languages, (language) => ({ value: language, label: language }))}
              onChange={(language) =>
                setMusicalReleaseDetails({ ...musicalReleaseDetails, language })
              }
              mandatoryError={fieldsMissing && !musicalReleaseDetails.language}
            />
          </Flex.Container>
          <Flex.Container flex={1}>
            <InputWithLabel
              placeholder={translate('WORKS.RECORD_LABEL')}
              label={translate('WORKS.RECORD_LABEL')}
              value={musicalReleaseDetails.recordLabel}
              onChange={(recordLabel) =>
                setMusicalReleaseDetails({ ...musicalReleaseDetails, recordLabel })
              }
            />
          </Flex.Container>
        </Flex.Container>
        <Flex.Container gap="16px" style={{ width: '100%' }}>
          <Flex.Container flex={1}>
            <InputTextAutocomplete
              label={translate('WORKS.PRIMARY_GENRE')}
              placeholder={translate('WORKS.CHOOSE_GENRE')}
              defaultOption={
                musicalReleaseDetails?.primaryGenre
                  ? {
                      value: musicalReleaseDetails.primaryGenre,
                      label: musicalReleaseDetails.primaryGenre,
                    }
                  : undefined
              }
              tooltip
              items={Array.from(genres, (genre) => ({ value: genre, label: genre }))}
              onChange={(primaryGenre) =>
                setMusicalReleaseDetails({ ...musicalReleaseDetails, primaryGenre })
              }
              mandatoryError={fieldsMissing && !musicalReleaseDetails.primaryGenre}
            />
          </Flex.Container>
          <Flex.Container flex={1}>
            <InputTextAutocomplete
              label={translate('WORKS.SECONDARY_GENRE')}
              placeholder={translate('WORKS.CHOOSE_GENRE')}
              defaultOption={
                musicalReleaseDetails?.secondaryGenre
                  ? {
                      value: musicalReleaseDetails.secondaryGenre,
                      label: musicalReleaseDetails.secondaryGenre,
                    }
                  : undefined
              }
              tooltip
              items={Array.from(genres, (genre) => ({ value: genre, label: genre }))}
              onChange={(secondaryGenre) =>
                setMusicalReleaseDetails({
                  ...musicalReleaseDetails,
                  secondaryGenre,
                })
              }
            />
          </Flex.Container>
        </Flex.Container>
      </Flex.Container>
      <Flex.Container gap="25px" flexDirection="column">
        <Text.SubtitleBold>{translate('WORKS.ARTWORK')}</Text.SubtitleBold>
        <FileUploadComponent type="image" onChange={(artworkFile) => setArtowork(artworkFile)} />
      </Flex.Container>
      <Flex.Container flexDirection="column" gap="15px">
        <Flex.Container alignItems="center" gap="1rem">
          <Text.SubtitleBold>UPC / EAN</Text.SubtitleBold>
          <Icon.InfoIcon />
        </Flex.Container>
        <Text.LabelMedium>{translate('WORKS.UPC_INFO')}</Text.LabelMedium>
        <Flex.Container style={{ width: '50%' }}>
          <InputWithLabel
            type="numeric"
            placeholder="UPC / EAN"
            label="UPC / EAN"
            tooltip
            onChange={(upc_ean) => setMusicalReleaseDetails({ ...musicalReleaseDetails, upc_ean })}
            value={musicalReleaseDetails.upc_ean}
          />
        </Flex.Container>
      </Flex.Container>
      <Flex.Container gap="25px" flexDirection="column">
        <Text.SubtitleBold>Copyright</Text.SubtitleBold>
        <Flex.Container gap="16px" style={{ width: '100%' }}>
          <Flex.Container flex={1}>
            <InputWithLabel
              label={translate('WORKS.PRODUCT_COPYRIGHT')}
              placeholder={translate('WORKS.PRODUCT_COPYRIGHT')}
              tooltip
              value={musicalReleaseDetails.productCopyright}
              onChange={(productCopyright) =>
                setMusicalReleaseDetails({ ...musicalReleaseDetails, productCopyright })
              }
            />
          </Flex.Container>
          <Flex.Container style={{ width: '50%' }}>
            <InputTextAutocomplete
              placeholder={musicalReleaseDetails.productCopyrightYear?.toString()}
              label={translate('WORKS.PRODUCT_COPYRIGHT_YEAR')}
              defaultOption={
                musicalReleaseDetails.productCopyrightYear
                  ? {
                      value: musicalReleaseDetails.productCopyrightYear.toString(),
                      label: musicalReleaseDetails.productCopyrightYear.toString(),
                    }
                  : {
                      value: '2022',
                      label: '2022',
                    }
              }
              tooltip
              items={Array.from(years, (year) => ({
                value: year,
                label: year,
              }))}
              onChange={(productCopyrightYear) =>
                setMusicalReleaseDetails({
                  ...musicalReleaseDetails,
                  productCopyrightYear: parseInt(productCopyrightYear, 10),
                })
              }
            />
          </Flex.Container>
        </Flex.Container>
      </Flex.Container>
      <Flex.Container gap="16px" style={{ width: '100%' }}>
        <Flex.Container flex={1}>
          <InputWithLabel
            value={musicalReleaseDetails.soundCopyright}
            label={translate('WORKS.SOUND_RECORDING_COPYRIGHT')}
            placeholder={translate('WORKS.SOUND_RECORDING_COPYRIGHT')}
            tooltip
            onChange={(soundCopyright) =>
              setMusicalReleaseDetails({ ...musicalReleaseDetails, soundCopyright })
            }
          />
        </Flex.Container>
        <Flex.Container style={{ width: '50%' }}>
          <InputTextAutocomplete
            defaultOption={
              musicalReleaseDetails.soundCopyrightYear
                ? {
                    value: musicalReleaseDetails.soundCopyrightYear.toString(),
                    label: musicalReleaseDetails.soundCopyrightYear.toString(),
                  }
                : {
                    value: '2022',
                    label: '2022',
                  }
            }
            label={translate('WORKS.SOUND_RECORDING_COPYRIGHT_YEAR')}
            tooltip
            items={Array.from(years, (year) => ({ value: year, label: year }))}
            onChange={(soundCopyrightYear) =>
              setMusicalReleaseDetails({
                ...musicalReleaseDetails,
                soundCopyrightYear: parseInt(soundCopyrightYear, 10),
              })
            }
          />
        </Flex.Container>
      </Flex.Container>
      {closeModal && musicalRelease && (
        <Flex.Container style={{ marginLeft: 'auto' }} gap="20px">
          <Button
            $transparent
            $variant="primary"
            onClick={() => {
              closeModal();
            }}
          >
            <Text.SectionSubtitle>{translate('PROFILE_INFO.CANCEL')}</Text.SectionSubtitle>
          </Button>

          <Button
            $size="md"
            $variant="primary"
            style={{ width: '290px' }}
            onClick={() => {
              saveChanges();
            }}
          >
            <Text.SectionSubtitle style={{ padding: 0 }} fontWeight={600}>
              {translate('PROFILE_INFO.SAVE')}
            </Text.SectionSubtitle>
          </Button>
        </Flex.Container>
      )}
    </Flex.Container>
  );
};

export default MusicalReleaseDetailsForm;
