import { useEffect, useState } from 'react';
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { gapi } from 'gapi-script';
import { Cradle, User } from '@solo/app-core';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { translate } from '@/app/i18n';
import { Button, Card, Flex, FormGroup, Icon, RouterLink, Text } from '@/app/ui';
import loginImg from '@/app/assets/images/login-img.png';
import Colors from '@/app/styles/Colors';
import { useCradle } from '@/app/contexts';
import { useConfig } from '@/app/hooks';

interface LoginI {
  email: string;
  password: string;
}

const LoginPage = () => {
  const [login, setLogin] = useState<LoginI>({ email: '', password: '' });
  const navigate = useNavigate();
  const { authBloc } = useCradle<Cradle>();
  const config = useConfig();
  const clientId = config.googleClientId;

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId,
        scope: '',
      });
    };
    gapi.load('client:auth2', initClient);
  }, []);

  const handleOnChangeInputText = (path: string, value: any) => {
    setLogin({
      ...login,
      [path]: value,
    });
  };

  async function checkUserValidations(user: User) {
    if (!user.isUserValidated()) {
      navigate('/');
      return;
    }
    navigate('/');
  }

  const onSuccess = async (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if ('profileObj' in res) {
      try {
        const authResponse = await authBloc.loginWithGoogle(
          res.profileObj.email,
          res.profileObj.googleId,
        );
        checkUserValidations(authResponse.user);
      } catch (error) {
        toast.error(translate('TOAST.LOGIN_ERROR'));
      }
    }
  };

  const loginWithEmail = async () => {
    try {
      const authResponse = await authBloc.login(login.email, login.password);
      checkUserValidations(authResponse.user);
    } catch (error) {
      toast.error(translate('TOAST.LOGIN_ERROR'));
    }
  };

  return (
    <Flex.Container
      alignItems="center"
      justifyContent="center"
      alignContent="center"
      style={{
        backgroundImage: `url(${loginImg})`,
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        zIndex: -1,
        height: '100vh',
      }}
    >
      <Card.Container
        backgroundColor={Colors.blackWithOpacity}
        className="p-5"
        style={{ width: '90%', maxWidth: '550px' }}
      >
        <Card.Body>
          <Flex.Container flexDirection="column" alignItems="center">
            <Icon.LogoBlackAndWhite width={150} className="mb-4" />
            <Flex.Container flexDirection="column" className="mt-5 mb-3" style={{ width: '100%' }}>
              <Flex.Item flex={1}>
                <Flex.Container justifyContent="center">
                  <GoogleLogin
                    clientId={clientId}
                    onSuccess={(res) => onSuccess(res)}
                    cookiePolicy="single_host_origin"
                    render={(renderProps) => (
                      <Button
                        $backgroundColor={Colors.white}
                        $backgroundColorHover={Colors.white}
                        style={{
                          display: 'inline-block',
                          padding: '1rem 1rem 1rem 1rem',
                          width: '100%',
                          border: `1px solid ${Colors.borderInput}`,
                        }}
                        onClick={renderProps.onClick}
                      >
                        <Flex.Container alignItems="center" justifyContent="center">
                          <Icon.GoogleIcon />
                          <Text.SectionSubtitleBold style={{ opacity: 0.54, marginLeft: '15px' }}>
                            {translate('LOGIN.SIGN_IN_GOOGLE')}
                          </Text.SectionSubtitleBold>
                        </Flex.Container>
                      </Button>
                    )}
                  />
                </Flex.Container>
              </Flex.Item>
              <Flex.Container justifyContent="center" className="mt-5">
                <Flex.Container gap="10px" alignItems="center" style={{ width: '70%' }}>
                  <Flex.Item
                    flex={10}
                    style={{ borderBottom: `1px solid ${Colors.borderInput}` }}
                  />
                  <Flex.Item flex={1}>or</Flex.Item>
                  <Flex.Item
                    flex={10}
                    style={{ borderBottom: `1px solid ${Colors.borderInput}` }}
                  />
                </Flex.Container>
              </Flex.Container>
              <form noValidate autoComplete="off">
                <Flex.Item flex={1}>
                  <FormGroup
                    className="mb-5"
                    type="text"
                    id="email"
                    path="email"
                    name="email"
                    label={translate('FORMS.EMAIL')}
                    placeholder={translate('FORMS.EMAIL')}
                    value={login.email}
                    onChange={handleOnChangeInputText}
                    required
                  />
                </Flex.Item>
                <Flex.Item flex={3}>
                  <FormGroup
                    type="password"
                    id="password"
                    path="password"
                    name="password"
                    label={translate('FORMS.PASSWORD')}
                    placeholder={translate('FORMS.PASSWORD')}
                    value={login.password}
                    onChange={handleOnChangeInputText}
                    required
                  />
                </Flex.Item>
                <Flex.Item flex={1}>
                  <RouterLink $transparent to="/auth/reset-password">
                    <Text.Paragraph fontSize={14} style={{ color: Colors.purple }}>
                      {translate('LOGIN.FORGOT_PASSWORD')}
                    </Text.Paragraph>
                  </RouterLink>
                </Flex.Item>
              </form>

              <Flex.Item flex={1}>
                <Button
                  $size="md"
                  className="mt-5"
                  $full
                  $variant="primary"
                  onClick={loginWithEmail}
                >
                  <Text.Span>{translate('LOGIN.LOG_IN')}</Text.Span>
                </Button>
              </Flex.Item>
            </Flex.Container>
            <Flex.Container className="mt-5" gap="1rem">
              <Text.Paragraph color={Colors.borderInput} fontSize={14}>
                {translate('LOGIN.DO_NOT_HAVE_ACCOUNT_YET')}
              </Text.Paragraph>
              <RouterLink $transparent to="/auth/sign-up">
                <Text.Paragraph fontSize={14} style={{ color: Colors.purple }}>
                  {translate('LOGIN.SIGN_UP')}
                </Text.Paragraph>
              </RouterLink>
            </Flex.Container>
          </Flex.Container>
        </Card.Body>
      </Card.Container>
    </Flex.Container>
  );
};
export default LoginPage;
