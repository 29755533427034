import { useState } from 'react';
import { NewPasswordI } from '@solo/app-core';
import { translate } from '@/app/i18n';
import { Flex, FormGroup } from '@/app/ui';

interface ChangePasswordFormPops {
  onChange: (passwordForm: NewPasswordI) => void;
}

const ChangePasswordForm = ({ onChange }: ChangePasswordFormPops) => {
  const [newPassword, setNewPassword] = useState<NewPasswordI>({
    password: '',
    repeatPassword: '',
  });

  const handleOnChangeInputText = (path: string, value: any) => {
    setNewPassword({
      ...newPassword,
      [path]: value,
    });

    onChange({
      ...newPassword,
      [path]: value,
    });
  };

  return (
    <form noValidate>
      <Flex.Container flexDirection="column">
        <Flex.Item flex={1}>
          <FormGroup
            className="mb-5"
            type="password"
            id="password"
            path="password"
            name="password"
            label={translate('FORMS.NEW_PASSWORD')}
            placeholder={translate('FORMS.NEW_PASSWORD')}
            value={newPassword.password}
            onChange={handleOnChangeInputText}
            required
          />
        </Flex.Item>
        <Flex.Item flex={1}>
          <FormGroup
            className="mb-5"
            type="password"
            id="repeatPassword"
            path="repeatPassword"
            name="repeatPassword"
            label={translate('FORMS.REPEAT_NEW_PASSWORD')}
            placeholder={translate('FORMS.REPEAT_NEW_PASSWORD')}
            value={newPassword.repeatPassword}
            onChange={handleOnChangeInputText}
            required
          />
        </Flex.Item>
      </Flex.Container>
    </form>
  );
};
export default ChangePasswordForm;
