import Colors from '@/app/styles/Colors';
import { Flex } from '@/app/ui/atoms';

interface ProgressBarProps {
  progress: number;
}

const ProgressBar = (props: ProgressBarProps) => {
  const { progress } = props;

  return (
    <Flex.Container
      style={{
        height: '7px',
        width: '100%',
        backgroundColor: Colors.borderInput,
        borderRadius: 50,
      }}
    >
      <Flex.Container
        style={{
          backgroundColor: Colors.primary,
          height: '100%',
          width: `${progress}%`,
          borderRadius: 'inherit',
        }}
      />
    </Flex.Container>
  );
};

export default ProgressBar;
