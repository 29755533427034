import styled from 'styled-components/macro';
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Block, Flex, FormInput, FormSelect, Text } from '@/app/ui/atoms';
import { OptionsPropsI } from '@/app/ui/atoms/FormSelect';
import Colors from '@/app/styles/Colors';

export interface FormGroupProps {
  path: string;
  name: string;
  // TODO type type
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  id: string;
  onChange?: CallableFunction;
  label?: string;
  className?: string;
  inputClassName?: string;
  placeholder?: string;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  checked?: boolean;
  options?: Array<OptionsPropsI>;
}

const FormGroup = (props: FormGroupProps): JSX.Element => {
  const {
    path,
    name,
    type,
    value,
    id,
    onChange,
    label,
    className,
    inputClassName,
    placeholder,
    required,
    readOnly,
    disabled,
    checked,
    options,
  } = props;

  // TODO type type select...etc
  if (type === 'select') {
    return (
      <div className={`form-group FormGroup ${className} m-0`}>
        {label ? (
          <Text.LabelSmall htmlFor={name} className="form-group label mb-1">
            {label}
          </Text.LabelSmall>
        ) : null}
        <FormSelect
          placeholder={placeholder || ''}
          // type={type}
          id={id}
          path={path}
          // name={name}
          value={value}
          // checked={checked}
          onChange={onChange}
          options={options}
          disabled={disabled}
        />
      </div>
    );
  }

  return (
    <div className={`form-group FormGroup ${className} mb-2`}>
      {label ? (
        <Text.LabelSmall
          htmlFor={name}
          className="form-group label mb-1"
          style={{ color: Colors.white }}
        >
          {label}
        </Text.LabelSmall>
      ) : null}
      <FormInput
        type={type}
        id={id}
        path={path}
        name={name}
        placeholder={placeholder}
        value={value}
        readOnly={readOnly}
        className={inputClassName}
        required={required}
        onChange={onChange}
      />
    </div>
  );
};

export default styled(FormGroup)`
  display: grid;
  .label {
    font-size: 14px;
  }
`;

// export default styled(MechaCardList)`
//   display: grid;
//   grid-gap: 2rem;
//   gap: 2rem;
//   grid-template-columns: repeat(auto-fill, minmax(250px, 0.8fr));
//   justify-content: center;
//   /* height: max(20vh, min(800px, 80vh)); */
//   height: min(80vh, max(20vh, 750px));
//   overflow: auto;
// `;
