import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';

import { container } from '@solo/app-core';
import { ContainerProvider } from '@/app/contexts';
import Navigator from '@/app/pages';

import './styles/index.scss';
import 'react-toastify/dist/ReactToastify.css';

const App = () => (
  <BrowserRouter>
    <ContainerProvider container={container}>
      <Navigator />

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </ContainerProvider>
  </BrowserRouter>
  // <React.StrictMode>
  // </React.StrictMode>
);

export default App;
