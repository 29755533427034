import { forwardRef, useEffect, useState } from 'react';
import { Flex, Input, Text } from '@/app/ui/atoms';
import { Icon } from '@/app/ui/molecules';
import Colors from '@/app/styles/Colors';

interface InputWithLabelProps {
  label: string;
  placeholder?: string;
  tooltip?: boolean;
  defaultValue?: string;
  type?: 'text' | 'numeric' | 'password';
  mandatoryError?: boolean;
  value?: string;
  disabled?: boolean;
  labelColor?: string;
  onChange?: (value: string) => void;
}

const InputWithLabel = forwardRef<HTMLInputElement, InputWithLabelProps>(
  (props: InputWithLabelProps, ref) => {
    const {
      label,
      placeholder,
      tooltip,
      defaultValue,
      type,
      mandatoryError,
      value,
      disabled,
      labelColor,
      onChange,
    } = props;

    const [currentValue, setCurrentValue] = useState<string>(value || '');
    const inputType = type || 'text';

    const handleOnChange = (text: string) => {
      if (!onChange || !(onChange instanceof Function)) return;
      setCurrentValue(text);
      onChange(text);
    };

    useEffect(() => {
      setCurrentValue(value || '');
    }, [value]);

    return (
      <Flex.Container flexDirection="column" style={{ width: '100%' }}>
        <Flex.Container gap="0.5rem" alignItems="center">
          <Text.LabelSmall
            htmlFor="title_input"
            style={{ color: mandatoryError ? Colors.red : labelColor }}
          >
            {label}
          </Text.LabelSmall>

          {tooltip && <Icon.InfoIcon />}
        </Flex.Container>
        {
          {
            numeric: (
              <Input.Numeric
                readOnly={disabled}
                style={{
                  border: mandatoryError ? `1px solid ${Colors.red}` : '',
                  backgroundColor: disabled ? Colors.borderInput : Colors.white,
                }}
                ref={ref}
                placeholder={placeholder}
                defaultValue={defaultValue}
                onChange={(e) => handleOnChange(e.target.value)}
                value={currentValue}
              />
            ),
            text: (
              <Input.Text
                readOnly={disabled}
                style={{
                  border: mandatoryError ? `1px solid ${Colors.red}` : '',
                  backgroundColor: disabled ? Colors.borderInput : Colors.white,
                }}
                ref={ref}
                placeholder={placeholder}
                defaultValue={defaultValue}
                onChange={(e) => handleOnChange(e.target.value)}
                value={currentValue}
              />
            ),
            password: (
              <Input.Password
                readOnly={disabled}
                style={{
                  border: mandatoryError ? `1px solid ${Colors.red}` : '',
                  backgroundColor: disabled ? Colors.borderInput : Colors.white,
                }}
                ref={ref}
                placeholder={placeholder}
                defaultValue={defaultValue}
                onChange={(e) => handleOnChange(e.target.value)}
                value={currentValue}
              />
            ),
          }[inputType]
        }
      </Flex.Container>
    );
  },
);

export default InputWithLabel;
