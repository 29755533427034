import styled from 'styled-components/macro';
import Flex from '@/app/ui/atoms/Flex';

interface TableProps {
  readonly gap?: string;
  readonly flex?: number;
  readonly padding?: string;
}

const Container = styled(Flex.Container)<TableProps>`
  flex-direction: 'column';
  gap: ${(props) => props.gap || '1rem'};
`;

const Tbody = styled(Flex.Container)<TableProps>`
  gap: ${(props) => props.gap || '1rem'};
`;

const Tr = styled(Flex.Container)<TableProps>`
  gap: ${(props) => props.gap || '1rem'};
`;

const Thead = styled(Flex.Container)<TableProps>`
  gap: ${(props) => props.gap || '1rem'};
  flex: ${(props) => props.flex || 1};
`;

const Th = styled(Flex.Item)<TableProps>`
  gap: ${(props) => props.gap || '1rem'};
  flex: ${(props) => props.flex || 1};
  padding: ${(props) => props.padding || 0};
`;

const Td = styled(Flex.Item)<TableProps>`
  gap: ${(props) => props.gap || '1rem'};
  flex: ${(props) => props.flex || 1};
  padding: ${(props) => props.padding || 0};
`;

const Table = { Container, Thead, Tr, Tbody, Td, Th };
export default Table;
