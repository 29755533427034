import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { gapi } from 'gapi-script';
import { useEffect } from 'react';
import { CreateUserProps } from '@solo/app-core';
import { translate } from '@/app/i18n';
import Colors from '@/app/styles/Colors';
import { Button, Card, Flex, Icon, RouterLink, Text } from '@/app/ui';
import { useConfig } from '@/app/hooks';

interface SignUpComponentProps {
  nextStep: (step: number) => void;
  saveUserInfo: (userInfo: CreateUserProps) => void;
}

const SignUpComponent = (props: SignUpComponentProps) => {
  const { nextStep, saveUserInfo } = props;
  const config = useConfig();
  const clientId = config.googleClientId;

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId,
        scope: '',
      });
    };
    gapi.load('client:auth2', initClient);
  }, []);

  const onSuccess = (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if ('profileObj' in res) {
      saveUserInfo({ name: res.profileObj.name, email: res.profileObj.email });
      nextStep(2);
    }
  };
  return (
    <Card.Container
      backgroundColor={Colors.blackWithOpacity}
      className="p-5"
      style={{ width: '90%', maxWidth: '550px', marginTop: '3rem' }}
    >
      <Card.Body>
        <Flex.Container flexDirection="column" alignItems="center">
          <Flex.Container flexDirection="column" gap="3rem" alignItems="center">
            <Text.Title3
              style={{ fontSize: 32, fontStyle: 'italic', fontFamily: 'Arial' }}
              color={Colors.white}
            >
              Welcome to
            </Text.Title3>
            <Icon.LogoBlackAndWhite width={220} className="mb-4" />
          </Flex.Container>
          <Flex.Container
            flexDirection="column"
            className="mt-5 mb-3"
            gap="2rem"
            alignItems="center"
            style={{ width: '100%' }}
          >
            <Flex.Item flex={1} style={{ width: '100%' }}>
              <Flex.Container justifyContent="center">
                <GoogleLogin
                  clientId={clientId}
                  onSuccess={(res) => onSuccess(res)}
                  cookiePolicy="single_host_origin"
                  render={(renderProps) => (
                    <Button
                      $backgroundColor={Colors.white}
                      $backgroundColorHover={Colors.white}
                      style={{
                        display: 'inline-block',
                        padding: '1rem 1rem 1rem 1rem',
                        width: '80%',
                        border: `1px solid ${Colors.borderInput}`,
                      }}
                      onClick={renderProps.onClick}
                    >
                      <Flex.Container alignItems="center" justifyContent="center">
                        <Icon.GoogleIcon />
                        <Text.SectionSubtitleBold style={{ opacity: 0.54, marginLeft: '15px' }}>
                          {translate('SIGN_UP.SIGN_UP_GOOGLE')}
                        </Text.SectionSubtitleBold>
                      </Flex.Container>
                    </Button>
                  )}
                />
              </Flex.Container>
            </Flex.Item>
            <Flex.Container justifyContent="center" className="mt-5" style={{ width: '100%' }}>
              <Flex.Container gap="10px" alignItems="center" style={{ width: '90%' }}>
                <Flex.Item flex={10} style={{ borderBottom: `1px solid ${Colors.borderInput}` }} />
                <Flex.Item flex={1}>or</Flex.Item>
                <Flex.Item flex={10} style={{ borderBottom: `1px solid ${Colors.borderInput}` }} />
              </Flex.Container>
            </Flex.Container>

            <Flex.Item flex={1} style={{ width: '80%' }}>
              <Button
                $size="md"
                className="mt-5"
                $full
                $variant="primary"
                onClick={() => nextStep(1)}
              >
                <Text.Span>{translate('SIGN_UP.SIGN_UP_EMAIL')}</Text.Span>
              </Button>
            </Flex.Item>
          </Flex.Container>
          <Flex.Container className="mt-5" gap="1rem">
            <Text.Paragraph color={Colors.borderInput} fontSize={14}>
              {translate('SIGN_UP.ALREADY_ACCOUNT')}
            </Text.Paragraph>
            <RouterLink $transparent to="/auth/login">
              <Text.Paragraph fontSize={14} style={{ color: Colors.purple }}>
                {translate('LOGIN.LOG_IN')}
              </Text.Paragraph>
            </RouterLink>
          </Flex.Container>
        </Flex.Container>
      </Card.Body>
    </Card.Container>
  );
};
export default SignUpComponent;
