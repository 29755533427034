import {
  MusicalRelease,
  MusicalReleaseType,
  MusicalReleasesFilters,
  PaginatedEntity,
} from '@solo/app-core';
import styled from 'styled-components';
import { useState } from 'react';
import { Flex, Text, Section, Input, Img, RouterLink, ElementsPerPageSelector } from '@/app/ui';
import { translate } from '@/app/i18n';
import Colors from '@/app/styles/Colors';
import loginImg from '@/app/assets/images/login-img.png';
import WorksFilterComponent from '@/app/ui/organisms/Works/WorksListComponent/WorksFilterComponent/WorksFilterComponent';
import Pagination from '@/app/ui/organisms/Pagination/Pagination';
import { convertArrayToString } from '@/app/utils/ArrayToString';

interface MusicalReleaseListComponentPorps {
  musicalReleases: PaginatedEntity<MusicalRelease>;
  className?: string;
  filter: (filters: MusicalReleasesFilters) => void;
  jumpToPage: (page: number) => void;
  selectElementsPerPage: (nElements: number) => void;
}
const pages = [5, 10, 20, 50];
const MusicalReleasesListComponent = ({
  musicalReleases,
  className,
  filter,
  jumpToPage,
  selectElementsPerPage,
}: MusicalReleaseListComponentPorps) => {
  const [selectedMusicalReleasesIds, setSelectedMusicalReleasesIds] = useState<string[]>([]);

  function handleCheckboxClick(musicalReleaseId?: string) {
    let currentSelectedMusicalRelesesIds = [...selectedMusicalReleasesIds];
    if (!musicalReleaseId) {
      if (currentSelectedMusicalRelesesIds.length < musicalReleases.data.length) {
        currentSelectedMusicalRelesesIds = musicalReleases.data.map((mr) => mr.id);
      } else {
        currentSelectedMusicalRelesesIds = [];
      }
    } else if (currentSelectedMusicalRelesesIds.includes(musicalReleaseId)) {
      const elementIndex = currentSelectedMusicalRelesesIds.indexOf(musicalReleaseId);
      currentSelectedMusicalRelesesIds.splice(elementIndex, 1);
    } else {
      currentSelectedMusicalRelesesIds.push(musicalReleaseId);
    }
    setSelectedMusicalReleasesIds(currentSelectedMusicalRelesesIds);
  }

  return (
    <>
      <WorksFilterComponent filter={filter} />
      <ElementsPerPageSelector
        position="right"
        totalPages={musicalReleases.pagination.totalElements}
        options={pages}
        defaultElementsPerPage={pages[0]}
        onChange={(p) => selectElementsPerPage(p)}
      />
      <Section className={className}>
        <Flex.Container flex={1} className="table" flexDirection="column">
          <Flex.Container flex={1} className="thead">
            <Flex.Container flex={3} className="th ml-2" alignItems="center">
              <Input.Checkbox
                style={{ marginRight: 15 }}
                onChange={() => handleCheckboxClick()}
                checked={selectedMusicalReleasesIds.length === musicalReleases.data.length}
              />
              <Text.Paragraph fontWeight={600} style={{ padding: 0 }}>
                {translate('WORKS.TITLE')}
              </Text.Paragraph>
            </Flex.Container>
            <Flex.Item flex={3} className="th">
              {translate('WORKS.ARTIST')}
            </Flex.Item>
            <Flex.Item flex={2} className="th">
              {translate('WORKS.TYPE')}
            </Flex.Item>
            {/* <Flex.Item flex={2} className="th">
            {translate('WORKS.STATUS')}
          </Flex.Item> */}
            <Flex.Item flex={2} className="th">
              {translate('WORKS.RELEASE')}
            </Flex.Item>
          </Flex.Container>
          <Flex.Container className="tbody" flexDirection="column">
            {musicalReleases.data.map((musicalRelease) => (
              <Flex.Container key={musicalRelease.id} className="tr" alignItems="center">
                <Flex.Container className="td ml-2" flex={3} alignItems="center">
                  <Input.Checkbox
                    style={{ marginRight: 15 }}
                    onChange={() => handleCheckboxClick(musicalRelease.id)}
                    checked={selectedMusicalReleasesIds.includes(musicalRelease.id)}
                  />
                  <Img src={loginImg} height={40} width="auto" style={{ marginRight: 15 }} />
                  <RouterLink $transparent $variant="primary" to={`${musicalRelease.id}`}>
                    <Text.Paragraph fontWeight={600} color={Colors.primary}>
                      {musicalRelease.title}
                    </Text.Paragraph>
                  </RouterLink>
                </Flex.Container>
                <Flex.Item flex={3} className="td">
                  {convertArrayToString(
                    musicalRelease.mainArtists?.map((artist) => artist.name) || [],
                  )}
                </Flex.Item>
                <Flex.Item flex={2} className="td">
                  <Text.Paragraph
                    className="box"
                    style={{
                      backgroundColor:
                        musicalRelease.type === MusicalReleaseType.ALBUM
                          ? Colors.grey
                          : Colors.primary,
                    }}
                  >
                    {
                      {
                        ALBUM: translate('WORKS.ALBUM'),
                        EP: 'EP',
                        SINGLE: translate('WORKS.SINGLE'),
                      }[musicalRelease.type]
                    }
                  </Text.Paragraph>
                </Flex.Item>
                {/* <Flex.Item flex={2} className="td">
                {musicalRelease.status}
              </Flex.Item> */}
                <Flex.Item flex={2} className="td">
                  Pending
                </Flex.Item>
              </Flex.Container>
            ))}
          </Flex.Container>
        </Flex.Container>
      </Section>
      <Pagination
        totalElements={musicalReleases.pagination.totalElements}
        pagination={musicalReleases.pagination}
        jumpToPage={jumpToPage}
        maxDisplayedPages={8}
      />
    </>
  );
};

// export default WorksListComponent;
export default styled(MusicalReleasesListComponent)`
  .box {
    padding: 2px 0px;
    width: fit-content;
    color: ${Colors.white};
    width: 80px;
    text-align: center;
  }
`;
