import { translate } from '@/app/i18n';
import Colors from '@/app/styles/Colors';
import { Flex, Text, Icon, RouterLink } from '@/app/ui';

const SignUpCompletedComponent = () => (
  <Flex.Container flexDirection="column" gap="4rem" justifyContent="center" alignContent="center">
    <Icon.SpinnerCheckIcon height={90} />
    <Flex.Container flexDirection="column" gap="2rem">
      <Text.BiggerBold style={{ color: Colors.white }}>
        {translate('SIGN_UP.SIGN_UP_COMPLETED')}
      </Text.BiggerBold>
      <Flex.Container flexDirection="column" gap="1rem" alignItems="center">
        <Text.LabelMedium style={{ color: Colors.white }}>
          {translate('SIGN_UP.CONTRACT_SENT')}
        </Text.LabelMedium>
        <Text.LabelMedium style={{ color: Colors.white }}>
          {translate('SIGN_UP.CHECK_EMAIL_AND_LOGIN')}
        </Text.LabelMedium>
      </Flex.Container>
    </Flex.Container>

    <RouterLink $size="md" to="/auth/login" style={{ marginTop: '3rem' }}>
      <Text.LabelSmallBold fontSize={14} style={{ color: Colors.white }}>
        {translate('LOGIN.LOG_IN')}
      </Text.LabelSmallBold>
    </RouterLink>
  </Flex.Container>
);
export default SignUpCompletedComponent;
