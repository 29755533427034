import { translate } from '@/app/i18n';
import { Button, Flex, Text } from '@/app/ui/atoms';
import { Icon } from '@/app/ui/molecules';

interface AuthorsNameTableComponentPorps {
  authors: Array<string>;
  deleteAuthor: (author: string) => void;
}

const AuthorsNameTableComponent = ({ authors, deleteAuthor }: AuthorsNameTableComponentPorps) => (
  <Flex.Container className="table" flexDirection="column">
    <Flex.Container gap="16px">
      <Flex.Item flex={4} className="th">
        <Text.LabelSmall>{translate('WORKS_PAGES.AUTHOR_COMPOSER')}</Text.LabelSmall>
      </Flex.Item>
      <Flex.Item flex={6} className="th" />
    </Flex.Container>
    <Flex.Container flexDirection="column" gap="10px">
      {authors.map((author) => (
        <Flex.Container key={author} className="tr" alignItems="center" gap="16px">
          <Flex.Container className="td" flex={4} alignItems="center">
            {author}
          </Flex.Container>
          <Flex.Item flex={6} className="td">
            <Button
              onClick={() => deleteAuthor(author)}
              style={{ marginLeft: '55px' }}
              $transparent
            >
              <Icon.BinIcon width={15} />
            </Button>
          </Flex.Item>
        </Flex.Container>
      ))}
    </Flex.Container>
  </Flex.Container>
);

export default AuthorsNameTableComponent;
