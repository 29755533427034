import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Flex, Input, Text } from '@/app/ui/atoms';
import { Icon } from '@/app/ui';
import { ClearInputHandle } from '@/app/ui/organisms/Royalties/EarningsWorks';

interface InputSearchProps {
  label?: string;
  placeholder?: string;
  clearInput?: boolean;
  onClear?: () => void;
  onChange?: (text: string) => void;
}

const InputWithLabel = forwardRef(
  (props: InputSearchProps, ref: React.ForwardedRef<ClearInputHandle>) => {
    const { label, placeholder, clearInput, onClear, onChange } = props;
    const [searchText, setSearchText] = useState<string>('');

    useImperativeHandle(ref, () => ({
      clearInput() {
        setSearchText('');
      },
    }));

    const handleOnChange = (text: string) => {
      setSearchText(text);
      if (onChange === undefined || typeof onChange !== 'function') return;

      onChange(text);
    };

    return (
      <Flex.Container flexDirection="column" style={{ width: '100%' }}>
        <Flex.Container gap="0.5rem" alignItems="center">
          <Text.LabelSmall htmlFor="title_input" style={{ marginBottom: '2px' }}>
            {label}
          </Text.LabelSmall>
        </Flex.Container>

        <Flex.Container>
          <Input.Text
            onChange={(e) => handleOnChange(e.target.value)}
            id="title_input"
            placeholder={placeholder}
            value={searchText}
          />
          {clearInput && (
            <Button
              $transparent
              style={{ marginLeft: '-30px', padding: '1rem' }}
              // eslint-disable-next-line no-console
              onClick={() => onClear && onClear()}
            >
              <Icon.ClearCross />
            </Button>
          )}
        </Flex.Container>
      </Flex.Container>
    );
  },
);

export default InputWithLabel;
