import I18n from 'i18n-js';

import en from '@/app/assets/i18n/en';

export const LANGUAGES = ['en'];

export const translate = (text: string): string =>
  // I18n.translate(text, { defaultValue: text });
  I18n.translate(text);

I18n.translations = {
  en,
};

// When a value is missing from a language it'll fallback to another language with the key present.
I18n.fallbacks = true;
I18n.defaultLocale = 'en-EN';

export default I18n;
