import React, { createElement } from 'react';
import { LinkProps } from 'react-router-dom';
import { Link as LinkAtom, RouterLink as RouterLinkAtom } from '@/app/ui/atoms';
import Icon from './Icons';

interface ArrowSizeProps {
  // eslint-disable-next-line react/require-default-props
  readonly arrowPosition?: 'left' | 'right';
}

export const LinkArrow = (
  props: LinkProps & React.RefAttributes<HTMLAnchorElement> & ArrowSizeProps,
) => {
  const { children, arrowPosition = 'right' } = props;

  return (
    <>
      {arrowPosition === 'left' && <Icon.ArrowLeft size="lg" style={{ marginRight: '0.5rem' }} />}
      {createElement(LinkAtom, { ...props, $transparent: true, $underlinehover: true }, children)}
      {arrowPosition === 'right' && <Icon.ArrowRight size="lg" style={{ marginLeft: '0.5rem' }} />}
    </>
  );
};

export const RouterLinkArrow = (
  props: LinkProps & React.RefAttributes<HTMLAnchorElement> & ArrowSizeProps,
) => {
  const { children, arrowPosition = 'right' } = props;

  return (
    <>
      {arrowPosition === 'left' && <Icon.ArrowLeft size="lg" style={{ marginRight: '1rem' }} />}
      {createElement(
        RouterLinkAtom,
        { ...props, $transparent: true, $underlinehover: true },
        children,
      )}
      {arrowPosition === 'right' && <Icon.ArrowRight size="lg" style={{ marginLeft: '0.5rem' }} />}
    </>
  );
};
