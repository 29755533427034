import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';
import { Coordinates } from '@solo/app-core/dist/modules/territory/domain/Coordinates';
import { Flex } from '@/app/ui/atoms';
import Colors from '@/app/styles/Colors';
// eslint-disable-next-line import/extensions
import geoData from './map.json';
import { normalize } from '@/app/utils/Normalize';

export interface QuantityPerCountry {
  territoryTis: number;
  quantity: number;
}

export interface QuantityPerCountryWithCoordinates {
  countryData: QuantityPerCountry;
  coordinates: Coordinates;
}

interface WorldMapWithMarkersProps {
  markers: QuantityPerCountryWithCoordinates[];
}

const WorldMapWithMarkers = (props: WorldMapWithMarkersProps) => {
  const { markers } = props;

  return (
    <Flex.Container>
      <ComposableMap>
        <Geographies geography={geoData}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography
                fill={Colors.disabledButton}
                stroke={Colors.white}
                key={geo.rsmKey}
                geography={geo}
              />
            ))
          }
        </Geographies>
        {markers.map(({ countryData, coordinates }) => (
          <Marker
            key={countryData.territoryTis}
            coordinates={[coordinates.longitude, coordinates.latitude]}
          >
            <circle
              r={normalize(
                countryData.quantity,
                markers[markers.length - 1].countryData.quantity,
                markers[0].countryData.quantity,
                [10, 20],
              )}
              cx={0}
              cy={0}
              fill={`rgba(141, 0, 255, ${
                1.2 -
                normalize(
                  countryData.quantity,
                  markers[markers.length - 1].countryData.quantity,
                  markers[0].countryData.quantity,
                  [0, 1],
                )
              } )`}
              strokeWidth={2}
            />
            <text textAnchor="middle" style={{ fontFamily: 'system-ui', fill: '#5D5A6D' }} />
          </Marker>
        ))}
      </ComposableMap>
    </Flex.Container>
  );
};

export default WorldMapWithMarkers;
