import { createRef, useEffect, useState } from 'react';
import { Cradle, DigitalPlatformId, PaginatedEntity, Pagination, Usage } from '@solo/app-core';
import { Button, Flex, Text } from '@/app/ui/atoms';
import { useCradle } from '@/app/contexts';
import UsageDetailsTable from '@/app/ui/organisms/Usages/UsageDetailsList';
import { translate } from '@/app/i18n';
import Colors from '@/app/styles/Colors';
import {
  InputDateWithLabel,
  InputTextAutocomplete,
  Pagination as PaginationComponent,
} from '@/app/ui';
import { useBlocState } from '@/app/hooks';
import DefaultPagination from '@/app/constants/Pagination';

const AllUsageDetails = () => {
  const [usagesData, setUsagesData] = useState<PaginatedEntity<Usage>>();
  const { digitalDistributionBloc } = useCradle<Cradle>();
  const digitalDistributionState = useBlocState(digitalDistributionBloc);
  const [currentGroupFilter, setCurrentGroupFilter] = useState<
    DigitalPlatformId | 'All' | undefined
  >('All');

  const { consumptionReportsBloc } = useCradle<Cradle>();

  const fromDateRef = createRef<HTMLInputElement>();
  const toDateRef = createRef<HTMLInputElement>();

  const digitalPlatforms =
    digitalDistributionState.kind === 'LoadedDigitalDistributionState'
      ? digitalDistributionState.platforms
      : [];

  async function getUsages(pagination: Pagination) {
    const usagesFiltered = await consumptionReportsBloc.getUsages(
      {
        digitalPlatformId: currentGroupFilter !== 'All' ? currentGroupFilter : undefined,
        dateFrom: fromDateRef.current?.valueAsDate || undefined,
        dateTo: toDateRef.current?.valueAsDate || undefined,
      },
      pagination,
    );
    usagesFiltered.data = usagesFiltered.data.slice(0, 50);
    setUsagesData(usagesFiltered);
  }

  const jumpToPage = async (page: number) => {
    const pag = { limit: DefaultPagination.limit, offset: DefaultPagination.limit * (page - 1) };
    getUsages(pag);
  };

  useEffect(() => {
    getUsages({ limit: DefaultPagination.limit });
  }, [currentGroupFilter]);

  return (
    <Flex.Container padding="3rem" flexDirection="column">
      <Flex.Container>
        <Text.PageTitle color={Colors.purple}>{translate('NAVBAR.MUSIC_USAGE')} </Text.PageTitle>
        <Text.Title3>&nbsp;/ {translate('PAGE_TITLE.MUSIC_USAGE_DETAIL')}</Text.Title3>
      </Flex.Container>
      <Flex.Container flexDirection="column" className="mt-5" gap="5rem">
        <Flex.Container style={{ width: '49%', height: '40px' }} gap="1rem" alignContent="center">
          <Flex.Item flex={2}>
            <InputTextAutocomplete
              placeholder="Platform..."
              items={[{ value: 'All', label: 'All' }].concat(
                digitalPlatforms.map((p) => ({ value: p.id, label: p.name })),
              )}
              onChange={(groupFilter) => setCurrentGroupFilter(groupFilter)}
            />
          </Flex.Item>
          <Flex.Item flex={1}>
            <InputDateWithLabel ref={fromDateRef} />
          </Flex.Item>
          <Flex.Item flex={1}>
            <InputDateWithLabel ref={toDateRef} />
          </Flex.Item>
          <Flex.Item flex={1} style={{ paddingTop: '2px' }}>
            <Button
              $size="md"
              $variant="primary"
              style={{ width: '100%', height: '100%' }}
              onClick={() => getUsages({ limit: DefaultPagination.limit })}
            >
              <Text.Paragraph style={{ padding: 0 }} fontWeight={600}>
                {translate('WORKS.SEARCH')}
              </Text.Paragraph>
            </Button>
          </Flex.Item>
        </Flex.Container>
        {usagesData !== undefined && (
          <>
            <UsageDetailsTable usages={usagesData.data} />
            <PaginationComponent
              totalElements={usagesData.pagination.totalElements}
              pagination={usagesData.pagination}
              jumpToPage={jumpToPage}
              maxDisplayedPages={8}
            />
          </>
        )}
      </Flex.Container>
    </Flex.Container>
  );
};

export default AllUsageDetails;
