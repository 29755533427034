import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Pagination as PaginationI } from '@solo/app-core';
import { Button, Flex, Section, Text } from '@/app/ui/atoms';
import Colors from '@/app/styles/Colors';
import { FormGroup, Icon } from '@/app/ui/molecules';
import { translate } from '@/app/i18n';
import { range } from '@/app/utils/Range';

interface PaginationProps {
  jumpToPage: (page: number) => void;
  className?: string;
  totalElements: number;
  pagination: PaginationI;
  maxDisplayedPages: number;
}

const Pagination = ({
  jumpToPage,
  className,
  totalElements,
  pagination,
  maxDisplayedPages,
}: PaginationProps) => {
  const { offset, limit } = pagination;

  const [page, setPage] = useState<number>(offset ? Math.ceil(totalElements / offset) : 1);
  const [pageToJump, setPageToJump] = useState<number | ''>(page);
  const [pages, setPages] = useState<number[]>([]);
  const [showPagination, setShowPagination] = useState<boolean>(false);
  const [currentDisplayedPages, setCurrentDisplayedPages] = useState<number[]>([]);

  useEffect(() => {
    const p = limit ? Math.ceil(totalElements / limit) : 0;
    const arrayP = Array.from(Array(p).keys()).map((value: number) => value + 1);
    setPages(arrayP);
    setCurrentDisplayedPages(arrayP.slice(0, maxDisplayedPages));
    setShowPagination(arrayP.length > 1);
  }, [totalElements, limit]);

  useEffect(() => {
    const nPagesBySide = Math.floor(maxDisplayedPages / 2);
    let previousPages: number[] = [];
    let nextPages: number[] = [];
    let nNextPagesExtra: number = 0;
    let nPreviousPagesExtra: number = 0;

    if (page <= nPagesBySide) {
      previousPages = range(1, page);
      nNextPagesExtra = nPagesBySide - previousPages.length;
    } else {
      const nPreviousPages = page - nPagesBySide;
      previousPages = range(nPreviousPages, page);
    }

    if (page >= pages.length - nPagesBySide) {
      nextPages = range(page, pages.length + 1);
      if (previousPages.length > nPagesBySide) {
        nPreviousPagesExtra = nPagesBySide - nextPages.length + 1;
      }
    } else {
      const nNextPages = page + nPagesBySide + nNextPagesExtra + 1;
      nextPages = range(page, nNextPages);
    }

    const previousPagesExtra = range(previousPages[0] - nPreviousPagesExtra, previousPages[0]);
    previousPages = previousPagesExtra.concat(previousPages);

    const displayedPages = previousPages.concat(nextPages);

    setCurrentDisplayedPages(displayedPages);
  }, [page, pages]);

  const changePage = (p: number) => {
    setPageToJump(p || '');
    setPage(p);
    jumpToPage(p);
  };

  const onGo = () => {
    if (!pageToJump) {
      return;
    }
    setPage(pageToJump);
    jumpToPage(pageToJump);
  };

  const nextPage = () => {
    const next = page + 1;
    if (next < pages.length + 1) {
      setPage(next);
      jumpToPage(next);
    }
  };

  const previousPage = () => {
    const previous = page - 1;
    if (previous >= 1) {
      setPage(previous);
      jumpToPage(previous);
    }
  };

  if (!showPagination) {
    return null;
  }

  return (
    <Section style={{ margin: 30 }} className={className}>
      <Flex.Container flex={1} justifyContent="center">
        <Flex.Container alignItems="center">
          <Flex.Item className="mr-3">
            <Button
              $transparent
              $variant={page === 1 ? 'grey' : 'primary'}
              onClick={() => previousPage()}
            >
              <Text.Paragraph fontWeight={600} color={Colors.primary} className="p-0">
                {translate('PAGINATION.PREVIOUS')}
              </Text.Paragraph>
            </Button>
          </Flex.Item>
          {page > maxDisplayedPages / 2 + 1 && (
            <Button
              $transparent
              $variant="primary"
              onClick={() => changePage(1)}
              style={{ width: '40px' }}
            >
              <Text.Paragraph fontWeight={600} color={Colors.primary} className="p-0 mr-2">
                1...
              </Text.Paragraph>
            </Button>
          )}
          {currentDisplayedPages.map((p: number) => (
            <Button
              key={p}
              $transparent
              $variant={page === p ? 'primary' : 'grey'}
              onClick={() => changePage(p)}
              style={{ width: '40px' }}
            >
              <Text.Paragraph
                fontWeight={page === p ? 600 : 500}
                color={page === p ? Colors.primary : Colors.greyLight}
                style={{ fontStyle: page === p ? 'italic' : '' }}
                className="p-0 mr-2"
              >
                {p}
              </Text.Paragraph>
            </Button>
          ))}
          {page < pages.length - maxDisplayedPages / 2 && (
            <Button
              $transparent
              $variant="primary"
              onClick={() => changePage(pages.length)}
              style={{ width: '60px' }}
            >
              <Text.Paragraph fontWeight={600} color={Colors.primary} className="p-0 mr-2">
                ...{pages.length}
              </Text.Paragraph>
            </Button>
          )}
          <Flex.Item className="ml-3 mr-3">
            <Button
              $transparent
              $variant={pages.length === page ? 'grey' : 'primary'}
              onClick={() => nextPage()}
            >
              <Text.Paragraph fontWeight={600} color={Colors.primary} className="p-0">
                {translate('PAGINATION.NEXT')}
              </Text.Paragraph>
            </Button>
          </Flex.Item>
          <Flex.Item className="ml-3 mr-2">
            <Text.Paragraph color={Colors.greyLight} className="p-0">
              {translate('PAGINATION.JUMP_TO_PAGE')}
            </Text.Paragraph>
          </Flex.Item>
          <Flex.Item>
            <FormGroup
              className="jump-to-page"
              inputClassName="small-input"
              type="text"
              id="jumpToPage"
              path="jumpToPage"
              name="jumpToPage"
              placeholder={translate('PAGINATION.PAGE')}
              value={pageToJump || ''}
              onChange={(path: string, value: string) => setPageToJump(parseInt(value, 10))}
              required
            />
          </Flex.Item>
          <Flex.Item className="ml-2 mr-1">
            <Button $transparent $variant="primary" onClick={() => onGo()}>
              <Text.Paragraph fontWeight={600} color={Colors.primary} className="p-0">
                {translate('PAGINATION.GO')}
              </Text.Paragraph>
              <Icon.ArrowRight width={10} />
            </Button>
          </Flex.Item>
        </Flex.Container>
      </Flex.Container>
    </Section>
  );
};

export default styled(Pagination)`
  .jump-to-page {
    margin-bottom: 0px !important;
    .small-input {
      width: 60px;
    }
  }
`;
