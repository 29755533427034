import { useState } from 'react';
import { Button, Flex } from '@/app/ui';

interface ButtonsNavBarProps {
  tabs: string[];
  onSelectTab: (tab: string) => void;
  defaultTab?: string;
}

const TopRevenueDSP = (props: ButtonsNavBarProps) => {
  const { tabs, onSelectTab, defaultTab } = props;
  const [activeTab, setActiveTab] = useState<string>(defaultTab || '');

  function changeTab(tab: string) {
    setActiveTab(tab);
    onSelectTab(tab);
  }

  return (
    <Flex.Container gap="1rem">
      {tabs.map((tab) => (
        <Button
          key={tab}
          $transparent
          $size="md"
          $activeButton={activeTab === tab}
          onClick={() => changeTab(tab)}
        >
          {tab}
        </Button>
      ))}
    </Flex.Container>
  );
};

export default TopRevenueDSP;
