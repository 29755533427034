import Colors from '@/app/styles/Colors';
import { Flex, Input, Text } from '@/app/ui/atoms';
import { Icon } from '@/app/ui/molecules';

interface CheckboxProps {
  label: string;
  tooltip?: boolean;
  checked?: boolean | undefined;
  value?: string;
  labelColor?: string;
  mandatoryError?: boolean;
  onChange?: (value?: string) => void;
}

const CheckboxWithLabel = (props: CheckboxProps) => {
  const { label, tooltip, checked, value, labelColor, mandatoryError, onChange } = props;

  const handleOnChange = (v: string | undefined) => {
    if (onChange === undefined || typeof onChange !== 'function') return;
    onChange(v);
  };

  return (
    <Flex.Container alignItems="center" gap="0.5rem" onClick={() => handleOnChange(value)}>
      <Input.Checkbox
        checked={checked}
        onChange={() => handleOnChange(value)}
        id="checkbox"
        width="100%"
        style={{ marginRight: '15px' }}
      />
      <Text.LabelMedium
        htmlFor="checkbox"
        style={{ color: mandatoryError ? Colors.red : labelColor, marginBottom: '2px' }}
      >
        {label}
      </Text.LabelMedium>
      {tooltip && <Icon.InfoIcon />}
    </Flex.Container>
  );
};

export default CheckboxWithLabel;
