import styled, { css } from 'styled-components/macro';
import Colors from '@/app/styles/Colors';

interface ContainerProps {
  readonly borderStyle?: 'solid' | 'dashed' | 'dotted';
  readonly borderSize?: number;
  readonly borderColor?: string;
  readonly backgroundColor?: string;
}

const commonCSS = css`
  padding: 1.5rem;
`;

const Container = styled.div<ContainerProps>`
  color: ${Colors.white};
  border: ${(props) => `${props.borderSize || 0}px`} ${(props) => props.borderStyle || ''}
    ${(props) => props.borderColor || ''};
  background: ${(props) => props.backgroundColor || Colors.notSoBlack};
  height: fit-content;
`;

const Header = styled.div`
  ${commonCSS}
  border-bottom: 1px solid ${Colors.secondary};
`;

const Body = styled.div`
  ${commonCSS}
`;

const Footer = styled.div`
  ${commonCSS}
  border-top: 1px solid ${Colors.secondary};
`;

const Card = { Container, Body, Header, Footer };

export default Card;
