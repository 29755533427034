import styled, { css } from 'styled-components/macro';
import Colors from '@/app/styles/Colors';
import { Button, Flex, Text } from '@/app/ui/atoms';

type ModalSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

interface ModalProps {
  readonly children: React.ReactNode;
  readonly isOpen: boolean;
  readonly title?: string;
  readonly closeable?: boolean;
  readonly backdropCloseable?: boolean;
  readonly onClose?: () => void;
  readonly className?: string;
  readonly size?: ModalSize;
}

interface ModalContentProps {
  readonly size?: ModalSize;
}

const mediasCSS = css`
  @media (min-width: 1500px) {
    max-width: 1500px;
  }

  /* @media (min-width: 1800px) {
    max-width: 1800px;
  } */
`;

const ModalContent = styled.div<ModalContentProps>`
  position: relative;
  color: ${Colors.black};
  padding: 2rem;
  width: 50%;
  height: fit-content;
  background: ${Colors.white};

  max-width: ${(props) => {
    switch (props.size) {
      case 'xs':
        return '400px';
      case 'sm':
        return '600px';
      case 'md':
        return '800px';
      case 'lg':
        return '1000px';
      case 'xl':
        return '1200px';
      default:
        return '1300px';
    }
  }};

  ${(props) => props.size === undefined && `${mediasCSS}`}

  width: 100%;
`;

const ModalContainer = (props: ModalProps) => {
  const { children, isOpen, size, className, closeable, onClose, backdropCloseable, title } = props;

  const handleClose = () => {
    if (onClose) onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Flex.Container
      style={{ overflowY: 'auto', paddingBottom: '40px' }}
      flexDirection="column"
      alignItems="center"
      className={className}
      onClick={() => {
        if (backdropCloseable) {
          handleClose();
        }
      }}
    >
      <ModalContent size={size} onClick={(event) => event.stopPropagation()}>
        {(title || closeable) && (
          <Flex.Container>
            {title && (
              <Flex.Item>
                <Text.Title1>{title}</Text.Title1>
              </Flex.Item>
            )}
            {closeable && (
              <Flex.Item style={{ textAlign: 'right', marginLeft: 'auto' }}>
                <Button onClick={handleClose} $transparent>
                  <Text.Span fontWeight={700}>X</Text.Span>
                </Button>
              </Flex.Item>
            )}
          </Flex.Container>
        )}
        {children}
      </ModalContent>
    </Flex.Container>
  );
};

const Modal = styled(ModalContainer)<{ position?: 'center' | 'top' }>`
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: ${Colors.black.concat('80')};

  justify-content: ${(props) => (props.position === 'center' ? 'center' : 'flex-start')};
  padding-top: ${(props) => (props.position === 'center' ? 0 : '4rem')};
`;

Modal.defaultProps = {
  position: 'center',
};

export default Modal;
