import { createElement } from 'react';
import styled from 'styled-components/macro';
import Colors from '@/app/styles/Colors';

import { Button, Flex } from '@/app/ui/atoms';
// import Icon from './Icons';

type ButtonProps = React.ComponentProps<typeof Button>;

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: ${Colors.grey2};
  border: 1px solid ${Colors.grey};
  min-width: 160px;
  z-index: 9999;
  border-radius: 6px;
  right: 0;
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;

  &:hover,
  &:focus {
    & ${DropdownContent} {
      display: block;
    }
  }
`;

export const DropdownButton = (props: ButtonProps) => {
  const { children } = props;

  return createElement(
    Button,
    { $transparent: true, ...props },
    <Flex.Container style={{ gap: '0.5rem', width: '100%' }}>
      {children}
      {/* <Icon.ArrowDown style={{ alignSelf: 'flex-end' }} /> */}
    </Flex.Container>,
  );
};

const Dropdown = { Container: DropdownContainer, Button: DropdownButton, Content: DropdownContent };

export default Dropdown;
