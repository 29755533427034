import { Distribution, Cradle, DigitalPlatform } from '@solo/app-core';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useCradle } from '@/app/contexts';
import { translate } from '@/app/i18n';
import Colors from '@/app/styles/Colors';
import { Section, Flex, Text, Input } from '@/app/ui/atoms';
import { DigitalDistributionIcons } from '@/app/ui';
import { useBlocState } from '@/app/hooks';

interface DigitalDistribution {
  distribution: Distribution;
  digitalPlatform?: DigitalPlatform;
}

const DigitalDistributionComponent = () => {
  const { id } = useParams();
  const { digitalDistributionBloc } = useCradle<Cradle>();
  const digitalDistributionBlocState = useBlocState(digitalDistributionBloc);
  const [digitalDistributions, setDigitalDistributions] = useState<DigitalDistribution[]>([]);

  useEffect(() => {
    async function loadDistribution() {
      if (digitalDistributionBlocState.kind !== 'LoadedDigitalDistributionState') return;
      const distributions = await digitalDistributionBloc.getDistributions({
        musicalReleaseId: id,
      });
      const dist: DigitalDistribution[] = distributions.map((distribution) => {
        const digitalPlatform = digitalDistributionBlocState.platforms.find(
          (p) => p.id === distribution.digitalPlatformId,
        );
        return { distribution, digitalPlatform };
      });
      setDigitalDistributions(dist);
    }

    loadDistribution();
  }, []);

  return (
    <Section>
      <Flex.Container flex={1} className="table" flexDirection="column">
        <Flex.Container flex={1} className="thead">
          <Flex.Container flex={3} className="th" alignItems="center">
            <Input.Checkbox style={{ marginRight: 15 }} />
            <Text.Paragraph fontWeight={600} style={{ padding: 0 }}>
              {translate('DIGITAL_DISTRIBUTION.PLATFORM')}
            </Text.Paragraph>
          </Flex.Container>
          {/* <Flex.Item flex={2} className="th">
            {translate('DIGITAL_DISTRIBUTION.DATE')}
          </Flex.Item> */}
          <Flex.Item flex={4} className="th">
            {translate('DIGITAL_DISTRIBUTION.STATUS')}
          </Flex.Item>
          <Flex.Item flex={2} className="th">
            {translate('ACTIONS.ACTIONS')}
          </Flex.Item>
        </Flex.Container>
        <Flex.Container className="tbody" flexDirection="column">
          {digitalDistributions?.map((digitalDs) => (
            <Flex.Container key={digitalDs.distribution.id} className="tr" alignItems="center">
              <Flex.Container className="td" flex={3} alignItems="center">
                <Input.Checkbox style={{ marginRight: 15 }} />
                <DigitalDistributionIcons
                  style={{ marginRight: 15 }}
                  width={25}
                  icon={digitalDs.digitalPlatform?.iconUrl || ''} // TODO icon not found
                />
                {digitalDs.digitalPlatform?.name || 'Platform not found'}
              </Flex.Container>
              {/* <Flex.Item flex={2} className="td">
                {platforms[digitalDs.id].date}
              </Flex.Item> */}
              <Flex.Item flex={4} className="td">
                {digitalDs.distribution.status}
              </Flex.Item>
              <Flex.Item flex={2} className="td">
                <Text.Paragraph fontWeight={600} color={Colors.primary}>
                  Release
                </Text.Paragraph>
              </Flex.Item>
            </Flex.Container>
          ))}
        </Flex.Container>
      </Flex.Container>
    </Section>
  );
};

export default DigitalDistributionComponent;
