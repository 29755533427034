import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryStack,
  VictoryTheme,
} from 'victory';
import Colors from '@/app/styles/Colors';

export interface BarData {
  x: string;
  y: number;
}

interface CustomBarChartProps {
  data: BarData[][];
  barColors: string[];
  xaxis?: string;
  yaxis?: string;
}

const CustomBarChart = (props: CustomBarChartProps) => {
  const { data, barColors, xaxis, yaxis } = props;

  return (
    <VictoryChart
      theme={VictoryTheme.material}
      domainPadding={100}
      padding={{ top: 20, bottom: 32, left: 70, right: 70 }}
      height={255}
      width={1500}
    >
      <VictoryAxis
        dependentAxis
        style={{
          tickLabels: { fontSize: '14px', fill: Colors.black },
          grid: { stroke: Colors.borderInput, strokeDasharray: '0' },
          axis: { stroke: Colors.borderInput },
        }}
      />
      <VictoryAxis
        style={{
          tickLabels: { fontSize: '14px', fill: Colors.black },
          grid: { stroke: 'transparent' },
          axis: { stroke: Colors.borderInput },
        }}
        label={xaxis}
      />

      <VictoryLabel x={20} y={5} text={yaxis} style={{ fontSize: '18px' }} />
      <VictoryStack colorScale={barColors}>
        {data.map((d, index) => (
          <VictoryBar
            key={`${d[index]?.x} ${d[index]?.y}`}
            cornerRadius={index === data.length - 1 ? { topLeft: 5, topRight: 5 } : {}}
            data={d}
            categories={{ x: d.map((group) => group.x.toString()) }}
          />
        ))}
      </VictoryStack>
    </VictoryChart>
  );
};

export default CustomBarChart;
