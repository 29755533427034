import { AuthorComposer, Cradle, RecordingAuthor, RecordingAuthorRole } from '@solo/app-core';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Flex, Text, Button } from '@/app/ui/atoms';
import {
  InputWithLabel,
  InputTextAutocomplete,
  NumericInputWithLabel,
  RadioButtonWithLabel,
} from '@/app/ui/molecules';
import { translate } from '@/app/i18n';
import { useCradle } from '@/app/contexts';
import { useBlocState } from '@/app/hooks';

const roles = ['Main', 'Composer', 'Editor'];

interface RecordingAuthorComposerPopUpProps {
  saveRecordingAuthor: (recordingAuthor: RecordingAuthor) => void;
  closeModal: () => void;
  currentRecordingAuthors: RecordingAuthor[];
}

const RecordingAuthorComposerPopUp = (props: RecordingAuthorComposerPopUpProps) => {
  const { saveRecordingAuthor, closeModal, currentRecordingAuthors } = props;
  const [newRecordingAuthor, setNewRecordingAuthor] = useState<Partial<RecordingAuthor>>({});
  const { musicalWorksBloc, authBloc } = useCradle<Cradle>();
  const authState = useBlocState(authBloc);

  const [newAuthorComposerName, setNewAuthorComposerName] = useState<string | undefined>();
  const [existingAuthorComposer, setExistingAuthorComposer] = useState<
    AuthorComposer | undefined
  >();

  const [recordingAuthors, setRecordingAuthors] =
    useState<RecordingAuthor[]>(currentRecordingAuthors);
  const [searching, setSearching] = useState<boolean>(true);
  const [authorComposers, setAuthorComposers] = useState<AuthorComposer[]>([]);

  useEffect(() => {
    async function loadAuthorComposers() {
      if (authState.kind !== 'AuthenticatedAuthState') return;
      const authComps = await musicalWorksBloc.getAuthorComposers();
      if (!authComps) return;
      setAuthorComposers(authComps);
    }
    loadAuthorComposers();
  }, []);

  useEffect(() => {
    setRecordingAuthors(currentRecordingAuthors);
  }, [currentRecordingAuthors]);

  async function addRecordingAuthor() {
    if (
      (newAuthorComposerName === undefined && !searching) ||
      (newRecordingAuthor === undefined && searching) ||
      newRecordingAuthor?.role === undefined ||
      newRecordingAuthor?.performanceSplit === undefined ||
      newRecordingAuthor?.mechanicalSplit === undefined
    ) {
      toast.error(translate('TOAST.MANDATORY_ERROR'));
      return;
    }

    if (
      newRecordingAuthor.performanceSplit +
        recordingAuthors
          .filter((author) => author.performanceSplit)
          .reduce((perf, cur) => cur.performanceSplit + perf, 0) >
      100
    ) {
      toast.error(translate('TOAST.PERF_100_ERROR'));
      return;
    }
    if (
      newRecordingAuthor.mechanicalSplit +
        recordingAuthors
          .filter((author) => author.mechanicalSplit)
          .reduce((perf, cur) => cur.mechanicalSplit + perf, 0) >
      100
    ) {
      toast.error(translate('TOAST.MECH_100_ERROR'));
      return;
    }
    let authorComposer: AuthorComposer | undefined;

    if (!searching) {
      if (authState.kind !== 'AuthenticatedAuthState' || !newAuthorComposerName) return;
      try {
        authorComposer = await musicalWorksBloc.createAuthorComposer(
          authState.user.id,
          newAuthorComposerName,
        );
      } catch (error) {
        toast.error(translate('TOAST.CREATE_AUTHOR_ERROR'));
      }
    } else {
      if (authState.kind !== 'AuthenticatedAuthState' || !existingAuthorComposer) return;
      try {
        authorComposer = await musicalWorksBloc.searchAuthorComposerByComposerId(
          existingAuthorComposer.id,
        );
      } catch (error) {
        toast.error(translate('TOAST.CREATE_AUTHOR_ERROR'));
      }
    }

    if (!authorComposer) return;

    const recordingAuthor: RecordingAuthor = {
      role: newRecordingAuthor.role,
      mechanicalSplit: newRecordingAuthor.mechanicalSplit,
      performanceSplit: newRecordingAuthor.performanceSplit,
      authorComposerId: authorComposer?.id,
      authorComposerName: searching ? authorComposer?.name : newAuthorComposerName,
    };

    saveRecordingAuthor(recordingAuthor);
    closeModal();
  }

  function addExistingRecordingAuthor(recordingAuthorId: string) {
    const authorComposer = authorComposers.find((ac) => ac.id === recordingAuthorId);
    if (!authorComposer) return;
    setExistingAuthorComposer(authorComposer);
  }

  return (
    <Flex.Container gap="30px" flexDirection="column" padding="0px 130px 0px 130px">
      <Flex.Container gap="10px" alignItems="center">
        <Text.SubtitleBold>{`${translate(
          'WORKS_PAGES.ADD_NEW_AUTHOR_COMPOSER',
        )}`}</Text.SubtitleBold>
      </Flex.Container>
      <Flex.Container gap="10px" alignItems="center">
        <Text.SubtitleBold>{`${translate('WORKS_PAGES.AUTHOR_COMPOSER_DATA')}`}</Text.SubtitleBold>
      </Flex.Container>
      <RadioButtonWithLabel
        checked={searching}
        onChange={() => setSearching(true)}
        label={`${translate('WORKS_PAGES.SEARCH_AUTHOR_COMPOSER')}`}
      />
      <InputTextAutocomplete
        placeholder={`${translate('WORKS_PAGES.SEARCH_NAME_COMPOSERID')}`}
        label={`${translate('WORKS.SEARCH')}`}
        items={authorComposers.map((ac) => ({ label: ac.name, value: ac.id }))}
        onChange={(recordingAuthorId: string) => addExistingRecordingAuthor(recordingAuthorId)}
        disabled={!searching}
      />
      <RadioButtonWithLabel
        checked={!searching}
        onChange={() => setSearching(false)}
        label={`${translate('WORKS_PAGES.CREATE_AUTHOR_COMPOSER')}`}
      />
      <InputWithLabel
        label={translate('WORKS_PAGES.FULLNAME')}
        placeholder={translate('WORKS_PAGES.FULLNAME')}
        onChange={(name) => setNewAuthorComposerName(name)}
        value={newAuthorComposerName}
        disabled={searching}
      />
      <Flex.Container gap="10px" alignItems="center">
        <Text.SubtitleBold>{`${translate('WORKS_PAGES.AUTHOR_COMPOSER_RULES')}`}</Text.SubtitleBold>
      </Flex.Container>
      <Flex.Container gap="16px">
        <Flex.Container flex={5}>
          <InputTextAutocomplete
            label={translate('TRACKS.ROLE')}
            placeholder={translate('WORKS_PAGES.TYPES_OF_ROLE')}
            items={Array.from(roles, (role: string) => ({
              value: role,
              label: role,
            }))}
            onChange={(role) =>
              setNewRecordingAuthor({
                ...newRecordingAuthor,
                role: role as RecordingAuthorRole,
              })
            }
            defaultOption={
              newRecordingAuthor.role
                ? {
                    value: newRecordingAuthor.role,
                    label: newRecordingAuthor.role,
                  }
                : undefined
            }
          />
        </Flex.Container>
        <Flex.Container flex={1}>
          <NumericInputWithLabel
            label={translate('TRACKS.PERF')}
            placeholder="100%"
            value={newRecordingAuthor.performanceSplit}
            onChange={(performanceSplit) =>
              setNewRecordingAuthor({
                ...newRecordingAuthor,
                performanceSplit,
              })
            }
          />
        </Flex.Container>
        <Flex.Container flex={1}>
          <NumericInputWithLabel
            value={newRecordingAuthor.mechanicalSplit}
            label={translate('TRACKS.MECH')}
            placeholder="100%"
            onChange={(mechanicalSplit) =>
              setNewRecordingAuthor({
                ...newRecordingAuthor,
                mechanicalSplit,
              })
            }
          />
        </Flex.Container>
      </Flex.Container>
      <Flex.Container justifyContent="flex-end" gap="5px">
        <Button $size="md" $transparent $variant="primary" onClick={() => closeModal()}>
          <Text.SectionSubtitle style={{ padding: 0 }} fontWeight={600}>
            {translate('PROFILE_INFO.CANCEL')}
          </Text.SectionSubtitle>
        </Button>
        <Button
          $size="md"
          $outline
          $transparent
          $variant="primary"
          onClick={() => addRecordingAuthor()}
        >
          <Text.SectionSubtitle style={{ padding: 0 }} fontWeight={600}>
            {translate('BUTTON.SAVE_ADD')}
          </Text.SectionSubtitle>
        </Button>
      </Flex.Container>
    </Flex.Container>
  );
};

export default RecordingAuthorComposerPopUp;
