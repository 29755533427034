import { Flex, Text } from '@/app/ui/atoms';
import TooltipInfo from '@/app/ui/organisms/TooltipInfo';

interface DataInfoProps {
  label: string;
  value: string | number;
  infoTooltip?: string;
  style?: React.CSSProperties;
}

const DataInfo = ({ label, value, infoTooltip, style }: DataInfoProps) => (
  <Flex.Container flexDirection="column" style={style}>
    <Flex.Item>
      <Flex.Container alignItems="center">
        <Text.Label fontSize={14}>{label}</Text.Label>
        {infoTooltip && <TooltipInfo text={infoTooltip} />}
      </Flex.Container>
    </Flex.Item>
    <Flex.Item>
      <Text.Paragraph>{value}</Text.Paragraph>
    </Flex.Item>
  </Flex.Container>
);

export default DataInfo;
