import { translate } from '@/app/i18n';
import { InsightData } from '@/app/pages/DashboardPage/DashboardPage';
import Colors from '@/app/styles/Colors';
import { Flex, RouterLink, Text } from '@/app/ui/atoms';
import { Icon } from '@/app/ui/molecules';

interface MySongsInsightsProps {
  insightData?: InsightData;
}

const MySongsInsights = (props: MySongsInsightsProps) => {
  const { insightData } = props;
  return (
    <Flex.Container flexDirection="column" gap="2rem">
      <Flex.Container
        gap="7rem"
        padding="0 0 2rem 0"
        style={{ borderBottom: `1px solid ${Colors.borderInput}` }}
      >
        <Flex.Item flex={1}>
          <Flex.Container flexDirection="column">
            <Text.LabelSmall>{translate('DASHBOARD.TOTAL_ALBUMS')}</Text.LabelSmall>
            <Text.BiggerBold>{insightData?.totalAlbums || 0}</Text.BiggerBold>
          </Flex.Container>
        </Flex.Item>
        <Flex.Item flex={1}>
          <Flex.Container flexDirection="column">
            <Text.LabelSmall>{translate('DASHBOARD.TOTAL_EPS')}</Text.LabelSmall>
            <Text.BiggerBold>{insightData?.totalEps || 0}</Text.BiggerBold>
          </Flex.Container>
        </Flex.Item>
        <Flex.Item flex={1}>
          <Flex.Container flexDirection="column">
            <Text.LabelSmall>{translate('DASHBOARD.TOTAL_SINGLES')}</Text.LabelSmall>
            <Text.BiggerBold>{insightData?.totalSingles || 0}</Text.BiggerBold>
          </Flex.Container>
        </Flex.Item>
      </Flex.Container>
      <Flex.Container gap="7rem">
        <Flex.Item flex={1}>
          <Flex.Container flexDirection="column">
            <Text.LabelSmallBold>{translate('DASHBOARD.TOTAL_NET_REVENUE')}</Text.LabelSmallBold>
            <Text.BiggerBold>{insightData?.totalRevenue || 0}€</Text.BiggerBold>
          </Flex.Container>
        </Flex.Item>
        <Flex.Item flex={1}>
          <Flex.Container flexDirection="column">
            <Text.LabelSmall>{translate('DASHBOARD.PUBLISHING_REVENUE')}</Text.LabelSmall>
            <Text.BiggerBold>{insightData?.publishingRevenue || 0}€</Text.BiggerBold>
          </Flex.Container>
        </Flex.Item>
        <Flex.Item flex={1}>
          <Flex.Container flexDirection="column" style={{ width: '110%' }}>
            <Text.LabelSmall>{translate('DASHBOARD.DISTRIBUTION_REVENUE')}</Text.LabelSmall>
            <Text.BiggerBold>{insightData?.distributionRevenue || 0}€</Text.BiggerBold>
          </Flex.Container>
        </Flex.Item>
      </Flex.Container>
      <Flex.Container className="mt-1">
        <RouterLink style={{ padding: 0 }} $size="md" $transparent to="/works">
          <Flex.Container gap="1rem" alignItems="center">
            <Text.SectionSubtitle style={{ color: Colors.primary }}>
              {translate('DASHBOARD.VIEW_ALL_SONGS')}
            </Text.SectionSubtitle>
            <Icon.PrimaryArrowRight />
          </Flex.Container>
        </RouterLink>
      </Flex.Container>
    </Flex.Container>
  );
};
export default MySongsInsights;
