import { useDrop } from 'react-dnd';

export type DraggableItem = {
  text: string;
};
interface DropItemProps {
  readonly children: React.ReactNode;
  readonly accept: string;
  readonly drop: (item: string) => void;
}

const DropItem = ({ children, accept, drop }: DropItemProps) => {
  const [, dropRef] = useDrop(
    () => ({
      accept,
      drop: (item) => {
        const data = item as DraggableItem;
        drop(data.text);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [],
  );

  return <div ref={dropRef}>{children}</div>;
};

export default DropItem;
