import { createRef, useEffect, useState } from 'react';
import Select from 'react-select/dist/declarations/src/Select';
import {
  CreateRecordingProps,
  UpdateRecordingProps,
  Recording,
  RecordingArtist,
  RecordingAuthor,
  RecordingId,
  RecordingType,
  RecordingArtistRole,
  ISRC,
} from '@solo/app-core';
import { toast } from 'react-toastify';
import { translate } from '@/app/i18n';
import { Button, Flex, Text } from '@/app/ui/atoms';
import {
  CheckboxWithLabel,
  Icon,
  InputTextAutocomplete,
  InputWithLabel,
  Modal,
  RadioButtonWithLabel,
  TextAreaWithLabel,
} from '@/app/ui/molecules';
import {
  ArtistsTableComponent,
  AuthorsComposersTableComponent,
  AuthorsNameTableComponent,
  FileUploadComponent,
  RecordingAuthorComposerPopUp,
} from '@/app/ui/organisms';
import Colors from '@/app/styles/Colors';
import { Artist } from '@/app/ui/organisms/Artists/ArtistsTableComponent';
import { Item } from '@/app/ui/molecules/InputTextAutocomplete';

const languages = ['Spanish', 'English', 'French'];
const roles = ['Main', 'Composer', 'Editor'];

interface RecordingFormProps {
  closeForm: () => void;
  recordingData?: Recording;
  createRecording: (recording: CreateRecordingProps) => void;
  updateRecording: (recording: UpdateRecordingProps, recordingId: RecordingId) => void;
  setNewAudio: (audio: File) => void;
}

const RecordingForm = (props: RecordingFormProps) => {
  const { closeForm, recordingData, createRecording, updateRecording, setNewAudio } = props;

  const [recordingFormData, setRecordingFormData] = useState<
    CreateRecordingProps | UpdateRecordingProps
  >({
    ...recordingData,
    type: recordingData ? recordingData.type : RecordingType.ORIGINAL,
  });

  const [fieldsMissing, setFieldsMissing] = useState<boolean>(false);

  const [originalAuthors, setOriginalAuthors] = useState<string[] | undefined>();
  const [newOriginalAuthor, setNewOriginalAuthor] = useState<string | undefined>();

  const trackTypeRef = createRef<Select<Item>>();

  const [artists, setArtists] = useState<RecordingArtist[]>(recordingData?.artists || []);
  const [newArtist, setNewArtist] = useState<Partial<RecordingArtist>>({});
  const [recordingAuthors, setRecordingAuthors] = useState<RecordingAuthor[]>([]);
  const [addingAuthorComposer, setAddingAuthorComposer] = useState<boolean>(false);

  const [hasIsrc, setHasIsrc] = useState<boolean | undefined>(recordingData?.isrc !== undefined);
  const [isrc, setIsrc] = useState<ISRC | undefined>();
  const [hasLicense, setHasLicense] = useState<boolean | undefined>();

  const [audio, setAudio] = useState<File | undefined>();

  useEffect(() => {
    if (audio) setNewAudio(audio);
  }, [audio]);

  useEffect(() => {
    if (recordingData === undefined) {
      setOriginalAuthors([]);
      setRecordingAuthors([]);
      return;
    }
    if (recordingFormData.type === RecordingType.ORIGINAL) {
      setRecordingAuthors(recordingData.authors as RecordingAuthor[]);
      return;
    }
    setOriginalAuthors(recordingData.authors as string[]);
  }, [recordingFormData.type]);

  function deleteOriginalAuthor(author: string) {
    setOriginalAuthors(originalAuthors?.filter((currentAuthor) => currentAuthor !== author));
  }

  function addNewOriginalAuthor() {
    if (originalAuthors && newOriginalAuthor) {
      setOriginalAuthors([...originalAuthors, newOriginalAuthor]);
      setNewOriginalAuthor(undefined);
    }
  }

  function deleteArtist(artist: Artist) {
    setArtists(artists?.filter((currentArtist) => currentArtist !== artist));
  }

  function deleteRecordingAuthor(recordingAuthor: RecordingAuthor) {
    setRecordingAuthors(
      recordingAuthors?.filter(
        (currentrecordingAuthor) => currentrecordingAuthor !== recordingAuthor,
      ),
    );
  }

  function addArtist() {
    if (artists && newArtist.name && newArtist.role) {
      setArtists([...artists, { name: newArtist.name, role: newArtist.role }]);
      setNewArtist({});
    }
  }

  function clearForm() {
    setRecordingFormData({});
    setArtists([]);
    setRecordingAuthors([]);
    setOriginalAuthors([]);
  }

  function checkMandatory() {
    if (
      recordingFormData.title === undefined ||
      recordingFormData.explicitContent === undefined ||
      artists.length === 0 ||
      recordingAuthors.length === 0 ||
      !audio
    )
      return false;
    return true;
  }

  function saveData() {
    if (!checkMandatory()) {
      setFieldsMissing(true);
      throw new Error('All mandatory fields must be filled!');
    }

    const data = { ...recordingFormData, artists, authors: recordingAuthors, isrc };

    if (recordingData) {
      updateRecording(data, recordingData.id);
      return;
    }
    createRecording(data as CreateRecordingProps);
  }

  async function saveDataAndClose() {
    try {
      await saveData();
      closeForm();
    } catch (error) {
      toast.error(translate('TOAST.MANDATORY_ERROR'));
    }
  }

  async function saveDataAndContinue() {
    try {
      await saveData();
      clearForm();
    } catch (error) {
      toast.error(translate('TOAST.MANDATORY_ERROR'));
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <Flex.Container flexDirection="column" gap="4rem" style={{ width: '100%' }}>
      <Flex.Container gap="30px">
        <Text.SubtitleBold>{translate('WORKS_PAGES.NEW_TRACK')}</Text.SubtitleBold>
        <Button $transparent $variant="primary" onClick={() => closeForm()}>
          <Text.LabelMedium style={{ padding: 0 }} fontWeight={600}>
            {translate('PROFILE_INFO.CANCEL')}
          </Text.LabelMedium>
        </Button>
      </Flex.Container>
      <Flex.Container gap="30px" flexDirection="column">
        <Text.SubtitleBold>{`1. ${translate('TRACKS.TRACK_TITLE')}`}</Text.SubtitleBold>
        <InputWithLabel
          placeholder={translate('TRACKS.TRACK_TITLE')}
          label={translate('TRACKS.TRACK_TITLE')}
          value={recordingFormData?.title}
          onChange={(title) => setRecordingFormData({ ...recordingFormData, title })}
          mandatoryError={fieldsMissing && !recordingFormData.title}
        />
        <InputWithLabel
          tooltip
          placeholder={translate('TRACKS.ALTERNATIVE_TITLE')}
          label={translate('TRACKS.ALTERNATIVE_TITLE')}
          value={recordingFormData?.alternativeTitle}
          onChange={(alternativeTitle) =>
            setRecordingFormData({ ...recordingFormData, alternativeTitle })
          }
        />
      </Flex.Container>
      <Flex.Container gap="20px" flexDirection="column">
        <Text.SubtitleBold>{`2. ${translate('WORKS_PAGES.TRACK_DETAILS')}`}</Text.SubtitleBold>
        <Flex.Container style={{ width: '49%' }}>
          <InputTextAutocomplete
            onChange={(type) =>
              setRecordingFormData({ ...recordingFormData, type: type as RecordingType })
            }
            ref={trackTypeRef}
            label={translate('TRACKS.COMPOSITION_TYPE')}
            tooltip
            items={[
              { value: RecordingType.ORIGINAL, label: translate('TRACKS.ORIGINAL') },
              { value: RecordingType.COVER, label: 'Cover' },
            ]}
            defaultOption={
              recordingFormData.type
                ? { value: recordingFormData.type, label: recordingFormData.type }
                : { value: RecordingType.ORIGINAL, label: translate('TRACKS.ORIGINAL') }
            }
          />
        </Flex.Container>
        {recordingFormData.type === 'COVER' && (
          <Flex.Container
            style={{ backgroundColor: Colors.whitePurple }}
            flexDirection="column"
            gap="16px"
            padding="24px 32px 32px 24px"
          >
            <Flex.Container gap="0.5rem">
              <Icon.AlertCircleIcon />
              <Text.LabelMedium>{translate('WORKS_PAGES.HAVE_LICENSE')}</Text.LabelMedium>
            </Flex.Container>
            <Flex.Container flexDirection="column" gap="16px" padding="0px 0px 0px 24px">
              <RadioButtonWithLabel
                checked={hasLicense || false}
                label={translate('TRACKS.YES')}
                onChange={() => setHasLicense(true)}
              />
              <RadioButtonWithLabel
                checked={!hasLicense && hasLicense !== undefined}
                label={translate('TRACKS.NO')}
                onChange={() => setHasLicense(false)}
              />
              {!hasLicense && (
                <Text.LabelSmallBold>{translate('WORKS_PAGES.NO_LICENSE')}</Text.LabelSmallBold>
              )}
            </Flex.Container>
          </Flex.Container>
        )}
        <Flex.Container gap="16px">
          <Flex.Container flex={1}>
            <InputTextAutocomplete
              label={translate('WORKS.LANGUAGE')}
              tooltip
              items={Array.from(languages, (language: string) => ({
                value: language,
                label: language,
              }))}
              defaultOption={
                recordingFormData.language
                  ? { value: recordingFormData.language, label: recordingFormData.language }
                  : undefined
              }
              onChange={(language) => setRecordingFormData({ ...recordingFormData, language })}
            />
          </Flex.Container>
          <Flex.Container flex={1}>
            <InputTextAutocomplete
              label={translate('TRACKS.EXPLICIT_CONTENT')}
              placeholder={`${translate('TRACKS.YES')}/${translate('TRACKS.NO')}`}
              tooltip
              items={Array.from(
                [translate('TRACKS.YES'), translate('TRACKS.NO')],
                (response: string) => ({
                  value: response,
                  label: response,
                }),
              )}
              defaultOption={
                recordingFormData.explicitContent !== undefined
                  ? {
                      value: recordingFormData.explicitContent
                        ? translate('TRACKS.YES')
                        : translate('TRACKS.NO'),
                      label: recordingFormData.explicitContent
                        ? translate('TRACKS.YES')
                        : translate('TRACKS.NO'),
                    }
                  : undefined
              }
              onChange={(explicitContent) =>
                setRecordingFormData({
                  ...recordingFormData,
                  explicitContent: explicitContent === translate('TRACKS.YES'),
                })
              }
              mandatoryError={fieldsMissing && !recordingFormData.explicitContent}
            />
          </Flex.Container>
        </Flex.Container>
        <Flex.Container gap="25px" flexDirection="column">
          <Text.SubtitleBold>{translate('TRACKS.AUDIO')}</Text.SubtitleBold>
          <FileUploadComponent type="audio" onChange={(audioFile) => setAudio(audioFile)} />
        </Flex.Container>
        <TextAreaWithLabel
          placeholder={translate('TRACKS.LYRICS')}
          label={translate('TRACKS.LYRICS')}
          defaultValue={recordingFormData?.lyrics}
          onChange={(lyrics) => setRecordingFormData({ ...recordingFormData, lyrics })}
        />
      </Flex.Container>
      <Flex.Container gap="30px" flexDirection="column">
        <Flex.Container gap="10px" alignItems="center">
          <Text.SubtitleBold>{`3. ${translate('WORKS_PAGES.ARTIST_PERFORMER')}`}</Text.SubtitleBold>
          <Icon.InfoIcon />
        </Flex.Container>

        <Flex.Container gap="16px">
          <Flex.Container flex={4}>
            <InputWithLabel
              label={translate('TRACKS.ARTIST')}
              placeholder={translate('WORKS.ARTIST_NAME')}
              onChange={(name) => setNewArtist({ ...newArtist, name })}
              value={newArtist.name}
            />
          </Flex.Container>
          <Flex.Container flex={3}>
            <InputTextAutocomplete
              label={translate('TRACKS.ROLE')}
              placeholder={translate('WORKS_PAGES.TYPES_OF_ROLE')}
              items={Array.from(roles, (role: string) => ({
                value: role,
                label: role,
              }))}
              onChange={(role) => setNewArtist({ ...newArtist, role: role as RecordingArtistRole })}
              defaultOption={
                newArtist.role ? { value: newArtist.role, label: newArtist.role } : undefined
              }
            />
          </Flex.Container>
          <Flex.Container flex={6}>
            <Button
              $size="md"
              $variant="primary"
              style={{ marginTop: '20px', height: '40px', width: '120px' }}
              onClick={() => addArtist()}
            >
              <Text.SectionSubtitle style={{ padding: 0 }} fontWeight={600}>
                {translate('BUTTON.ADD')}
              </Text.SectionSubtitle>
            </Button>
          </Flex.Container>
        </Flex.Container>
        {artists && artists?.length > 0 ? (
          <ArtistsTableComponent
            artists={artists}
            deleteArtist={(artist: Artist) => deleteArtist(artist)}
          />
        ) : (
          <Text.ErrorMessage>
            {fieldsMissing && translate('TRACKS.MISSING_ARTIST')}
          </Text.ErrorMessage>
        )}
      </Flex.Container>
      {recordingFormData.type === RecordingType.ORIGINAL ? (
        <Flex.Container gap="30px" flexDirection="column">
          <Flex.Container gap="10px" alignItems="center">
            <Text.SubtitleBold>{`4. ${translate(
              'WORKS_PAGES.AUTHOR_COMPOSERS',
            )}`}</Text.SubtitleBold>
            <Icon.InfoIcon />
          </Flex.Container>
          <Flex.Container alignItems="center">
            <CheckboxWithLabel label={translate('WORKS_PAGES.SONG_EDITED')} tooltip />
            <Button
              $size="md"
              $variant="primary"
              style={{ height: '40px', marginLeft: 'auto' }}
              onClick={() => setAddingAuthorComposer(true)}
            >
              <Text.SectionSubtitle style={{ padding: 0 }} fontWeight={600}>
                {translate('BUTTON.ADD_AUTHOR_COMPOSER')}
              </Text.SectionSubtitle>
            </Button>
          </Flex.Container>
          {recordingAuthors && recordingAuthors?.length > 0 ? (
            <AuthorsComposersTableComponent
              recordingAuthors={recordingAuthors}
              deleteRecordingAuthor={(recordingAuthor) => deleteRecordingAuthor(recordingAuthor)}
            />
          ) : (
            <Text.ErrorMessage>
              {fieldsMissing && translate('TRACKS.MISSING_AUTHOR')}
            </Text.ErrorMessage>
          )}
        </Flex.Container>
      ) : (
        <Flex.Container flexDirection="column" gap="32px">
          <Flex.Container gap="10px" alignItems="center">
            <Text.SubtitleBold>{`4. ${translate(
              'TRACKS.ORIGINAL_SONG_DETAILS',
            )}`}</Text.SubtitleBold>
            <Icon.InfoIcon />
          </Flex.Container>
          <Flex.Container style={{ width: '49%' }}>
            <InputWithLabel
              placeholder={translate('TRACKS.ORIGINAL_TITLE')}
              label={translate('TRACKS.ORIGINAL_TITLE')}
              defaultValue={recordingData?.originalSongTitle}
              onChange={(originalSongTitle) =>
                setRecordingFormData({ ...recordingFormData, originalSongTitle })
              }
            />
          </Flex.Container>
          <Flex.Container gap="16px" style={{ width: '100%' }}>
            <Flex.Container flex={1}>
              <InputWithLabel
                placeholder={translate('WORKS_PAGES.AUTHOR_COMPOSER')}
                label={translate('WORKS_PAGES.AUTHOR_COMPOSER')}
                onChange={(name) => setNewOriginalAuthor(name)}
                value={newOriginalAuthor}
              />
            </Flex.Container>
            <Flex.Container flex={1}>
              <Button
                $size="md"
                $variant="primary"
                style={{ marginTop: '20px', height: '40px', width: '170px' }}
                onClick={() => addNewOriginalAuthor()}
              >
                <Text.SectionSubtitle style={{ padding: 0 }} fontWeight={600}>
                  {translate('BUTTON.ADD')}
                </Text.SectionSubtitle>
              </Button>
            </Flex.Container>
          </Flex.Container>
        </Flex.Container>
      )}
      {originalAuthors !== undefined && originalAuthors?.length > 0 && (
        <AuthorsNameTableComponent
          authors={originalAuthors}
          deleteAuthor={(author: string) => deleteOriginalAuthor(author)}
        />
      )}
      <Flex.Container gap="30px" flexDirection="column">
        <Text.SubtitleBold>5. ISRC</Text.SubtitleBold>
        <Flex.Container gap="20px" flexDirection="column">
          <RadioButtonWithLabel
            checked={!hasIsrc && hasIsrc !== undefined}
            label={translate('WORKS_PAGES.NEED_ISRC')}
            onChange={() => setHasIsrc(false)}
          />
          <RadioButtonWithLabel
            checked={hasIsrc || false}
            label={translate('WORKS_PAGES.HAVE_ISRC')}
            onChange={() => setHasIsrc(true)}
          />
          {hasIsrc && (
            <InputWithLabel
              placeholder="ISRC"
              label="ISRC"
              defaultValue={recordingData?.isrc}
              onChange={(i) => setIsrc(i)}
            />
          )}
        </Flex.Container>
      </Flex.Container>
      <Flex.Container style={{ marginLeft: 'auto' }} gap="30px">
        <Button
          $transparent
          $variant="primary"
          onClick={() => {
            closeForm();
          }}
        >
          <Text.SectionSubtitle>{translate('PROFILE_INFO.CANCEL')}</Text.SectionSubtitle>
        </Button>
        <Button
          $size="sm"
          $variant="reversed"
          $transparent
          $outline
          style={{ height: '40px', width: '160px' }}
          onClick={() => {
            saveDataAndClose();
          }}
        >
          <Text.SectionSubtitle style={{ padding: 0 }} fontWeight={600}>
            {translate('PROFILE_INFO.SAVE')}
          </Text.SectionSubtitle>
        </Button>
        {!recordingData && (
          <Button
            $size="md"
            $variant="primary"
            style={{ width: '290px' }}
            onClick={() => {
              saveDataAndContinue();
            }}
          >
            <Text.SectionSubtitle style={{ padding: 0 }} fontWeight={600}>
              {translate('BUTTON.SAVE_ADD_TRACK')}
            </Text.SectionSubtitle>
          </Button>
        )}
      </Flex.Container>
      <Modal
        isOpen={addingAuthorComposer}
        onClose={() => setAddingAuthorComposer(false)}
        closeable
        position="top"
        size="md"
      >
        <RecordingAuthorComposerPopUp
          currentRecordingAuthors={recordingAuthors}
          closeModal={() => setAddingAuthorComposer(false)}
          saveRecordingAuthor={(recordingAuthor: RecordingAuthor) =>
            setRecordingAuthors([...recordingAuthors, recordingAuthor])
          }
        />
      </Modal>
    </Flex.Container>
  );
};

export default RecordingForm;
