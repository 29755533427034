import { Flex, Text, Card, Icon } from '@/app/ui';
import Colors from '@/app/styles/Colors';

// TODO CORE
export interface SubscriptionInfo {
  title: string;
  price: number;
  description: string;
  rights: { title: string; description: string }[];
}

interface SubscriptionCardProps {
  info: SubscriptionInfo;
  select?: () => void;
}

const SubscriptionCard = ({ info, select }: SubscriptionCardProps) => (
  <Card.Container
    backgroundColor={Colors.white}
    borderStyle="solid"
    borderSize={1}
    borderColor={Colors.grey3}
    style={{ height: '100%', padding: '4rem', cursor: 'pointer' }}
    onClick={select}
  >
    <Card.Body
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <Flex.Container flexDirection="column">
        <Text.Title2 style={{ fontStyle: 'italic' }}>{info.title}</Text.Title2>

        <Text.Paragraph fontSize={20} className="mt-2">
          {info.price}€/year
        </Text.Paragraph>
        <Text.Paragraph fontSize={14}>{info.description}</Text.Paragraph>
        {info.rights.map(({ title: rightTitle, description: rightDesc }) => (
          <Flex.Container key={rightTitle} alignItems="flex-start" className="mt-4">
            <Icon.CheckIcon width={15} className="mt-1 mr-2" />
            <Flex.Container alignItems="flex-start" flexDirection="column">
              <Text.Paragraph className="pt-0" fontSize={16} fontWeight={500}>
                {rightTitle}
              </Text.Paragraph>
              <Text.Paragraph className="pt-0" fontSize={14}>
                {rightDesc}
              </Text.Paragraph>
            </Flex.Container>
          </Flex.Container>
        ))}
      </Flex.Container>
    </Card.Body>
  </Card.Container>
);

export default SubscriptionCard;
