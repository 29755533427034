import { useEffect, useState } from 'react';
import { Cradle, MusicalRight } from '@solo/app-core';
import { ButtonsNavBar, Flex, Icon, Text } from '@/app/ui';
import { translate } from '@/app/i18n';
import Colors from '@/app/styles/Colors';
import { useCradle } from '@/app/contexts';
import { useBlocState } from '@/app/hooks';

type EaringsByPlatform = {
  amount: number;
  platformName: string;
};

const TopRevenueDSP = () => {
  const [activeTab, setActiveTab] = useState<string>();
  const [earningType, setEarningType] = useState<string | undefined>();
  const { consumptionReportsBloc, digitalDistributionBloc, authBloc } = useCradle<Cradle>();
  const authState = useBlocState(authBloc);

  const [earningsByPlatform, setEarningsByPlatform] = useState<EaringsByPlatform[]>([]);

  useEffect(() => {
    async function getEarningsByPlatform() {
      if (authState.kind !== 'AuthenticatedAuthState') return;
      const platforms = await digitalDistributionBloc.getDigitalPlatforms();
      const earnings: EaringsByPlatform[] = [];
      await Promise.all(
        platforms.map(async (platform) => {
          const earn = await consumptionReportsBloc.getEarnings({
            digitalPlatformId: platform.id,
            rightType: earningType ? (earningType as MusicalRight) : undefined,
            userId: authState.user.id,
          });
          earnings.push({
            amount: earn.map((e) => e.amount).reduce((prev, curr) => prev + curr, 0),
            platformName: platform.name,
          });
        }),
      );
      earnings.sort((a, b) => b.amount - a.amount);
      const topEarnings = earnings.slice(0, 5);
      setEarningsByPlatform(topEarnings);
    }
    getEarningsByPlatform();
  }, [activeTab]);

  function changeTab(tab: string) {
    switch (tab) {
      case translate('EARNINGS.ALL'):
        setEarningType(undefined);
        break;
      case translate('DIGITAL_DISTRIBUTION.DIGITAL_DISTRIBUTION'):
        setEarningType(MusicalRight.DISTRIBUTION);
        break;
      default:
        setEarningType(MusicalRight.PUBLISHING);
        break;
    }
    setActiveTab(tab);
  }

  return (
    <Flex.Container gap="1rem" flexDirection="column">
      <ButtonsNavBar
        defaultTab={translate('EARNINGS.ALL')}
        tabs={[
          translate('EARNINGS.ALL'),
          translate('DIGITAL_DISTRIBUTION.DIGITAL_DISTRIBUTION'),
          translate('EARNINGS.PUBLISHING'),
        ]}
        onSelectTab={(tab) => changeTab(tab)}
      />
      <Flex.Container flexDirection="column">
        {earningsByPlatform.map((earning, index) => (
          <Flex.Container
            gap="1rem"
            key={`${earning.platformName} ${earning.amount}`}
            style={{ borderTop: index !== 0 ? `1px solid ${Colors.borderInput}` : '' }}
            padding="1rem 0"
          >
            <Flex.Container gap="1rem">
              <Icon.SpotifyIcon />
              <Text.LabelMedium style={{ paddingTop: '3px' }}>
                {earning.platformName}
              </Text.LabelMedium>
            </Flex.Container>
            <Flex.Container
              style={{ width: '50%', marginLeft: 'auto' }}
              alignItems="flex-end"
              flexDirection="column"
            >
              <Text.Span>{earning.amount}€</Text.Span>
            </Flex.Container>
          </Flex.Container>
        ))}
      </Flex.Container>
    </Flex.Container>
  );
};

export default TopRevenueDSP;
