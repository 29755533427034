import { translate } from '@/app/i18n';
import { Button, Flex, Text } from '@/app/ui/atoms';
import { Icon } from '@/app/ui/molecules';

export type Artist = {
  name: string;
  role: string;
};

interface ArtistsTableComponentPorps {
  artists: Array<Artist>;
  deleteArtist: (artist: Artist) => void;
}

const ArtistsTableComponent = ({ artists, deleteArtist }: ArtistsTableComponentPorps) => (
  <Flex.Container className="table" flexDirection="column">
    <Flex.Container gap="16px">
      <Flex.Item flex={4} className="th">
        <Text.LabelSmall>{translate('WORKS.ARTIST')}</Text.LabelSmall>
      </Flex.Item>
      <Flex.Item flex={3} className="th">
        <Text.LabelSmall>{translate('TRACKS.ROLE')}</Text.LabelSmall>
      </Flex.Item>
      <Flex.Item flex={6} className="th" />
    </Flex.Container>
    <Flex.Container flexDirection="column" gap="10px">
      {artists.map((artist) => (
        <Flex.Container key={artist.name} className="tr" alignItems="center" gap="16px">
          <Flex.Container className="td" flex={4} alignItems="center">
            {artist.name}
          </Flex.Container>
          <Flex.Item flex={3} className="td">
            {artist.role}
          </Flex.Item>
          <Flex.Item flex={6} className="td">
            <Button
              onClick={() => deleteArtist(artist)}
              style={{ marginLeft: '55px' }}
              $transparent
            >
              <Icon.BinIcon width={15} />
            </Button>
          </Flex.Item>
        </Flex.Container>
      ))}
    </Flex.Container>
  </Flex.Container>
);

export default ArtistsTableComponent;
