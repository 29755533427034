import { useEffect, useState } from 'react';
import {
  Artwork,
  Audio,
  Cradle,
  CreateMusicalReleaseProps,
  CreateRecordingProps,
  Distribution,
  MusicalRelease,
  MusicalReleaseId,
  MusicalReleaseType,
  Recording,
  RecordingId,
  UpdateMusicalReleaseProps,
  UpdateRecordingProps,
} from '@solo/app-core';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Buffer } from 'buffer';
import { Button, Flex, MusicalReleaseSummaryForm, RouterLink, Section, Text } from '@/app/ui';
import { translate } from '@/app/i18n';
import Colors from '@/app/styles/Colors';
import { FormProgress } from '@/app/ui/molecules';
import {
  MusicalReleaseDetailsForm,
  MusicalReleaseTypeForm,
  MusicalReleaseTracksForm,
  MusicalReleaseDistributionForm,
} from '@/app/ui/organisms';
import { useCradle } from '@/app/contexts';
import { useBlocState } from '@/app/hooks';
import { MusicalReleaseDistributionData } from '@/app/ui/organisms/MusicalReleaseDistributionForm/MusicalReleaseDistributionForm';

type MusicalReleaseFormParams = {
  songType: string;
};

const MusicalReleaseForm = () => {
  const { songType } = useParams<MusicalReleaseFormParams>();
  const [type, setType] = useState<MusicalReleaseType | undefined>();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [musicalRelease, setMusicalRelease] = useState<MusicalRelease>();
  const [addReleaseSteps, setAddReleaseSteps] = useState<string[]>([
    translate('WORKS.ALBUM_DETAILS'),
    translate('WORKS_PAGES.TRACKS'),
    translate('WORKS_PAGES.DIGITAL_DISTRIBUTION'),
    translate('WORKS.SUMMARY'),
  ]);
  const [showNavButtons, setShowNavButtons] = useState<boolean>(true);

  const { musicalWorksBloc } = useCradle<Cradle>();
  const musicalWorksState = useBlocState(musicalWorksBloc);
  const { digitalDistributionBloc } = useCradle<Cradle>();
  const [digitalDistributions, setDigitalDistributions] = useState<Distribution[]>([]);
  const [recordings, setRecordings] = useState<Recording[]>([]);
  const [artworkFile, setArworkFile] = useState<File | undefined>();
  const [audioFile, setAudioFile] = useState<File | undefined>();

  const [musicalReleaseDetails, setMusicalReleaseDetails] = useState<
    CreateMusicalReleaseProps | UpdateMusicalReleaseProps
  >();
  const [detailsFieldsMissing, setDetailsFieldsMissing] = useState<boolean>();

  const [musicalReleaseDistribution, setMusicalReleaseDistribution] =
    useState<MusicalReleaseDistributionData>();

  useEffect(() => {
    async function getMusicalRelease() {
      const musicalRel = await musicalWorksBloc.getMusicalRelease(
        musicalRelease?.id as MusicalReleaseId,
      );
      setMusicalRelease(musicalRel);

      const recs: Recording[] = [];
      await musicalRel?.recordingIds?.forEach(async (id) => {
        const rec = await musicalWorksBloc.getRecording(id);
        recs.push(rec);
      });
      setRecordings(recs);
    }
    async function getDistributions() {
      const distributions = await digitalDistributionBloc.getDistributions({
        musicalReleaseId: musicalRelease?.id as MusicalReleaseId,
      });
      setDigitalDistributions(distributions);
    }

    if (musicalRelease) getMusicalRelease();
    getDistributions();
  }, [musicalWorksState]);

  useEffect(() => {
    let [firstStep] = addReleaseSteps;
    const [, ...restOfSteps] = addReleaseSteps;
    switch (type) {
      case 'EP':
        firstStep = translate('WORKS.EP_DETAILS');
        break;
      case 'SINGLE':
        firstStep = translate('WORKS.SINGLE_DETAILS');
        break;
      case 'ALBUM':
        firstStep = translate('WORKS.ALBUM_DETAILS');
        break;
      default:
    }
    setAddReleaseSteps([firstStep, ...restOfSteps]);
  }, [type]);

  useEffect(() => {
    if (!songType) return;
    setType(songType.toUpperCase() as MusicalReleaseType);
    setCurrentStep(1);
  }, [songType]);

  function checkMandatoryDetailsFields() {
    if (!musicalReleaseDetails) return false;
    return (
      musicalReleaseDetails.title !== '' &&
      musicalReleaseDetails.releaseDate &&
      musicalReleaseDetails.releaseTime !== '' &&
      musicalReleaseDetails.language !== '' &&
      musicalReleaseDetails.primaryGenre !== ''
    );
  }

  async function createMusicalRelease() {
    if (!musicalReleaseDetails || !checkMandatoryDetailsFields())
      throw new Error('All mandatory fields must be filled!');
    let musicalReleaseResponse: MusicalRelease | undefined;
    if (musicalRelease) {
      try {
        await musicalWorksBloc.updateMusicalRelease(musicalRelease.id, musicalReleaseDetails);
      } catch (error) {
        toast.error(translate('TOAST.CHANGES_ERROR'));
      }
      if (!musicalReleaseResponse || !artworkFile) return;
      try {
        const buffer = Buffer.from(await artworkFile.arrayBuffer());
        const artwork: Artwork = {
          file: buffer,
          name: artworkFile.name,
          type: artworkFile.type,
        };
        await musicalWorksBloc.setArtworkToMusicalRelease(musicalRelease.id, artwork);
      } catch (error) {
        toast.error(translate('TOAST.CHANGES_ERROR'));
      }
      musicalWorksBloc.loadMusicalReleases();
    } else {
      try {
        musicalReleaseResponse = await musicalWorksBloc.createMusicalRelease(
          musicalReleaseDetails as CreateMusicalReleaseProps,
        );
        setMusicalRelease(musicalReleaseResponse);
      } catch (error) {
        toast.error(translate('TOAST.CHANGES_ERROR'));
      }
      if (!musicalReleaseResponse || !artworkFile) return;
      try {
        const buffer = Buffer.from(await artworkFile.arrayBuffer());
        const artwork: Artwork = {
          file: buffer,
          name: artworkFile.name,
          type: artworkFile.type,
        };
        await musicalWorksBloc.setArtworkToMusicalRelease(musicalReleaseResponse.id, artwork);

        setMusicalRelease(musicalReleaseResponse);
      } catch (error) {
        toast.error(translate('TOAST.CHANGES_ERROR'));
      }
    }
  }

  async function addRecording(recordingId: RecordingId) {
    if (musicalRelease)
      musicalWorksBloc.addRecordingsToMusicalRelease(musicalRelease.id, [recordingId]);
    musicalWorksBloc.loadRecordings();
  }

  async function removeRecording(recordingId: RecordingId) {
    if (musicalRelease)
      musicalWorksBloc.removeRecordingsFromMusicalRelease(musicalRelease.id, [recordingId]);
    musicalWorksBloc.loadRecordings();
  }

  async function createRecording(recording: CreateRecordingProps) {
    let recordingResponse: Recording | undefined;
    try {
      recordingResponse = await musicalWorksBloc.createRecording(
        recording as CreateRecordingProps,
        musicalRelease?.id as MusicalReleaseId,
      );
    } catch (error) {
      toast.error(translate('TOAST.CHANGES_ERROR'));
    }

    if (!recordingResponse || !audioFile) return;

    try {
      const buffer = Buffer.from(await audioFile.arrayBuffer());
      const audio: Audio = {
        file: buffer,
        name: audioFile.name,
        type: audioFile.type,
      };
      await musicalWorksBloc.setAudioToRecording(recordingResponse.id, audio);
    } catch (error) {
      toast.error(translate('TOAST.CHANGES_ERROR'));
    }

    musicalWorksBloc.loadRecordings();
  }

  async function updateRecording(recording: UpdateRecordingProps, recordingId: RecordingId) {
    musicalWorksBloc.updateRecording(recordingId, recording);

    musicalWorksBloc.loadRecordings();
  }

  async function reorderRecordings(recordingId: string, indexTo: number) {
    if (!musicalRelease) return;
    musicalWorksBloc.reorderReleaseRecordings(musicalRelease.id, recordingId, indexTo);
    musicalWorksBloc.loadRecordings();
  }

  async function saveDistributions() {
    if (!musicalReleaseDistribution) return;
    musicalReleaseDistribution.newDistributions.forEach(async (distribution) => {
      await digitalDistributionBloc.createDistribution(
        musicalRelease?.id as MusicalReleaseId,
        distribution,
      );
    });
    musicalReleaseDistribution.updatedDistributions.forEach(async (distribution) => {
      await digitalDistributionBloc.updateDistribution(
        distribution.distributionId,
        distribution.props,
      );
    });
    musicalReleaseDistribution.deletedDistributions.forEach(async (id) => {
      await digitalDistributionBloc.deleteDistribution(id);
    });

    musicalWorksBloc.loadMusicalReleases();
  }

  function endMusicalReleaseForm() {
    setMusicalRelease(undefined);
    setRecordings([]);
    setDigitalDistributions([]);
    setCurrentStep(0);
    toast.success(translate('WORKS.RELEASE_SUCCESS'));
  }

  async function handleNextStep(step: number) {
    switch (step) {
      case 0:
        setMusicalRelease(undefined);
        break;
      case 1:
        try {
          await createMusicalRelease();
          setCurrentStep(step + 1);
        } catch (error) {
          toast.error(translate('TOAST.CHANGES_ERROR'));
          setDetailsFieldsMissing(true);
        }
        break;
      case 2:
        setCurrentStep(step + 1);
        break;
      case 3:
        try {
          await saveDistributions();
          setCurrentStep(step + 1);
        } catch (error) {
          if (error instanceof Error) toast.error(error.message);
        }
        setCurrentStep(step + 1);
        break;
      default:
        break;
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <Section>
      {type === undefined ? (
        <MusicalReleaseTypeForm />
      ) : (
        <Flex.Container
          flexDirection="column"
          style={{ width: '100%' }}
          padding="0px 140px 40px 140px"
        >
          <FormProgress
            steps={addReleaseSteps}
            currentStep={currentStep}
            canGoBack
            setCurrentStep={(step: number) => setCurrentStep(step)}
          />

          <Flex.Container
            flexDirection="column"
            gap="40px"
            padding="30px 90px 20px 90px"
            style={{ border: `1px solid ${Colors.borderInput}`, width: '100%' }}
          >
            {
              {
                1: (
                  <MusicalReleaseDetailsForm
                    releaseType={type}
                    onChange={(details) => setMusicalReleaseDetails(details)}
                    musicalRelease={musicalRelease}
                    detailsFieldsMissing={detailsFieldsMissing}
                    setNewArtwork={(artwork) => setArworkFile(artwork)}
                  />
                ),
                2: (
                  <MusicalReleaseTracksForm
                    addRecording={(recordingId) => addRecording(recordingId)}
                    removeRecording={(recordingId) => removeRecording(recordingId)}
                    createRecording={(recording) => createRecording(recording)}
                    updateRecording={(recording, recordingId) =>
                      updateRecording(recording, recordingId)
                    }
                    reorderRecordings={(recordingId, indexTo) =>
                      reorderRecordings(recordingId, indexTo)
                    }
                    recordings={recordings}
                    showNavButtons={(hide: boolean) => setShowNavButtons(hide)}
                    type={type || ''}
                    setNewAudio={(audio) => setAudioFile(audio)}
                  />
                ),
                3: (
                  <MusicalReleaseDistributionForm
                    musicalReleaseId={musicalRelease?.id as MusicalReleaseId}
                    distributionData={digitalDistributions}
                    onChange={(distributionData) => setMusicalReleaseDistribution(distributionData)}
                  />
                ),
                4: (
                  <MusicalReleaseSummaryForm
                    musicalRelease={musicalRelease as MusicalRelease}
                    digitalDistributions={digitalDistributions}
                    recordings={recordings}
                    stepBackTo={(step) => setCurrentStep(step)}
                  />
                ),
              }[currentStep]
            }
            {showNavButtons && (
              <Flex.Container style={{ marginLeft: 'auto' }} gap="20px">
                <Button
                  $transparent
                  $variant="primary"
                  onClick={() => {
                    setCurrentStep(currentStep - 1);
                  }}
                >
                  <Text.SectionSubtitle>
                    {currentStep > 1
                      ? translate('WORKS_PAGES.BACK')
                      : translate('PROFILE_INFO.CANCEL')}
                  </Text.SectionSubtitle>
                </Button>
                {currentStep < 4 ? (
                  <Button
                    $size="md"
                    $variant="primary"
                    style={{ width: '290px' }}
                    onClick={() => handleNextStep(currentStep)}
                  >
                    <Text.SectionSubtitle style={{ padding: 0 }} fontWeight={600}>
                      {translate('PAGINATION.NEXT_STEP')}
                    </Text.SectionSubtitle>
                  </Button>
                ) : (
                  <RouterLink
                    to="/works"
                    $size="md"
                    $variant="primary"
                    style={{ width: '290px' }}
                    onClick={() => {
                      endMusicalReleaseForm();
                    }}
                  >
                    <Text.SectionSubtitle style={{ padding: 0 }} fontWeight={600}>
                      {translate('PROFILE_INFO.SAVE')}
                    </Text.SectionSubtitle>
                  </RouterLink>
                )}
              </Flex.Container>
            )}
          </Flex.Container>
        </Flex.Container>
      )}
    </Section>
  );
};

export default MusicalReleaseForm;
