import { Recording } from '@solo/app-core';
import { translate } from '@/app/i18n';
import { Flex, RouterLink, Text } from '@/app/ui/atoms';
import { convertArrayToString } from '@/app/utils/ArrayToString';

interface RecordingListComponentProps {
  tracks: Array<Recording>;
}
const RecordingListComponent = ({ tracks }: RecordingListComponentProps) => (
  <Flex.Container flex={1} className="table" flexDirection="column">
    <Flex.Container flex={1} className="thead">
      <Flex.Item flex={2} className="th">
        <Text.Paragraph
          fontWeight={600}
          style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 15 }}
        >
          {translate('TRACKS.ORDER')}
        </Text.Paragraph>
      </Flex.Item>
      <Flex.Item flex={4} className="th">
        {translate('TRACKS.TRACK_TITLE')}
      </Flex.Item>
      <Flex.Item flex={4} className="th">
        {translate('TRACKS.ARTIST')}
      </Flex.Item>
    </Flex.Container>
    <Flex.Container className="tbody" flexDirection="column">
      {tracks.map((track, index) => (
        <Flex.Container key={track.id} className="tr" alignItems="center">
          <Flex.Container className="td" flex={2} alignItems="center">
            <Flex.Container padding="0px 0px 0px 20px">{index + 1}</Flex.Container>
          </Flex.Container>
          <Flex.Item flex={4} className="td">
            <RouterLink $transparent $variant="primary" to={`${track.id}`}>
              <Text.Paragraph fontWeight={600}>{track.title}</Text.Paragraph>
            </RouterLink>
          </Flex.Item>
          <Flex.Item flex={4} className="td">
            {convertArrayToString(track.artists.map((artist) => artist.name))}
          </Flex.Item>
        </Flex.Container>
      ))}
    </Flex.Container>
  </Flex.Container>
);

export default RecordingListComponent;
