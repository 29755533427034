import styled from 'styled-components/macro';
import { Link, LinkProps } from 'react-router-dom';
import { createElement } from 'react';
import Icon from './Icons';

const LogoLink = (props: LinkProps & React.RefAttributes<HTMLAnchorElement>) =>
  createElement(Link, props, <Icon.Logo height="100%" width="auto" />);

const NavLogo = styled(LogoLink)`
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 2.5rem;
  font-weight: 800;
  transition: all 0.5s ease;
  &:hover {
    transform: scale(1.08);
  }
`;

export default NavLogo;
