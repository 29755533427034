import {
  Cradle,
  Recording,
  MusicalReleaseId,
  RecordingId,
  CreateRecordingProps,
  UpdateRecordingProps,
  Audio,
} from '@solo/app-core';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { translate } from '@/app/i18n';
import { Button, Section, Flex, Text } from '@/app/ui/atoms';
import RecordingTableComponent from './RecordingTableComponent/RecordingTableComponent';
import { useCradle } from '@/app/contexts';
import { Modal } from '@/app/ui/molecules';
import { useBlocState } from '@/app/hooks';
import RecordingForm from '@/app/ui/organisms/RecordingForm/RecordingForm';

const TracksComponent = () => {
  const { id } = useParams();
  const { musicalWorksBloc } = useCradle<Cradle>();
  const musicalWorksState = useBlocState(musicalWorksBloc);

  const [recordings, setRecordings] = useState<Recording[]>([]);
  const [addingRecording, setAddingRecording] = useState<boolean>(false);
  const [audioFile, setAudioFile] = useState<File | undefined>();

  useEffect(() => {
    const getRecordings = async () => {
      if (id === undefined) {
        return;
      }
      try {
        const musicalRelease = await musicalWorksBloc.getMusicalRelease(id as MusicalReleaseId);
        const recIds = musicalRelease.recordingIds;
        if (!recIds) return;
        const releaseRecordings: Recording[] = [];
        await Promise.all(
          recIds.map(async (recId) => {
            const rec = await musicalWorksBloc.getRecording(recId);
            releaseRecordings.push(rec);
          }),
        );
        setRecordings(releaseRecordings);
      } catch (error) {
        // TODO handling error
      }
    };
    getRecordings();
  }, [musicalWorksState]);

  async function createRecording(recording: CreateRecordingProps) {
    let recordingResponse: Recording | undefined;
    try {
      recordingResponse = await musicalWorksBloc.createRecording(
        recording as CreateRecordingProps,
        id as MusicalReleaseId,
      );
    } catch (error) {
      toast.error(translate('TOAST.SAVING_ERROR'));
    }

    if (recordingResponse && audioFile) {
      try {
        const buffer = Buffer.from(await audioFile.arrayBuffer());
        const audio: Audio = {
          file: buffer,
          name: audioFile.name,
          type: audioFile.type,
        };
        await musicalWorksBloc.setAudioToRecording(recordingResponse.id, audio);
      } catch (error) {
        toast.error(translate('TOAST.CHANGES_ERROR'));
      }
    }

    musicalWorksBloc.loadRecordings();
  }

  async function updateRecording(recording: UpdateRecordingProps, recordingId: RecordingId) {
    await musicalWorksBloc.updateRecording(recordingId, recording);

    musicalWorksBloc.loadRecordings();
  }

  function deleteRecording(recording: Recording) {
    musicalWorksBloc.removeRecordingsFromMusicalRelease(id as MusicalReleaseId, [recording.id]);
    musicalWorksBloc.loadRecordings();
  }

  return (
    <>
      <Section>
        <Flex.Container flex={1} justifyContent="flex-end">
          <Button
            $size="md"
            $outline
            $transparent
            $variant="primary"
            onClick={() => {
              setAddingRecording(true);
            }}
          >
            <Text.Paragraph style={{ padding: 0 }} fontWeight={600}>
              {translate('BUTTON.ADD_NEW_TRACK')}
            </Text.Paragraph>
          </Button>
        </Flex.Container>
      </Section>
      <Section my={10}>
        {recordings && (
          <RecordingTableComponent
            deleteRecording={(recording) => deleteRecording(recording)}
            createRecording={(recording) => createRecording(recording)}
            updateRecording={(recording, recordingId) => updateRecording(recording, recordingId)}
            setNewAudio={(audio) => setAudioFile(audio)}
            recordings={recordings}
          />
        )}
      </Section>
      <Modal
        isOpen={addingRecording}
        onClose={() => setAddingRecording(false)}
        closeable
        position="top"
        size="lg"
      >
        <Flex.Container flexDirection="column" gap="40px" padding="30px 90px 20px 90px">
          <RecordingForm
            closeForm={() => setAddingRecording(false)}
            createRecording={(recording) => createRecording(recording)}
            updateRecording={(recording, recordingId) => updateRecording(recording, recordingId)}
            setNewAudio={(audio) => setAudioFile(audio)}
          />
        </Flex.Container>
      </Modal>
    </>
  );
};

export default TracksComponent;
