import { MusicalRight } from '@solo/app-core';
import { translate } from '@/app/i18n';
import { Button, Flex, Text } from '@/app/ui/atoms';
import Colors from '@/app/styles/Colors';
import { Icon } from '@/app/ui/molecules';
import logo from '@/app/assets/images/logo.png';

// interface StatementsTableProps {
//   statements: Usage[];
// }

const statements = [
  { id: '1', period: new Date(), status: 'Paid', type: MusicalRight.DISTRIBUTION, amount: 200 },
  { id: '2', period: new Date(), status: 'Unpaid', type: MusicalRight.PUBLISHING, amount: 300 },
  {
    id: '3',
    period: new Date(),
    status: 'Payment Pending',
    type: MusicalRight.DISTRIBUTION,
    amount: 100,
  },
  { id: '4', period: new Date(), status: 'Paid', type: MusicalRight.PUBLISHING, amount: 400 },
];

const StatementList = () => {
  function downloadStatement() {
    const link = document.createElement('a');
    link.href = logo;
    link.setAttribute('download', 'Statement.pdf');
    document.body.appendChild(link);
    link.click();
  }
  return (
    <Flex.Container className="table" flexDirection="column">
      <Flex.Container gap="16px" className="thead">
        <Flex.Item flex={3} className="th" style={{ paddingLeft: '1rem' }}>
          <Text.Paragraph fontWeight={600} style={{ padding: 0 }}>
            {translate('EARNINGS.PERIOD')}
          </Text.Paragraph>
        </Flex.Item>
        <Flex.Item flex={3} className="th">
          <Text.Paragraph fontWeight={600} style={{ padding: 0 }}>
            {translate('WORKS.STATUS')}
          </Text.Paragraph>
        </Flex.Item>
        <Flex.Item flex={3} className="th">
          <Text.Paragraph fontWeight={600} style={{ padding: 0 }}>
            {translate('WORKS.TYPE')}
          </Text.Paragraph>
        </Flex.Item>
        <Flex.Item flex={3} className="th">
          <Text.Paragraph fontWeight={600} style={{ padding: 0 }}>
            {translate('EARNINGS.AMOUNT')}
          </Text.Paragraph>
        </Flex.Item>
        <Flex.Item flex={2} className="th">
          <Text.Paragraph fontWeight={600} style={{ padding: 0 }}>
            {translate('ACTIONS.ACTIONS')}
          </Text.Paragraph>
        </Flex.Item>
      </Flex.Container>
      <Flex.Container className="tbody" flexDirection="column">
        {statements.map((statement) => (
          <Flex.Container
            key={statement.id}
            className="tr"
            alignItems="center"
            gap="16px"
            style={{ padding: '0.5rem' }}
          >
            <Flex.Item flex={3} className="td" style={{ paddingLeft: '1rem' }}>
              <Text.Paragraph fontWeight={700}>{statement.period.toDateString()}</Text.Paragraph>
            </Flex.Item>
            <Flex.Item flex={3} className="td">
              {statement.status}
            </Flex.Item>
            <Flex.Item flex={3} className="td">
              {statement.type}
            </Flex.Item>
            <Flex.Item flex={3} className="td">
              {statement.amount}
            </Flex.Item>
            <Flex.Item flex={2} className="td">
              <Button $size="md" $transparent style={{ padding: 0 }}>
                <Flex.Container
                  gap="0.5rem"
                  alignItems="center"
                  onClick={() => downloadStatement()}
                >
                  <Icon.DownloadIcon />
                  <Text.Paragraph fontWeight={700} style={{ padding: 0, color: Colors.primary }}>
                    {translate('DOWNLOAD.DOWNLOAD')}
                  </Text.Paragraph>
                </Flex.Container>
              </Button>
            </Flex.Item>
          </Flex.Container>
        ))}
      </Flex.Container>
    </Flex.Container>
  );
};

export default StatementList;
