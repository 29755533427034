import { Flex, Text, PlaysByPlatform, TopSongsPlatform, UsageTrend, UsageDetail } from '@/app/ui';
import { translate } from '@/app/i18n';

const MusicUsagesPage = () => (
  <Flex.Container flexDirection="column" gap="4rem">
    <Flex.Container flexDirection="column" gap="1rem">
      <Text.SubtitleBold>{translate('USAGES.TOP_SONGS_PLATFORM')}</Text.SubtitleBold>
      <TopSongsPlatform />
    </Flex.Container>
    <Flex.Container flexDirection="column">
      <Text.SubtitleBold>{translate('USAGES.PLAYS_PLATFORM')}</Text.SubtitleBold>
      <PlaysByPlatform />
    </Flex.Container>
    <Flex.Container flexDirection="column">
      <Text.SubtitleBold>{translate('USAGES.USAGE_TREND')}</Text.SubtitleBold>
      <UsageTrend />
    </Flex.Container>
    <Flex.Container flexDirection="column">
      <Text.SubtitleBold>{translate('USAGES.USAGE_DETAILS')}</Text.SubtitleBold>
      <UsageDetail />
    </Flex.Container>
  </Flex.Container>
);

export default MusicUsagesPage;
