import { useEffect, useState } from 'react';
import { Cradle, Email, User } from '@solo/app-core';
import { toast } from 'react-toastify';
import {
  Flex,
  SignUpComponent,
  SignUpFooter,
  FormProgress,
  Payment,
  SubscriptionTypeSelectComponent,
  SignUpWithEmail,
  SignaturitComponent,
  SignUpCompletedComponent,
  AliceComponent,
} from '@/app/ui';
import signUpImg from '@/app/assets/images/login-img.png';
import { translate } from '@/app/i18n';
import Icons from '@/app/ui/molecules/Icons';
import { SubscriptionInfo } from '@/app/ui/organisms/SubscriptionCard/SubscriptionCard';
import { useCradle } from '@/app/contexts';
import { useBlocState } from '@/app/hooks';

const signUpSteps = [
  translate('LOGIN.SIGN_UP'),
  translate('ACCOUNT.SUBSCRIPTION'),
  translate('SIGN_UP.IDCARD'),
  translate('SIGN_UP.DOCUMENTS'),
];

export interface CreateUserProps {
  email: Email;
  name: string;
  surname?: string;
  password?: string;
  repeatPassword?: string;
}

const SignUpPage = () => {
  const [step, setStep] = useState<number>(0);
  const [selectedSubscription, setSelectedSubscription] = useState<SubscriptionInfo | undefined>();
  const { userBloc, authBloc } = useCradle<Cradle>();
  const authState = useBlocState(authBloc);

  const [user, setUser] = useState<User>();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    if (authState.kind !== 'AuthenticatedAuthState') return;
    setIsLoggedIn(true);
    setUser(authState.user);

    if (!authState.user.subscribed) {
      setStep(2);
    } else if (!authState.user.biometricAuthenticated) {
      setStep(3);
    } else if (!authState.user.documentSigned) {
      setStep(4);
    }
  }, [selectedSubscription]);

  async function createUser(userInfo: CreateUserProps) {
    if (!userInfo.password) return;
    try {
      const username = userInfo.email;
      const { password, name, surname } = userInfo;
      await authBloc.signUp({ username, password, name, surname });
      setIsLoggedIn(true);
    } catch (error) {
      toast.error('Error signing up');
    }
  }

  async function confirmPayment() {
    if (!user) return;
    try {
      await userBloc.updateUser({ subscribed: true });
    } catch (error) {
      toast.error('Invalid payment.');
    }
  }

  return (
    <Flex.Container
      flexDirection="column"
      style={{
        background: `url(${signUpImg})`,
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
      }}
    >
      <Flex.Container flexDirection="column" flex={1}>
        {step !== 0 && (
          <Flex.Container
            alignItems="flex-start"
            flexDirection="column"
            gap="2rem"
            style={{ width: '55%', padding: '1rem 10rem 0' }}
          >
            <Icons.LogoBlackAndWhite />
            <Flex.Container style={{ paddingLeft: '2rem', width: '100%' }}>
              <FormProgress
                steps={signUpSteps}
                currentStep={step}
                setCurrentStep={(s) => setStep(s)}
                canGoBack={false}
              />
            </Flex.Container>
          </Flex.Container>
        )}
        {
          {
            0: (
              <Flex.Container
                style={{ height: '100%' }}
                alignItems="center"
                justifyContent="center"
                alignContent="center"
              >
                <SignUpComponent
                  nextStep={(stp) => setStep(stp)}
                  saveUserInfo={(userInfo) => createUser(userInfo)}
                />
                ,
              </Flex.Container>
            ),
            1: (
              <Flex.Container
                style={{ height: '100%' }}
                alignItems="center"
                justifyContent="center"
                alignContent="center"
              >
                <SignUpWithEmail
                  nextStep={() => setStep(step + 1)}
                  saveUserSignUpInfo={(userInfo) => createUser(userInfo)}
                />
              </Flex.Container>
            ),
            2: (
              <Flex.Container padding="0 0 0 2rem" style={{ height: '100%' }}>
                {selectedSubscription !== undefined && isLoggedIn ? (
                  <Payment
                    confirmPayment={() => confirmPayment()}
                    selectedSubscription={selectedSubscription}
                    nextStep={() => setStep(step + 1)}
                    isLoggedIn={isLoggedIn}
                  />
                ) : (
                  <SubscriptionTypeSelectComponent
                    setSelectedSubscription={(subscription) =>
                      setSelectedSubscription(subscription)
                    }
                  />
                )}
              </Flex.Container>
            ),
            3: (
              <Flex.Container justifyContent="center" style={{ height: '100%' }}>
                {user?.name && user.email && (
                  <AliceComponent email={user.email} nextStep={() => setStep(step + 1)} />
                )}
              </Flex.Container>
            ),
            4: (
              <Flex.Container justifyContent="center" style={{ height: '100%' }}>
                {user?.name && user.email && (
                  <SignaturitComponent
                    username={user.name}
                    email={user?.email}
                    nextStep={() => setStep(step + 1)}
                  />
                )}
              </Flex.Container>
            ),
            5: (
              <Flex.Container style={{ width: '100%', minHeight: '70vh' }} justifyContent="center">
                <SignUpCompletedComponent />
              </Flex.Container>
            ),
          }[step]
        }
      </Flex.Container>
      <Flex.Container justifyContent="center" style={{ paddingBottom: '2rem', marginTop: 'auto' }}>
        <SignUpFooter />
      </Flex.Container>
    </Flex.Container>
  );
};
export default SignUpPage;
