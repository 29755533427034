import {
  Cradle,
  DigitalPlatform,
  Distribution,
  MusicalRelease,
  Recording,
  TerritoryRestrictionModificator,
} from '@solo/app-core';
import { useEffect, useState } from 'react';
import { Flex, Text, Icon, TextWithLabel, RecordingListComponent, Button } from '@/app/ui';
import { translate } from '@/app/i18n';
import { convertArrayToString } from '@/app/utils/ArrayToString';
import { useCradle } from '@/app/contexts';
import { useBlocState } from '@/app/hooks';

interface MusicalReleaseSummaryFormProps {
  musicalRelease: MusicalRelease;
  recordings: Recording[];
  digitalDistributions: Distribution[];
  stepBackTo: (step: number) => void;
}

const MusicalReleaseSummaryForm = (props: MusicalReleaseSummaryFormProps) => {
  const { musicalRelease, recordings, digitalDistributions, stepBackTo } = props;

  const { digitalDistributionBloc } = useCradle<Cradle>();
  const digitalDistributionState = useBlocState(digitalDistributionBloc);
  const allDigitalPlatforms =
    digitalDistributionState.kind === 'LoadedDigitalDistributionState'
      ? digitalDistributionState.platforms
      : [];

  const [digitalPlatforms, setDigitalPlatforms] = useState<DigitalPlatform[]>([]);

  useEffect(() => {
    const digitalPlatformIds = digitalDistributions.map(
      (distribution) => distribution.digitalPlatformId,
    );
    setDigitalPlatforms(
      allDigitalPlatforms.filter((platform) => digitalPlatformIds.includes(platform.id)),
    );
  }, [digitalDistributions]);

  let type;
  let title;
  switch (musicalRelease.type) {
    case 'ALBUM':
      type = translate('WORKS_PAGES.ALBUM_DETAILS');
      title = translate('WORKS.ALBUM_TITLE');
      break;
    case 'SINGLE':
      type = translate('WORKS.SINGLE_DETAILS');
      title = translate('WORKS.SINGLE_TITLE');
      break;
    default:
      type = translate('WORKS.EP_DETAILS');
      title = translate('WORKS.EP_TITLE');
  }

  return (
    <Flex.Container
      flexDirection="column"
      gap="4rem"
      style={{ width: '100%', marginBottom: '3rem' }}
    >
      <Flex.Container gap="25px" flexDirection="column">
        <Flex.Container gap="30px">
          <Text.SubtitleBold>{type}</Text.SubtitleBold>
          <Button $transparent $variant="primary" onClick={() => stepBackTo(1)}>
            <Text.SectionSubtitle>{translate('WORKS_PAGES.EDIT_DETAILS')}</Text.SectionSubtitle>
          </Button>
        </Flex.Container>
        <TextWithLabel text={musicalRelease.title || '-'} label={title} />
        <Flex.Container gap="16px" style={{ width: '100%' }}>
          <Flex.Container flex={1}>
            <TextWithLabel
              text={
                musicalRelease.mainArtists
                  ? convertArrayToString(musicalRelease.mainArtists.map((artist) => artist.name))
                  : '-'
              }
              label={translate('WORKS.MAIN_ARTIST')}
            />
          </Flex.Container>
          <Flex.Container flex={1}>
            <TextWithLabel
              text={musicalRelease.language || '-'}
              label={translate('WORKS.LANGUAGE')}
            />
          </Flex.Container>
        </Flex.Container>

        <Flex.Container gap="16px" style={{ width: '100%' }}>
          <Flex.Container flex={1}>
            <TextWithLabel
              text={musicalRelease.recordLabel || '-'}
              label={translate('WORKS.RECORD_LABEL')}
            />
          </Flex.Container>
          <Flex.Container flex={1}>
            <TextWithLabel text={musicalRelease.upc_ean || '-'} label="UPC / EAN" />
          </Flex.Container>
        </Flex.Container>
        <Flex.Container gap="16px" style={{ width: '100%' }}>
          <Flex.Container flex={1}>
            <TextWithLabel
              text={musicalRelease.releaseDate?.toString() || '-'}
              label={translate('WORKS.RELEASE_DATE')}
            />
          </Flex.Container>
          <Flex.Container flex={1}>
            <TextWithLabel
              text={musicalRelease.releaseTime || '-'}
              label={translate('WORKS.RELEASE_TIME')}
            />
          </Flex.Container>
        </Flex.Container>
        <Flex.Container gap="16px" style={{ width: '100%' }}>
          <Flex.Container flex={1}>
            <TextWithLabel
              text={musicalRelease.primaryGenre || '-'}
              label={translate('WORKS.PRIMARY_GENRE')}
            />
          </Flex.Container>
          <Flex.Container flex={1}>
            <TextWithLabel
              text={musicalRelease.secondaryGenre || '-'}
              label={translate('WORKS.SECONDARY_GENRE')}
            />
          </Flex.Container>
        </Flex.Container>
        <Flex.Container gap="16px" style={{ width: '100%' }}>
          <Flex.Container flex={1}>
            <TextWithLabel
              text={musicalRelease.productCopyright || '-'}
              label={translate('WORKS.PRODUCT_COPYRIGHT')}
            />
          </Flex.Container>
          <Flex.Container flex={1}>
            <TextWithLabel
              text={musicalRelease.soundCopyright || '-'}
              label={translate('WORKS.SOUND_RECORDING_COPYRIGHT')}
            />
          </Flex.Container>
        </Flex.Container>
      </Flex.Container>

      <Flex.Container gap="35px" flexDirection="column">
        <Flex.Container gap="30px">
          <Text.SubtitleBold>{translate('WORKS_PAGES.TERRITORY_RESTRICTIONS')}</Text.SubtitleBold>
          <Button $transparent $variant="primary" onClick={() => stepBackTo(3)}>
            <Text.SectionSubtitle>
              {translate('WORKS_PAGES.CHANGE_RESTRICTION')}
            </Text.SectionSubtitle>
          </Button>
        </Flex.Container>

        <Flex.Container gap="0.5rem" alignItems="center">
          <Icon.AlertCircleIcon />
          <Text.LabelMedium>{translate('WORKS_PAGES.CHANGE_RESTRICTION_WARNING')}</Text.LabelMedium>
        </Flex.Container>
        <Flex.Container gap="1rem">
          {digitalDistributions[0]?.territoryRestrictions.length > 0 ? (
            digitalDistributions[0].territoryRestrictions.map((dist) => (
              <Flex.Container
                gap="1rem"
                alignItems="center"
                id={dist.territory.tis.toString()}
                key={dist.territory.tis.toString()}
              >
                {dist.mod === TerritoryRestrictionModificator.INCLUDE ? (
                  <Icon.CheckIcon />
                ) : (
                  <Icon.Forbidden />
                )}
                <Text.LabelMedium>{dist.territory.name}</Text.LabelMedium>
              </Flex.Container>
            ))
          ) : (
            <Flex.Container gap="1rem" alignItems="center">
              <Icon.CheckIcon />
              <Text.LabelMedium>{translate('WORKS_PAGES.ALL_TERRITORIES')}</Text.LabelMedium>
            </Flex.Container>
          )}
        </Flex.Container>
      </Flex.Container>
      <Flex.Container gap="25px" flexDirection="column">
        <Flex.Container gap="30px">
          <Text.SubtitleBold>{translate('WORKS_PAGES.TRACKS')}</Text.SubtitleBold>
          <Button $transparent $variant="primary" onClick={() => stepBackTo(2)}>
            <Text.SectionSubtitle>{translate('WORKS_PAGES.EDIT_TRACKS')}</Text.SectionSubtitle>
          </Button>
        </Flex.Container>
        <RecordingListComponent tracks={recordings} />
      </Flex.Container>
      <Flex.Container gap="35px" flexDirection="column">
        <Flex.Container gap="30px">
          <Text.SubtitleBold>{translate('WORKS_PAGES.DIGITAL_DISTRIBUTION')}</Text.SubtitleBold>
          <Button $transparent $variant="primary" onClick={() => stepBackTo(3)}>
            <Text.SectionSubtitle>
              {translate('WORKS_PAGES.EDIT_DISTRIBUTION')}
            </Text.SectionSubtitle>
          </Button>
        </Flex.Container>{' '}
        <Flex.Container flexDirection="column" gap="50px">
          <Flex.Container flexDirection="row" flexWrap="wrap" gap="20px 0px">
            {digitalPlatforms.map((store) => (
              <Flex.Container
                key={store.id}
                gap="20px"
                alignItems="center"
                style={{ width: '33%' }}
              >
                <Icon.CheckIcon />
                <Text.LabelMedium>{store.name}</Text.LabelMedium>
              </Flex.Container>
            ))}
          </Flex.Container>
          <Flex.Container gap="16px" style={{ width: '100%' }}>
            <Flex.Container flex={1}>
              <TextWithLabel
                text={
                  digitalDistributions.find(
                    (dist) =>
                      dist.digitalPlatformId ===
                      allDigitalPlatforms.find((p) => p.name === 'Apple Music')?.id,
                  )?.platformUserId || '-'
                }
                label="Apple Music ID"
              />
            </Flex.Container>
            <Flex.Container flex={1}>
              <TextWithLabel
                text={
                  digitalDistributions.find(
                    (dist) =>
                      dist.digitalPlatformId ===
                      allDigitalPlatforms.find((p) => p.name === 'Spotify')?.id,
                  )?.platformUserId || '-'
                }
                label="Spotify ID"
              />
            </Flex.Container>
          </Flex.Container>
        </Flex.Container>
      </Flex.Container>
    </Flex.Container>
  );
};

export default MusicalReleaseSummaryForm;
