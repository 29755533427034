import { Outlet } from 'react-router-dom';
import { Flex, Text, Container, Section, RouterLink } from '@/app/ui';

import { translate } from '@/app/i18n';
import MusicUsagesPage from '@/app/pages/MusicUsagesPage/MusicUsagesPage';

const MusicUsagePage = (): JSX.Element => (
  <Flex.Container flexDirection="column" gap="3rem" padding="3rem">
    <Flex.Container>
      <RouterLink $transparent to="/music-usage">
        <Text.Title2 style={{ marginRight: 5 }}>{translate('NAVBAR.MUSIC_USAGE')}</Text.Title2>
      </RouterLink>
    </Flex.Container>
    <Flex.Container className="ml-2">
      <MusicUsagesPage />
    </Flex.Container>

    <Section>
      <Container>
        <Flex.Container>
          <Flex.Item>
            <Outlet />
          </Flex.Item>
        </Flex.Container>
      </Container>
    </Section>
  </Flex.Container>
);

export default MusicUsagePage;
