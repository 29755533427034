import { createElement } from 'react';

import spotifyIcon from '@/app/assets/images/icons/spotify-icon.svg';
import deezerIcon from '@/app/assets/images/icons/deezer-icon.svg';
import appleMusicIcon from '@/app/assets/images/icons/apple-music-icon.svg';
import soundCloudIcon from '@/app/assets/images/icons/sound-cloud-icon.svg';
import youtubeIcon from '@/app/assets/images/icons/youtube-icon.svg';
import { Img } from '@/app/ui/atoms';

type ImageProps = React.ComponentProps<typeof Img>;
type ImagePropsNoSrc = Omit<ImageProps, 'src'>;

/* ##### CUSTOM ICONS ####################################################### */

const ImageIcon = (props: ImageProps) => {
  const { width, height, src, className, onClick } = props;

  return createElement(Img, {
    src,
    height: width === undefined && height === undefined ? 32 : height || 'auto',
    width: width || 'auto',
    ...props,
    className: `${className !== undefined ? className : ''}${
      onClick !== undefined ? ' clickable' : ''
    }`,
  });
};

const SpotifyIcon = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { src: spotifyIcon, ...props });

const DeezerIcon = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { src: deezerIcon, ...props });

const AppleMusicIcon = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { src: appleMusicIcon, ...props });

const SoundCloudIcon = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { src: soundCloudIcon, ...props });

const YoutubeIcon = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { src: youtubeIcon, ...props });

const DefaultDigitalDistributionIcon = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { src: deezerIcon, ...props });

const Icons = {
  SpotifyIcon,
  DeezerIcon,
  AppleMusicIcon,
  SoundCloudIcon,
  YoutubeIcon,
  DefaultDigitalDistributionIcon,
};

// type DigitalDistributionIconsType =
//   | 'spotify'
//   | 'deezer'
//   | 'apple-music'
//   | 'sound-cloud'
//   | 'youtube';

interface DigitalDistributionIconsProps {
  icon: string;
  width: number;
  style?: React.CSSProperties;
}

const DigitalDistributionIcons = ({ icon, style, width }: DigitalDistributionIconsProps) => {
  if (icon === 'spotify') return <Icons.SpotifyIcon style={style} width={width} />;
  if (icon === 'deezer') return <Icons.DeezerIcon style={style} width={width} />;
  if (icon === 'apple-music') return <Icons.AppleMusicIcon style={style} width={width} />;
  if (icon === 'sound-cloud') return <Icons.SoundCloudIcon style={style} width={width} />;
  if (icon === 'youtube') return <Icons.YoutubeIcon style={style} width={width} />;

  return <Icons.DefaultDigitalDistributionIcon style={style} width={width} />;
};

export default DigitalDistributionIcons;
