import { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Cradle, StripeClientSecret } from '@solo/app-core';
import { translate } from '@/app/i18n';
import Colors from '@/app/styles/Colors';
import {
  Flex,
  Text,
  SubscriptionCard,
  Icon,
  Button,
  LoadingComponent,
  PaymentComponent,
} from '@/app/ui';
import { useCradle } from '@/app/contexts';

// TODO crear hook useConfig i useCoreConfig per obtenir aquest tipus de dades
const stripePromise = loadStripe(
  'pk_test_51LiFjEIvsNFcbB4AY1BQNqs9WIUkKiM8doYOkJ8vIS84uJPyvSFhrlmscM38PvLLDpxNoLjlRCt9mNayuH8gfQMc00IbjXUSxo',
);

interface PaymentProps {
  selectedSubscription: any;
  confirmPayment: () => void;
  nextStep: () => void;
  isLoggedIn: boolean;
}

const Payment = ({ selectedSubscription, confirmPayment, nextStep, isLoggedIn }: PaymentProps) => {
  const [completedSub, setCompletedSub] = useState<boolean>(false);
  const [clientSecret, setClientSecret] = useState<StripeClientSecret>();
  const { paymentBloc } = useCradle<Cradle>();

  function sendPaymentInfo() {
    setCompletedSub(true);
    confirmPayment();
  }

  useEffect(() => {
    async function createPaymentIntent() {
      await paymentBloc.createStripeAccount();

      const paymentIntentClientSecret: StripeClientSecret =
        await paymentBloc.createStripePaymentIntent('price_1MwhkDIvsNFcbB4AvaTgHMx0'); // TODO fetch price ID corresponent

      setClientSecret(paymentIntentClientSecret);
    }
    createPaymentIntent();
  }, []);

  return (
    <Flex.Container style={{ width: '100%' }}>
      {completedSub ? (
        <Flex.Container
          style={{ width: '100%', minHeight: '70vh' }}
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <Flex.Container flexDirection="column" gap="2rem" justifyContent="center">
            <Text.BiggerBold style={{ color: Colors.white }}>
              {translate('SIGN_UP.SUB_COMPLETED')}
            </Text.BiggerBold>
            <Flex.Container
              style={{ marginLeft: '140px' }}
              gap="1.5rem"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Icon.SpinnerCheckIcon />
              <Text.LabelMedium style={{ color: Colors.white }}>
                {translate('SIGN_UP.SUB_ITEM')}
              </Text.LabelMedium>
            </Flex.Container>
            <Flex.Container
              style={{ marginLeft: '140px' }}
              gap="1.5rem"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Icon.SpinnerCheckIcon />
              <Text.LabelMedium style={{ color: Colors.white }}>
                {translate('SIGN_UP.PAYMENT')}
              </Text.LabelMedium>
            </Flex.Container>
            <Button onClick={() => nextStep()} style={{ width: '100%' }}>
              <Text.SectionSubtitle style={{ color: Colors.white, padding: '1rem' }}>
                {translate('PAGINATION.NEXT_STEP')}
              </Text.SectionSubtitle>
            </Button>
          </Flex.Container>
        </Flex.Container>
      ) : (
        <Flex.Container
          flexDirection="column"
          style={{ width: '100%', padding: '0rem 10rem 0rem' }}
        >
          <Text.BiggerItalic style={{ color: Colors.white }} className="mt-2 mb-2">
            {translate('SIGN_UP.PAYMENT')}
          </Text.BiggerItalic>
          <Flex.Container gap="10rem">
            <Flex.Container flexDirection="column" gap="1rem">
              <Text.SubtitleBold style={{ color: Colors.white }} className="mt-2 mb-2">
                {translate('SIGN_UP.SELECTED_ITEM')}
              </Text.SubtitleBold>
              <Flex.Container
                style={{
                  maxWidth: '380px',
                  minHeight: '440px',
                  boxShadow: `0px 0px 4px 4px ${Colors.primary}`,
                }}
              >
                <SubscriptionCard info={selectedSubscription} />
              </Flex.Container>
            </Flex.Container>
            <Flex.Container
              justifyContent="flex-start"
              flexDirection="column"
              style={{ minWidth: '550px' }}
            >
              <Text.SubtitleBold style={{ color: Colors.white }} className="mt-2 mb-2">
                {translate('SIGN_UP.PAYMENT_INFORMATION')}
              </Text.SubtitleBold>
              {isLoggedIn && clientSecret ? (
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                  <PaymentComponent
                    clientSecret={clientSecret}
                    savePaymentInfo={() => sendPaymentInfo()}
                  />
                </Elements>
              ) : (
                <LoadingComponent />
              )}
            </Flex.Container>
          </Flex.Container>
        </Flex.Container>
      )}
    </Flex.Container>
  );
};
export default Payment;
