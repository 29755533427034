import { Navigate, useLocation } from 'react-router-dom';
import { Cradle } from '@solo/app-core';
import { useCradle } from '@/app/contexts';
import { useBlocState } from '@/app/hooks';

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { authBloc } = useCradle<Cradle>();
  const authState = useBlocState(authBloc);
  const location = useLocation();

  if (authState.kind !== 'AuthenticatedAuthState') {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAuth;
